import React, { useState } from "react";
import getUserModules from "services/getUserModules";
import useFetchData from "effects/useFetchData";
import AdminSideBar from "components/AdminSideBar";
import Authentication from "services/Authentication";
import Footer from "components/Footer";
import EditorModal from "components/EditorModal";

const auth = new Authentication();

const AdminAddCourse = () => {
  const [isOpen, setOpen] = useState(false);

  const [loading, errored, apiResponse] = useFetchData(() => getUserModules());
  const { studentModules } = apiResponse;
  if (loading) {
    return <div>Loading...</div>;
  }

  if (errored) {
    return <div>Something went wrong</div>;
  }

  return (
    <>
      <AdminSideBar />

      <div className="page-content">
        {isOpen && <EditorModal setOpen={setOpen} />}
        <div className="page-content-inner">
          <div className="d-flex">
            <nav id="breadcrumbs" className="mb-3">
              <ul>
                <li>
                  <a href="#">
                    {" "}
                    <i className="uil-home-alt"></i>{" "}
                  </a>
                </li>
                <li>
                  <a href="#"> Course </a>
                </li>
                <li>Create New Course</li>
              </ul>
            </nav>
          </div>

          <div className="card">
            <div className="card-header border-bottom-0 py-4">
              <h5> Course Manager </h5>
            </div>

            <ul
              className="uk-child-width-expand uk-tab"
              data-uk-switcher="connect: #course-edit-tab ; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium"
            >
              <li className="uk-active">
                <a href="#"> Basic</a>
              </li>
              <li>
                <a href="#"> Curriculum</a>
              </li>
              <li>
                <a href="#">Meta data</a>
              </li>
              <li>
                <a href="#">Requirements</a>
              </li>
              <li>
                <a href="#">Pricing</a>
              </li>
              <li>
                <a href="#">Finish</a>
              </li>
            </ul>

            <div className="card-body">
              <ul className="uk-switcher uk-margin" id="course-edit-tab">
                <li>
                  <div className="row">
                    <div className="col-xl-9 m-auto">
                      <div className="form-group row mb-3">
                        <label
                          className="col-md-3 col-form-label"
                          for="course_title"
                        >
                          Course title<span className="required">*</span>
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className="form-control"
                            id="course_title"
                            name="title"
                            placeholder="Enter course title"
                            value="Learn CSS Fundamentals ..."
                            required=""
                          />
                        </div>
                      </div>

                      <div className="form-group row mb-3">
                        <label
                          className="col-md-3 col-form-label"
                          for="short_description"
                        >
                          Short description
                        </label>
                        <div className="col-md-9">
                          <textarea
                            name="short_description"
                            id="short_description"
                            className="form-control"
                          >
                            CSS is what makes the web beautiful. It describes
                            how HTML should be displayed and how to layout
                            elements. Take this class and get familiar with
                            CSS!.
                          </textarea>
                        </div>
                      </div>

                      <div className="form-group row mb-3">
                        <label
                          className="col-md-3 col-form-label"
                          for="course_title"
                        >
                          {" "}
                          Category <span className="required">*</span>
                        </label>
                        <div className="col-md-9">
                          <select className="selectpicker">
                            <option data-icon="uil-android-alt" selected>
                              {" "}
                              Mobile App{" "}
                            </option>
                            <option data-icon="uil-bag-alt">Business</option>
                            <option data-icon="uil-palette">Desings</option>
                            <option data-icon="uil-camera">Photography</option>
                            <option data-icon="uil-medkit">
                              Health Fitness
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group row mb-3">
                        <label
                          className="col-md-3 col-form-label"
                          for="course_title"
                        >
                          {" "}
                          Language <span className="required">*</span>
                        </label>
                        <div className="col-md-9">
                          <select className="selectpicker">
                            <option value=""> Beginner </option>
                            <option value="1"> Intermediate </option>
                            <option value="2"> Advanced</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="row">
                    <div className="col-xl-10 m-auto">
                      <ul className="c-curriculum" data-uk-accordion>
                        <li className="uk-open">
                          <a className="uk-accordion-title" href="#">
                            {" "}
                            <i className="uil-folder"></i>Section titile 1
                          </a>
                          <div className="action-btn">
                            <a href="#">
                              {" "}
                              <i className="uil-plus"> </i> Add Lacture{" "}
                            </a>
                            <a href="#">
                              {" "}
                              <i className="uil-plus"> </i> Add link{" "}
                            </a>
                            <a href="#">
                              {" "}
                              <i className="uil-plus"> </i> Add quiz{" "}
                            </a>
                          </div>
                          <div className="uk-accordion-content">
                            <ul
                              className="sec-list"
                              data-uk-sortable="handle: .uk-sortable-handle"
                            >
                              <li>
                                <div
                                  className="sec-list-item"
                                  onClick={() => setOpen(true)}
                                >
                                  <div>
                                    {" "}
                                    <i className="uil-list-ul uk-sortable-handle"></i>
                                    <label className="mb-0 mx-2">
                                      <input
                                        className="uk-checkbox"
                                        type="checkbox"
                                      />
                                    </label>
                                    <p> CLICK ME! </p>
                                  </div>
                                  <div>
                                    <div className="btn-act">
                                      {" "}
                                      <a href="#">
                                        <i className="uil-cloud-download"></i>
                                      </a>
                                      <a href="#">
                                        <i className="uil-eye"></i>
                                      </a>
                                      <a href="#">
                                        <i className="icon-feather-x"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="sec-list-item">
                                  <div>
                                    {" "}
                                    <i className="uil-list-ul uk-sortable-handle"></i>
                                    <label className="mb-0 mx-2">
                                      <input
                                        className="uk-checkbox"
                                        type="checkbox"
                                      />
                                    </label>
                                    <p> Course Simple Content </p>
                                  </div>
                                  <div>
                                    <div className="btn-act">
                                      {" "}
                                      <a href="#">
                                        <i className="uil-cloud-download"></i>
                                      </a>
                                      <a href="#">
                                        <i className="uil-eye"></i>
                                      </a>
                                      <a href="#">
                                        <i className="icon-feather-x"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="sec-list-item">
                                  <div>
                                    {" "}
                                    <i className="uil-list-ul uk-sortable-handle"></i>
                                    <label className="mb-0 mx-2">
                                      <input
                                        className="uk-checkbox"
                                        type="checkbox"
                                      />
                                    </label>
                                    <p> Course Simple Content </p>
                                  </div>
                                  <div>
                                    <div className="btn-act">
                                      {" "}
                                      <a href="#">
                                        <i className="uil-cloud-download"></i>
                                      </a>
                                      <a href="#">
                                        <i className="uil-eye"></i>
                                      </a>
                                      <a href="#">
                                        <i className="icon-feather-x"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="sec-list-item">
                                  <div>
                                    {" "}
                                    <i className="uil-list-ul uk-sortable-handle"></i>
                                    <label className="mb-0 mx-2">
                                      <input
                                        className="uk-checkbox"
                                        type="checkbox"
                                      />
                                    </label>
                                    <p> Course Simple Content </p>
                                  </div>
                                  <div>
                                    <div className="btn-act">
                                      {" "}
                                      <a href="#">
                                        <i className="uil-cloud-download"></i>
                                      </a>
                                      <a href="#">
                                        <i className="uil-eye"></i>
                                      </a>
                                      <a href="#">
                                        <i className="icon-feather-x"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <a className="uk-accordion-title" href="#">
                            {" "}
                            <i className="uil-folder"></i>Section titile 2
                          </a>
                          <div className="action-btn">
                            <a href="#">
                              {" "}
                              <i className="uil-plus"> </i> Add Lacture{" "}
                            </a>
                            <a href="#">
                              {" "}
                              <i className="uil-plus"> </i> Add link{" "}
                            </a>
                            <a href="#">
                              {" "}
                              <i className="uil-plus"> </i> Add quiz{" "}
                            </a>
                          </div>
                          <div className="uk-accordion-content">
                            <ul
                              className="sec-list"
                              uk-sortable="handle: .uk-sortable-handle"
                            >
                              <li>
                                <div className="sec-list-item">
                                  <div>
                                    {" "}
                                    <i className="uil-list-ul uk-sortable-handle"></i>
                                    <label className="mb-0 mx-2">
                                      <input
                                        className="uk-checkbox"
                                        type="checkbox"
                                      />
                                    </label>
                                    <p> Course Simple Content </p>
                                  </div>
                                  <div>
                                    <div className="btn-act">
                                      {" "}
                                      <a href="#">
                                        <i className="uil-cloud-download"></i>
                                      </a>
                                      <a href="#">
                                        <i className="uil-eye"></i>
                                      </a>
                                      <a href="#">
                                        <i className="icon-feather-x"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="sec-list-item">
                                  <div>
                                    {" "}
                                    <i className="uil-list-ul uk-sortable-handle"></i>
                                    <label className="mb-0 mx-2">
                                      <input
                                        className="uk-checkbox"
                                        type="checkbox"
                                      />
                                    </label>
                                    <p> Course Simple Content </p>
                                  </div>
                                  <div>
                                    <div className="btn-act">
                                      {" "}
                                      <a href="#">
                                        <i className="uil-cloud-download"></i>
                                      </a>
                                      <a href="#">
                                        <i className="uil-eye"></i>
                                      </a>
                                      <a href="#">
                                        <i className="icon-feather-x"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="sec-list-item">
                                  <div>
                                    {" "}
                                    <i className="uil-list-ul uk-sortable-handle"></i>
                                    <label className="mb-0 mx-2">
                                      <input
                                        className="uk-checkbox"
                                        type="checkbox"
                                      />
                                    </label>
                                    <p> Course Simple Content </p>
                                  </div>
                                  <div>
                                    <div className="btn-act">
                                      {" "}
                                      <a href="#">
                                        <i className="uil-cloud-download"></i>
                                      </a>
                                      <a href="#">
                                        <i className="uil-eye"></i>
                                      </a>
                                      <a href="#">
                                        <i className="icon-feather-x"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="sec-list-item">
                                  <div>
                                    {" "}
                                    <i className="uil-list-ul uk-sortable-handle"></i>
                                    <label className="mb-0 mx-2">
                                      <input
                                        className="uk-checkbox"
                                        type="checkbox"
                                      />
                                    </label>
                                    <p> Course Simple Content </p>
                                  </div>
                                  <div>
                                    <div className="btn-act">
                                      {" "}
                                      <a href="#">
                                        <i className="uil-cloud-download"></i>
                                      </a>
                                      <a href="#">
                                        <i className="uil-eye"></i>
                                      </a>
                                      <a href="#">
                                        <i className="icon-feather-x"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <a className="uk-accordion-title" href="#">
                            {" "}
                            <i className="uil-folder"></i>Section titile 3{" "}
                          </a>
                          <div className="action-btn">
                            <a href="#">
                              {" "}
                              <i className="uil-plus"> </i> Add Lacture{" "}
                            </a>
                            <a href="#">
                              {" "}
                              <i className="uil-plus"> </i> Add link{" "}
                            </a>
                            <a href="#">
                              {" "}
                              <i className="uil-plus"> </i> Add quiz{" "}
                            </a>
                          </div>
                          <div className="uk-accordion-content">
                            <ul
                              className="sec-list"
                              data-uk-sortable="handle: .uk-sortable-handle"
                            >
                              <li>
                                <div className="sec-list-item">
                                  <div>
                                    {" "}
                                    <i className="uil-list-ul uk-sortable-handle"></i>
                                    <label className="mb-0 mx-2">
                                      <input
                                        className="uk-checkbox"
                                        type="checkbox"
                                      />
                                    </label>
                                    <p> Course Simple Content </p>
                                  </div>
                                  <div>
                                    <div className="btn-act">
                                      {" "}
                                      <a href="#">
                                        <i className="uil-cloud-download"></i>
                                      </a>
                                      <a href="#">
                                        <i className="uil-eye"></i>
                                      </a>
                                      <a href="#">
                                        <i className="icon-feather-x"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="sec-list-item">
                                  <div>
                                    {" "}
                                    <i className="uil-list-ul uk-sortable-handle"></i>
                                    <label className="mb-0 mx-2">
                                      <input
                                        className="uk-checkbox"
                                        type="checkbox"
                                      />
                                    </label>
                                    <p> Course Simple Content </p>
                                  </div>
                                  <div>
                                    <div className="btn-act">
                                      {" "}
                                      <a href="#">
                                        <i className="uil-cloud-download"></i>
                                      </a>
                                      <a href="#">
                                        <i className="uil-eye"></i>
                                      </a>
                                      <a href="#">
                                        <i className="icon-feather-x"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="sec-list-item">
                                  <div>
                                    {" "}
                                    <i className="uil-list-ul uk-sortable-handle"></i>
                                    <label className="mb-0 mx-2">
                                      <input
                                        className="uk-checkbox"
                                        type="checkbox"
                                      />
                                    </label>
                                    <p> Course Simple Content </p>
                                  </div>
                                  <div>
                                    <div className="btn-act">
                                      {" "}
                                      <a href="#">
                                        <i className="uil-cloud-download"></i>
                                      </a>
                                      <a href="#">
                                        <i className="uil-eye"></i>
                                      </a>
                                      <a href="#">
                                        <i className="icon-feather-x"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="sec-list-item">
                                  <div>
                                    {" "}
                                    <i className="uil-list-ul uk-sortable-handle"></i>
                                    <label className="mb-0 mx-2">
                                      <input
                                        className="uk-checkbox"
                                        type="checkbox"
                                      />
                                    </label>
                                    <p> Course Simple Content </p>
                                  </div>
                                  <div>
                                    <div className="btn-act">
                                      {" "}
                                      <a href="#">
                                        <i className="uil-cloud-download"></i>
                                      </a>
                                      <a href="#">
                                        <i className="uil-eye"></i>
                                      </a>
                                      <a href="#">
                                        <i className="icon-feather-x"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <a className="uk-accordion-title" href="#">
                            {" "}
                            <i className="uil-folder"></i>Section titile 4
                          </a>
                          <div className="action-btn">
                            <a href="#">
                              {" "}
                              <i className="uil-plus"> </i> Add Lacture{" "}
                            </a>
                            <a href="#">
                              {" "}
                              <i className="uil-plus"> </i> Add link{" "}
                            </a>
                            <a href="#">
                              {" "}
                              <i className="uil-plus"> </i> Add quiz{" "}
                            </a>
                          </div>
                          <div className="uk-accordion-content">
                            <ul
                              className="sec-list"
                              data-uk-sortable="handle: .uk-sortable-handle"
                            >
                              <li>
                                <div className="sec-list-item">
                                  <div>
                                    {" "}
                                    <i className="uil-list-ul uk-sortable-handle"></i>
                                    <label className="mb-0 mx-2">
                                      <input
                                        className="uk-checkbox"
                                        type="checkbox"
                                      />
                                    </label>
                                    <p> Course Simple Content </p>
                                  </div>
                                  <div>
                                    <div className="btn-act">
                                      {" "}
                                      <a href="#">
                                        <i className="uil-cloud-download"></i>
                                      </a>
                                      <a href="#">
                                        <i className="uil-eye"></i>
                                      </a>
                                      <a href="#">
                                        <i className="icon-feather-x"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="sec-list-item">
                                  <div>
                                    {" "}
                                    <i className="uil-list-ul uk-sortable-handle"></i>
                                    <label className="mb-0 mx-2">
                                      <input
                                        className="uk-checkbox"
                                        type="checkbox"
                                      />
                                    </label>
                                    <p> Course Simple Content </p>
                                  </div>
                                  <div>
                                    <div className="btn-act">
                                      {" "}
                                      <a href="#">
                                        <i className="uil-cloud-download"></i>
                                      </a>
                                      <a href="#">
                                        <i className="uil-eye"></i>
                                      </a>
                                      <a href="#">
                                        <i className="icon-feather-x"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="sec-list-item">
                                  <div>
                                    {" "}
                                    <i className="uil-list-ul uk-sortable-handle"></i>
                                    <label className="mb-0 mx-2">
                                      <input
                                        className="uk-checkbox"
                                        type="checkbox"
                                      />
                                    </label>
                                    <p> Course Simple Content </p>
                                  </div>
                                  <div>
                                    <div className="btn-act">
                                      {" "}
                                      <a href="#">
                                        <i className="uil-cloud-download"></i>
                                      </a>
                                      <a href="#">
                                        <i className="uil-eye"></i>
                                      </a>
                                      <a href="#">
                                        <i className="icon-feather-x"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="sec-list-item">
                                  <div>
                                    {" "}
                                    <i className="uil-list-ul uk-sortable-handle"></i>
                                    <label className="mb-0 mx-2">
                                      <input
                                        className="uk-checkbox"
                                        type="checkbox"
                                      />
                                    </label>
                                    <p> Course Simple Content </p>
                                  </div>
                                  <div>
                                    <div className="btn-act">
                                      {" "}
                                      <a href="#">
                                        <i className="uil-cloud-download"></i>
                                      </a>
                                      <a href="#">
                                        <i className="uil-eye"></i>
                                      </a>
                                      <a href="#">
                                        <i className="icon-feather-x"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="row justify-content-center">
                    <div className="col-xl-9">
                      <div className="form-group row mb-3">
                        <label
                          className="col-md-3 col-form-label"
                          for="website_keywords"
                        >
                          Meta keywords
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className="form-control bootstrap-tag-input"
                            id="meta_keywords"
                            name="meta_keywords"
                            data-role="tagsinput"
                            style={{ width: "100%", display: "none" }}
                          />
                          <div className="bootstrap-tagsinput">
                            <input size="1" type="text" placeholder="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-9">
                      <div className="form-group row mb-3">
                        <label
                          className="col-md-3 col-form-label"
                          for="meta_description"
                        >
                          Meta description
                        </label>
                        <div className="col-md-9">
                          <textarea
                            name="meta_description"
                            className="form-control"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  {/*                   
                                <script type="text/JavaScript">
                                    function createNewElement() {
                            // First create a DIV element.
                            var txtNewInputBox = document.createElement('div');
                        
                            // Then add the content (a new input box) of the element.
                            txtNewInputBox.innerHTML = "<input type='text' className='uk-input'>";
                        
                            // Finally put it where it is supposed to appear.
                            document.getElementById("newElementId").appendChild(txtNewInputBox);
                        }
                        </script> */}

                  <div className="row justify-content-center">
                    <div className="col-xl-9">
                      <button
                        className="btn btn-default mb-3"
                        onclick="createNewElement();"
                      >
                        {" "}
                        <i className="uil-plus"></i> Requirements{" "}
                      </button>

                      <div id="newElementId"> </div>

                      <input
                        type="text"
                        className="uk-input"
                        placeholder="Any computer will work: Windows, macOS or Linux"
                      />
                      <input
                        type="text"
                        className="uk-input"
                        placeholder="Basic programming HTML and CSS."
                      />
                      <input
                        type="text"
                        className="uk-input"
                        placeholder="Basic/Minimal understanding of JavaScript"
                      />
                    </div>
                  </div>
                </li>

                <li>
                  <div className="row justify-content-center">
                    <div className="col-xl-9">
                      <div className="form-group row mb-3">
                        <label className="col-md-3 col-form-label">
                          Course price ($)
                        </label>
                        <div className="col-md-9">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter  price"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-9">
                      <div className="form-group row mb-3">
                        <label className="col-md-3 col-form-label">
                          Discounted price ($)
                        </label>
                        <div className="col-md-9">
                          <input className="form-control" />
                          <small className="text-muted">
                            This course has{" "}
                            <span className="text-danger">10%</span>{" "}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="row">
                    <div className="col-12 my-lg-5">
                      <div className="text-center">
                        <h2 className="mt-0">
                          <i className="icon-feather-check-circle text-success"></i>
                        </h2>
                        <h3 className="mt-0">Thank you !</h3>

                        <p className="w-75 mb-2 mx-auto">
                          {" "}
                          Submit This Course For Reweiw{" "}
                        </p>

                        <div className="mb-3 mt-3">
                          <button type="button" className="btn btn-default">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AdminAddCourse;
