/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Toggle from "react-toggle";
import ccLogoThumbWhite from "images/cc_logo_thumb_white.png";
import ccLogoThumbBlack from "images/cc_logo_thumb.png";
import moon from "images/moon.png";
import sun from "images/sun.png";
import Authentication from "services/Authentication";
import SignedIn from "../SignedIn";
import getGravatarImg from "utils/getGravatarImg";

const auth = new Authentication();
const Header = ({ nightMode, setNightMode, forceUpdate, updateCount }) => {
  let history = useHistory();
  const jwtPayload = auth.getAccessTokenPayload();

  return (
    <>
      <header className="header header-horizontal">
        <span style={{ display: "none" }}>{updateCount}</span>{" "}
        {/* hacky way to rerender*/}
        <div className="container">
          <nav data-uk-navbar>
            {/* left Side Content */}
            <div className="uk-navbar-left">
              {/* menu icon */}
              <span className="mmenu-trigger">
                <button className="hamburger hamburger--collapse" type="button">
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
              </span>

              {/* logo */}

              <a
                href="#"
                className="logo"
                onClick={() => {
                  history.push(`/`);
                }}
              >
                <img
                  src={nightMode ? ccLogoThumbWhite : ccLogoThumbBlack}
                  alt=""
                />
                <span> CyprusCodes</span>
              </a>

              {/* Main Navigation */}

              <nav id="navigation">
                <ul id="responsive">
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push(`/`);
                      }}
                    >
                      Home
                    </a>{" "}
                  </li>

                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push(`/blogs`);
                      }}
                    >
                      Blogs
                    </a>{" "}
                  </li>
                </ul>
              </nav>
              {/* Main Navigation / End */}
            </div>

            {/*  Right Side Content   */}

            <div className="uk-navbar-right">
              <div
                className="header-widget"
                style={{ background: "transparent" }}
              >
                {/*}
                <SignedIn>
                  <div className="searchbox uk-visible@s">
                    <input
                      className="uk-search-input"
                      type="search"
                      placeholder="Search..."
                    />
                    <button className="btn-searchbox"> </button>
                  </div>
                  <div
                    data-uk-dropdown="pos: top;mode:click;animation: data-uk-animation-slide-bottom-small"
                    className="dropdown-search"
                  >
                    <div
                      className="erh BR9 MIw"
                      style={{
                        top: "-27px",
                        position: "absolute",
                        left: "24px",
                        fill: "currentColor",
                        height: "24px",
                        pointerEvents: "none",
                        color: "#f5f5f5",
                      }}
                    >
                      <svg width="22" height="22">
                        <path d="M0 24 L12 12 L24 24"></path>
                      </svg>
                    </div>

                    <ul className="dropdown-search-list">
                      <li className="list-title">Recent Searches</li>
                      <li>
                        <a href="course-intro.html">
                          Ultimate Web Designer And Developer Course
                        </a>
                      </li>
                      <li>
                        <a href="course-intro.html">
                          The Complete Ruby on Rails Developer Course{" "}
                        </a>
                      </li>
                      <li>
                        <a href="course-intro.html">
                          Bootstrap 4 From Scratch With 5 Real Projects{" "}
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="course-intro.html">
                          The Complete 2020 Web Development Bootcamp{" "}
                        </a>
                      </li>
                      <li className="menu-divider" />
                      <li>
                        <a href="course-intro.html">
                          Bootstrap 4 From Scratch With 5 Real Projects{" "}
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="course-intro.html">
                          The Complete 2020 Web Development Bootcamp{" "}
                        </a>
                      </li>
                    </ul>
                  </div>

                  <span
                    className="icon-feather-x icon-small uk-hidden@s"
                    data-uk-toggle="target: .header-widget ; cls: is-active"
                  >
                    {" "}
                  </span>
                </SignedIn>
                */}
                <Toggle
                  defaultChecked={nightMode}
                  icons={{
                    checked: (
                      <img
                        src={moon}
                        width="20"
                        height="20"
                        role="presentation"
                        style={{ pointerEvents: "none" }}
                      />
                    ),
                    unchecked: (
                      <img
                        src={sun}
                        width="20"
                        height="20"
                        role="presentation"
                        style={{ pointerEvents: "none" }}
                      />
                    ),
                  }}
                  onChange={() => {
                    document.documentElement.classList.toggle("night-mode");
                    if (
                      document.documentElement.classList.contains("night-mode")
                    ) {
                      setNightMode(true);
                      return;
                    }
                    setNightMode(false);
                  }}
                />
                {/* profile-icon*/}

                <SignedIn>
                  <a href="#" className="header-widget-icon profile-icon">
                    {jwtPayload.image ? (
                      <>
                        <img
                          src={jwtPayload.image}
                          alt="profile"
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "100%",
                          }}
                        />
                      </>
                    ) : (
                      <img
                        src={getGravatarImg(
                          jwtPayload.firstName,
                          jwtPayload.lastName
                        )}
                        alt="profile"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "100%",
                        }}
                      />
                    )}
                  </a>

                  <div
                    data-uk-dropdown="pos: top-right ;mode:click"
                    className="dropdown-notifications small"
                  >
                    {/* User Name / Avatar */}
                    <a href="profile-1.html">
                      <div className="dropdown-user-details">
                        <div className="dropdown-user-avatar">
                          {jwtPayload.image ? (
                            <div className="user-profile-photo  m-auto">
                              <img
                                src={jwtPayload.image}
                                alt="profile"
                                style={{ width: "50px", height: "46px" }}
                              />
                            </div>
                          ) : (
                            <img
                              src={getGravatarImg(
                                jwtPayload.firstName,
                                jwtPayload.lastName
                              )}
                              alt="profile"
                              style={{
                                width: "38px",
                                height: "38px",
                              }}
                            />
                          )}
                        </div>
                        <div className="dropdown-user-name">
                          {jwtPayload.firstName + " " + jwtPayload.lastName}{" "}
                          <span>Student</span>
                        </div>
                      </div>
                    </a>

                    {/* User menu */}

                    <ul className="dropdown-user-menu">
                      <li>
                        <Link to="/">
                          <i className="icon-material-outline-dashboard"></i>
                          Dashboard
                        </Link>
                      </li>

                      {/* <li>
                        <Link to="/courses">
                          <i className="icon-feather-shopping-bag"></i>
                          Courses
                        </Link>
                      </li> */}

                      <li>
                        <Link to="/profile">
                          <i className="icon-feather-settings"></i> Account
                          Settings
                        </Link>
                      </li>

                      <li className="menu-divider" />

                      <li>
                        <a
                          onClick={() => {
                            auth.logout();
                            history.push(`/login`);
                            forceUpdate();
                          }}
                        >
                          <i className="icon-feather-log-out"></i> Sign Out
                        </a>
                      </li>
                    </ul>
                  </div>
                </SignedIn>
              </div>

              {/* icon search*/}
              <a
                className="uk-navbar-toggle uk-hidden@s"
                data-uk-toggle="target: .nav-overlay; animation: uk-animation-fade"
                href="#"
              >
                <i className="uil-search icon-small"></i>
              </a>

              {/* User icons */}
              <a
                href="#"
                className="uil-user icon-small uk-hidden@s"
                data-uk-toggle="target: .header-widget ; cls: is-active"
              ></a>
            </div>
            {/* End Right Side Content / End */}
          </nav>
        </div>
        {/* container  / End */}
      </header>

      {/* 
      <div
        className="nav-overlay uk-navbar-left uk-position-relative uk-flex-1 bg-grey uk-light p-2"
        hidden
        style={{ zIndex: 100000 }}
      >
        <div
          className="uk-navbar-item uk-width-expand"
          style={{ minHeight: "60px" }}
        >
          <form className="uk-search uk-search-navbar uk-width-1-1">
            <input
              className="uk-search-input"
              type="search"
              placeholder="Search..."
              autoFocus
            />
          </form>
        </div>
        <a
          className="uk-navbar-toggle"
          data-uk-close
          data-uk-toggle="target: .nav-overlay; animation: uk-animation-fade"
          href="#"
        ></a>
      </div>
      */}
    </>
  );
};

export default Header;
