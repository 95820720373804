import http from "utils/http";
import { blog } from "constants/endpoints";

const postBlog = ({
  blogStatusId,
  categoryId,
  description,
  image,
  slug,
  title,
  blogContent,
  published,
}) => {
  const ENDPOINT = blog.postBlog();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${ENDPOINT}`, {
    data: {
      blogStatusId,
      categoryId,
      description,
      image,
      slug,
      title,
      blogContent,
      published,
    },
  });
};

export default postBlog;
