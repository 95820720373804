import { useState } from "react";

const InstructorPopup = ({ instructor, setShowDetails }) => {
  return (
    <div className="popup-boxs">
      <div className="boxs">
        <span className="close-icons" onClick={() => setShowDetails(false)}>
          x
        </span>
        {instructor.firstName} {instructor.lastName}
      </div>
    </div>
  );
};

export default InstructorPopup;
