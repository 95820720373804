import React from "react";
import { useHistory } from "react-router";

const InProgressModuleCard = ({
  moduleId,
  title,
  description,
  progress,
  courseImg = "../assets/images/course/1.png",
}) => {
  let history = useHistory();

  return (
    <div>
      <a
        onClick={() => {
          history.push(`/module/${moduleId}`);
        }}
      >
        <div className="course-card-resume">
          <div className="course-card-thumbnail">
            <img src={courseImg} alt="course" />
            <span className="play-button-trigger"></span>
          </div>
          <div className="course-card-resume-body">
            <h5 className="text-blue">{title}</h5>
            <span className="number">{description}</span>
            <div className="course-progressbar">
              <div
                className="course-progressbar-filler"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default InProgressModuleCard;
