import React, { useState, useRef } from "react";
import { useHistory } from "react-router";
import postUser from "services/postUser";
import useForm from "effects/useForm";
import ReCAPTCHA from "react-google-recaptcha";

const SignUp = () => {
  let history = useHistory();
  const captchaRef = useRef(null);

  const {
    REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY: GOOGLE_RECAPTCHA_SITE_KEY_PROD,
  } = process.env;

  const [isNextPage, setIsNextPage] = useState("");
  const [firstPageValidationError, setFirstPageValidationError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isVerified, setIsVerified] = useState(false);

  const {
    email,
    setEmail,
    emailErrorMessage,
    password,
    passwordErrorMessage,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    confirmPasswordErrorMessage,
    firstName,
    setFirstName,
    firstNameErrorMessage,
    lastName,
    setLastName,
    lastNameErrorMessage,
    dateOfBirth,
    setDateOfBirth,
    dateOfBirthErrorMessage,
    address,
    setAddress,
    addressErrorMessage,
    city,
    setCity,
    cityErrorMessage,
    country,
    setCountry,
    countryErrorMessage,
    phoneNumber,
    setPhoneNumber,
    phoneNumberErrorMessage,
    isValid,
  } = useForm({
    email: {
      validation: (email) => {
        if (email === "") {
          return "Email is required";
        }
      },
      initialValue: "",
    },
    password: {
      validation: (password) => {
        if (password === "") {
          return "Password is required";
        }
      },
      initialValue: "",
    },
    confirmPassword: {
      validation: (confirmPassword) => {
        if (confirmPassword === "") {
          return "Confirm your password";
        }
      },
      initialValue: "",
    },

    firstName: {
      validation: (firstName) => {
        if (firstName === "") {
          return "First name is required";
        }
      },
      initialValue: "",
    },
    lastName: {
      validation: (lastName) => {
        if (lastName === "") {
          return "Surname is required";
        }
      },
      initialValue: "",
    },
    dateOfBirth: {
      validation: (dateOfBirth) => {
        if (dateOfBirth === "") {
          return "Date of birth is required";
        }
      },
      initialValue: "",
    },
    address: {
      validation: (address) => {
        if (address === "") {
          return "Address is required";
        }
      },
      initialValue: "",
    },
    city: {
      validation: (city) => {
        if (city === "") {
          return "City is required";
        }
      },
      initialValue: "",
    },
    country: {
      validation: (country) => {
        if (country === "") {
          return "Country is required";
        }
      },
      initialValue: "",
    },
    phoneNumber: {
      validation: (phoneNumber) => {
        if (phoneNumber === "") {
          return "Phone number is required";
        }
      },
      initialValue: "",
    },
  });

  const checkFirstPageValid = () => {
    if (
      email === "" ||
      firstName === "" ||
      lastName === "" ||
      password === "" ||
      confirmPassword === ""
    ) {
      setFirstPageValidationError("Please fill all of the fields");
    } else {
      setIsNextPage(true);
    }
  };

  const handleSignUp = (event) => {
    event.preventDefault();
    const captcha = captchaRef.current.getValue();

    postUser({
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
      dateOfBirth,
      address,
      city,
      country,
      phoneNumber,
      captcha,
    })
      .then(() => {
        history.push("/registration-confirmation");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "err");
        setLoading(false);
      });
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleVerify = (e) => {
    e.preventDefault();
    setIsVerified(!isVerified);
    captchaRef.current.getResponse().then((res) => {
      setCaptchaToken(res);
    });
  };
  return (
    <>
      <div data-uk-height-viewport className="uk-flex uk-flex-middle">
        <div className="uk-width-2-3@m uk-width-1-2@s m-auto rounded">
          <div
            className="uk-child-width-1-2@m uk-grid-collapse bg-gradient-grey"
            data-uk-grid
          >
            <div className="uk-margin-auto-vertical uk-text-center uk-animation-scale-up p-3 uk-light">
              <i className=" uil-graduation-hat icon-large"></i>
              <h3 className="mb-4"> CyprusCodes</h3>
              <p>#ShapeYourFuture</p>
              <p>#TeknolojininAmbargosuOlmaz</p>
            </div>

            <div className="uk-card-default p-5 rounded">
              <div className="mb-4 uk-text-center">
                <h3 className="mb-0"> Create new Account</h3>
                <p className="my-2">Join CyprusCodes Now.</p>
              </div>
              {!isNextPage ? (
                <form className="uk-child-width-1-1 uk-grid-small" data-uk-grid>
                  <div className="uk-width-1-2@s">
                    <div className="uk-form-group">
                      <label className="uk-form-label"> First Name</label>

                      <div className="uk-position-relative w-100">
                        <span className="uk-form-icon">
                          <i className="icon-feather-user"></i>
                        </span>
                        <input
                          className="uk-input"
                          type="name"
                          placeholder="Name"
                          onChange={(e) => setFirstName(e.target.value)}
                          value={firstName}
                          required
                        />
                      </div>
                    </div>
                    {firstNameErrorMessage && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          "margin-top": "-7px",
                        }}
                      >
                        {firstNameErrorMessage}
                      </p>
                    )}
                  </div>
                  <div className="uk-width-1-2@s">
                    <div className="uk-form-group">
                      <label className="uk-form-label"> Surname</label>

                      <div className="uk-position-relative w-100">
                        <span className="uk-form-icon">
                          <i className="icon-feather-user"></i>
                        </span>
                        <input
                          className="uk-input"
                          type="surname"
                          placeholder="Surname"
                          required
                          onChange={(e) => setLastName(e.target.value)}
                          value={lastName}
                        />
                      </div>
                    </div>
                    {lastNameErrorMessage && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          "margin-top": "-7px",
                        }}
                      >
                        {lastNameErrorMessage}
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="uk-form-group">
                      <label className="uk-form-label"> Email</label>

                      <div className="uk-position-relative w-100">
                        <span className="uk-form-icon">
                          <i className="icon-feather-mail"></i>
                        </span>
                        <input
                          className="uk-input"
                          type="email"
                          placeholder="name@example.com"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </div>
                    </div>
                    {emailErrorMessage && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          "margin-top": "-7px",
                        }}
                      >
                        {emailErrorMessage}
                      </p>
                    )}
                  </div>

                  <div className="uk-width-1-2@s">
                    <div className="uk-form-group">
                      <label className="uk-form-label"> Password</label>

                      <div className="uk-position-relative w-100">
                        <button
                          className="uk-form-icon"
                          onClick={(event) => handleShowPassword(event)}
                          style={{
                            border: "none",
                            background: "rgba(0,0,0,0)",
                            outline: "0px",
                            cursor: "pointer",
                          }}
                        >
                          <i
                            className={
                              showPassword
                                ? "icon-feather-unlock"
                                : "icon-feather-lock"
                            }
                          ></i>
                        </button>
                        <input
                          className="uk-input"
                          type={showPassword ? "text" : "password"}
                          placeholder="********"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          required
                        />
                      </div>
                    </div>
                    {passwordErrorMessage && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          "margin-top": "-7px",
                        }}
                      >
                        {passwordErrorMessage}
                      </p>
                    )}
                  </div>
                  <div className="uk-width-1-2@s">
                    <div className="uk-form-group">
                      <label className="uk-form-label"> Confirm password</label>

                      <div className="uk-position-relative w-100">
                        <button
                          className="uk-form-icon"
                          onClick={(event) => handleShowConfirmPassword(event)}
                          style={{
                            border: "none",
                            background: "rgba(0,0,0,0)",
                            outline: "0px",
                            cursor: "pointer",
                          }}
                        >
                          <i
                            className={
                              showConfirmPassword
                                ? "icon-feather-unlock"
                                : "icon-feather-lock"
                            }
                          ></i>
                        </button>
                        <input
                          className="uk-input"
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="********"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          value={confirmPassword}
                          required
                        />
                      </div>
                    </div>
                    {confirmPasswordErrorMessage && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          "margin-top": "-7px",
                        }}
                      >
                        {confirmPasswordErrorMessage}
                      </p>
                    )}
                  </div>

                  <div>
                    {" "}
                    {firstPageValidationError && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          "margin-top": "-7px",
                        }}
                      >
                        {firstPageValidationError}
                      </p>
                    )}
                    <div
                      className="mt-4 uk-flex-middle uk-grid-small"
                      data-uk-grid
                    >
                      <div className="uk-width-expand@s">
                        <p>
                          {" "}
                          If you have account,{" "}
                          <a
                            href="#"
                            onClick={() => {
                              history.push(`/login}`);
                            }}
                          >
                            {" "}
                            Login
                          </a>
                        </p>
                      </div>
                      <div className="uk-width-2-5">
                        <input
                          type="submit"
                          className="btn btn-default"
                          value="Get Started"
                          onClick={(event) => {
                            event.preventDefault(event);
                            checkFirstPageValid();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <form className="uk-child-width-1-1 uk-grid-small" data-uk-grid>
                  <div
                    className="g-recaptcha"
                    data-sitekey={GOOGLE_RECAPTCHA_SITE_KEY_PROD}
                  ></div>

                  <div className="uk-width-1-2@s">
                    <div className="uk-form-group">
                      <label className="uk-form-label"> Date of birth</label>

                      <div className="uk-position-relative w-100">
                        <span className="uk-form-icon">
                          {/* <i className="icon-feather-calendar"></i> */}
                        </span>
                        <input
                          className="uk-input"
                          type="date"
                          onChange={(e) => setDateOfBirth(e.target.value)}
                          value={dateOfBirth}
                        />
                      </div>
                    </div>
                    {dateOfBirthErrorMessage && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          "margin-top": "-7px",
                        }}
                      >
                        {dateOfBirthErrorMessage}
                      </p>
                    )}
                  </div>
                  <div className="uk-width-1-2@s">
                    <div className="uk-form-group">
                      <label className="uk-form-label">Contact Number</label>

                      <div className="uk-position-relative w-100">
                        <span className="uk-form-icon">
                          <i className="icon-feather-phone"></i>
                        </span>
                        <input
                          className="uk-input"
                          type="telephone"
                          placeholder=""
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          value={phoneNumber}
                        />
                      </div>
                    </div>
                    {phoneNumberErrorMessage && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          "margin-top": "-7px",
                        }}
                      >
                        {phoneNumberErrorMessage}
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="uk-form-group">
                      <label className="uk-form-label"> Address </label>

                      <div className="uk-position-relative w-100">
                        <span className="uk-form-icon">
                          <i className="icon-feather-map-pin"></i>
                        </span>
                        <input
                          className="uk-input"
                          type="email"
                          placeholder=""
                          onChange={(e) => setAddress(e.target.value)}
                          value={address}
                        />
                      </div>
                    </div>
                    {addressErrorMessage && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          "margin-top": "-7px",
                        }}
                      >
                        {addressErrorMessage}
                      </p>
                    )}
                  </div>

                  <div className="uk-width-1-2@s">
                    <div className="uk-form-group">
                      <label className="uk-form-label"> City</label>

                      <div className="uk-position-relative w-100">
                        <span className="uk-form-icon"></span>
                        <input
                          className="uk-input"
                          type="text"
                          placeholder=""
                          onChange={(e) => setCity(e.target.value)}
                          value={city}
                        />
                      </div>
                    </div>
                    {cityErrorMessage && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          "margin-top": "-7px",
                        }}
                      >
                        {cityErrorMessage}
                      </p>
                    )}
                  </div>
                  <div className="uk-width-1-2@s">
                    <div className="uk-form-group">
                      <label className="uk-form-label"> Country</label>

                      <div className="uk-position-relative w-100">
                        <span className="uk-form-icon"></span>
                        <input
                          className="uk-input"
                          type="text"
                          placeholder=""
                          onChange={(e) => setCountry(e.target.value)}
                          value={country}
                        />
                      </div>
                    </div>
                    {countryErrorMessage && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          "margin-top": "-7px",
                        }}
                      >
                        {countryErrorMessage}
                      </p>
                    )}
                  </div>
                  <ReCAPTCHA
                    sitekey={GOOGLE_RECAPTCHA_SITE_KEY_PROD}
                    onChange={handleVerify}
                    ref={captchaRef}
                  />

                  <div>
                    <div
                      className="mt-4 uk-flex-middle uk-grid-small"
                      data-uk-grid
                    >
                      <div className="uk-width-expand@s">
                        <p>
                          {" "}
                          If you have account,{" "}
                          <a
                            href="#"
                            onClick={() => {
                              history.push(`/login}`);
                            }}
                          >
                            {" "}
                            Login
                          </a>
                        </p>
                      </div>

                      <div className="uk-width-auto@s">
                        {loading ? (
                          <i class="fas fa-spinner fa-spin"></i>
                        ) : (
                          <input
                            type="submit"
                            className="btn btn-default"
                            disabled={!isValid && !isVerified}
                            value="Register"
                            onClick={handleSignUp}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
