import React, { useState } from "react";
import postPageFeedback from "services/page/postPageFeedback";
import { ReactComponent as HappySVG } from "./images/happy.svg";
import { ReactComponent as NeutralSVG } from "./images/neutral.svg";
import { ReactComponent as SadSVG } from "./images/sad.svg";

const Feedback = ({ pageId, moduleId }) => {
  const [mood, setMood] = useState("");
  const [comments, setComments] = useState("");
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const handlePageFeedback = (newMood) => {
    postPageFeedback({ pageId, moduleId, comments, rating: newMood });
  };

  const showFeedbackForm = Boolean(mood) && !feedbackSubmitted;

  return (
    <>
      <div className="feedbackContainer" id="smileys">
        <SadSVG
          type="radio"
          name="smiley"
          value="sad"
          className={mood === 1 ? "selectedMoodSad" : "sad"}
          onClick={() => {
            setMood(1);
            handlePageFeedback(1);
          }}
        />
        <NeutralSVG
          type="radio"
          name="smiley"
          value="neutral"
          className={mood === 2 ? "selectedMoodNeutral" : "neutral"}
          onClick={() => {
            setMood(2);
            handlePageFeedback(2);
          }}
        />
        <HappySVG
          type="radio"
          name="smiley"
          value="happy"
          className={mood === 3 ? "selectedMoodHappy" : "happy"}
          onClick={() => {
            setMood(3);
            handlePageFeedback(3);
          }}
        />
      </div>

      {Boolean(showFeedbackForm) && (
        <div className="feedbackCommentSubmit">
          <textarea
            className="uk-textarea feedbackTextArea"
            placeholder="Thanks for your feedback. Let us know how we can improve this page."
            cols="4"
            onChange={(e) => setComments(e.target.value)}
            value={comments}
          ></textarea>

          <button
            type="button"
            className="btn btn-default icon-label feedbackButton"
            onClick={() =>{ handlePageFeedback(mood)
               setFeedbackSubmitted(true)}
            }
          >
            <span className="inner-text"> Submit </span>
          </button>
        </div> 
      )} 

      {Boolean(feedbackSubmitted)  && (
         <div className="feedbackCommentSubmit" 
         style={{marginTop:"10px", font: "bold"}}>Thanks for your feedback!</div>
      )

      }
    </>
  );
};

export default Feedback;
