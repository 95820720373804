export default `
/*
Reboot
Normalization of HTML elements, manually forked from Normalize.css to remove styles targeting irrelevant browsers while applying new styles.
Normalize is licensed MIT. https://github.com/necolas/normalize.css
* Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
*/
/*
Document
2. Change the default font family in all browsers.
3. Correct the line height in all browsers.
4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
5. Setting @viewport causes scrollbars to overlap content in IE11 and Edge, so we force a non-overlapping, non-auto-hiding scrollbar to counteract.
6. Change the default tap highlight to be completely transparent in iOS.
*/
*,
*::before,
*::after {
  box-sizing: border-box; /* 1 */
}
html {
  font-family: sans-serif; /* 2 */
  line-height: 1.15; /* 3 */
  -webkit-text-size-adjust: 100%; /* 4 */
  -ms-text-size-adjust: 100%; /* 4 */
  -ms-overflow-style: scrollbar; /* 5 */
  -webkit-tap-highlight-color: transparent; /* 6 */
}
@-ms-viewport {
  width: device-width;
}
/* stylelint-disable selector-list-comma-newline-after
Shim for "new" HTML5 structural elements to display correctly (IE10, older browsers) */
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}
/*
Body
1. Remove the margin in all browsers.
*/
body {
  margin: 0; /* 1 */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  text-align: left; /* 3 */
  background-color: #fff; /* 2 */
}
/*
Suppress the focus outline on elements that cannot be accessed via keyboard.
This prevents an unwanted focus outline from appearing around elements that might still respond to pointer events.
Credit: https://github.com/suitcss/base
*/
[tabindex="-1"]:focus {
  outline: none !important;
}
/* 
Content grouping
1. Add the correct box sizing in Firefox.
2. Show the overflow in Edge and IE.
*/
.ck hr {
  box-sizing: content-box; /* 1 */
  height: 1px; /* 1 */
  overflow: visible; /* 2 */
}
/* 
Typography
*/
/* 
Remove top margins from headings
*/
.ck h1, h2, h3, h4, h5, h6 {
  margin-top: 0 !important;
  margin-bottom: .5rem;
  color: unset;
}
/*
Reset margins on paragraphs
*/
.ck p {
  margin-top: 0;
  margin-bottom: 1rem;
}
/*
Abbreviations
1. Remove the bottom border in Firefox 39-.
2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
3. Add explicit cursor to indicate changed behavior.
4. Duplicate behavior to the data-* attribute for our tooltip plugin
*/
.ck abbr[title],
abbr[data-original-title] { /* 4 */
  text-decoration: underline; /* 2 */
  -webkit-text-decoration: underline dotted; /* 2 */
          text-decoration: underline dotted; /* 2 */
  cursor: help; /* 3 */
  border-bottom: 0; /* 1 */
}
.ck address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
.ck ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
.ck ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
.ck dt {
  font-weight: bold;
}
.ck dd {
  margin-bottom: .5rem;
  margin-left: 0; /* Undo browser default */
}
.ck blockquote {
  margin: 0 0 1rem;
}
.ck dfn {
  font-style: italic; /* Add the correct font style in Android 4.3- */
}
.ck b,
strong {
  font-weight: bolder; /* Add the correct font weight in Chrome, Edge, and Safari */
}
.ck small {
  font-size: 80%; /* Add the correct font size in all browsers */
}
.ck sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
.ck sub {
  bottom: -.25em;
}
.ck sup {
  top: -.5em;
}
/*
Links
*/
.ck a {
  color: #007bff;
  text-decoration: underline;
  background-color: transparent; /* Remove the gray background on active links in IE 10. */
  -webkit-text-decoration-skip: objects; /* Remove gaps in links underline in iOS 8+ and Safari 8+. */
}
.ck a:hover a{
  color: #0056b3;
  text-decoration: underline;
}
/*
And undo these styles for placeholder links/named anchors (without href) which have not been made explicitly keyboard-focusable (without tabindex). It would be more straightforward to just use a[href] in previous block, but that causes specificity issues in many other styles that are too complex to fix.
See https://github.com/twbs/bootstrap/issues/19402
*/
.ck a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
.ck a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
.ck a:not([href]):not([tabindex]):focus {
  outline: 0;
}
/*
Code
*/
.ck pre,
code,
kbd,
samp {
  font-family: monospace, monospace; /* Correct the inheritance and scaling of font size in all browsers. */
  white-space: break-spaces;
}
.ck pre {
  /* Remove browser default top margin */
  margin-top: 0;
  margin-bottom: 1rem; 
  /* Don't allow content to break outside */    
  overflow: auto;
  /* We have @viewport set which causes scrollbars to overlap content in IE11 and Edge, so we force a non-overlapping, non-auto-hiding scrollbar to counteract. */      
  -ms-overflow-style: scrollbar;
}
/*
Figures
*/
.ck figure {
  /* Apply a consistent margin strategy (matches our type styles). */
  margin: 0 0 1rem;
}
/*
Images and content
*/
.ck img {
  vertical-align: middle;
  border-style: none; /* Remove the border on images inside links in IE 10-. */
}
.ck svg:not(:root) {
  overflow: hidden; /* Hide the overflow in IE */
}
/*
See:
* https://getbootstrap.com/docs/4.0/content/reboot/#click-delay-optimization-for-touch
* https://caniuse.com/#feat=css-touch-action
*  https://patrickhlauke.github.io/touch/tests/results/#suppressing-300ms-delay
*/
.ck a,
area,
button,
[role="button"],
input:not([type=range]),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
      touch-action: manipulation;
}
/*
Tables
*/
.ck table {
  border-collapse: collapse; /* Prevent double borders */
}
.ck caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}
.ck th {
  text-align: inherit;
}
/*
Forms
*/
.ck label {
  display: inline-block;
  margin-bottom: .5rem;
}
/*
Details at https://github.com/twbs/bootstrap/issues/24093
*/
.ck button {
  border-radius: 0;
}
/*
Credit: https://github.com/suitcss/base/
*/
.ck button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
.ck input,
button,
select,
optgroup,
textarea {
  margin: 0; /* Remove the margin in Firefox and Safari */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.ck button,
input {
  overflow: visible; /* Show the overflow in Edge */
}
.ck button,
select {
  text-transform: none; /* Remove the inheritance of text transform in Firefox */
}
/*
2. Correct the inability to style clickable types in iOS and Safari.
*/
.ck button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/*
Remove inner border and padding from Firefox, but don't restore the outline like Normalize.
*/
.ck button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
.ck input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box; /* 1. Add the correct box sizing in IE 10- */
  padding: 0; /* 2. Remove the padding in IE 10- */
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  /* 
  Remove the default appearance of temporal inputs to avoid a Mobile Safari bug where setting a custom line-height prevents text from being vertically centered within the input.
  
  See https://bugs.webkit.org/show_bug.cgi?id=139848
  and https://github.com/twbs/bootstrap/issues/11266
  */
  -webkit-appearance: listbox;
}
.ck textarea {
  overflow: auto; /* Remove the default vertical scrollbar in IE. */
  /* Textareas should really only resize vertically so they don't break their (horizontal) containers. */
  resize: vertical;
}
.ck fieldset {
  /* 
  
  See https://github.com/twbs/bootstrap/issues/12359
  and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements  
  */
  min-width: 0;
  /* Reset the default outline behavior of fieldsets so they don't affect page layout. */
  padding: 0;
  margin: 0;
  border: 0;
}
/*
1. Correct the text wrapping in Edge and IE.
*/
.ck legend {
  display: block;
  width: 100%;
  max-width: 100%; /* 1 */
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit; /* 2 */
  white-space: normal; /* 1 */
}
.ck progress {
  vertical-align: baseline; /* Add the correct vertical alignment in Chrome, Firefox, and Opera. */
}
/* 
Correct the cursor style of increment and decrement buttons in Chrome.
*/
.ck [type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
.ck [type="search"] {
  /* 
  
  For details, see https://github.com/twbs/bootstrap/issues/11586.
  */    
  outline-offset: -2px;
  -webkit-appearance: none;
}
/*
Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
*/
.ck [type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/*
1. Correct the inability to style clickable types in iOS and Safari.
*/
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
/*
Correct element displays
*/
.ck output {
  display: inline-block;
}
.ck summary {
  display: list-item; /* Add the correct display in all browsers */
}
.ck template {
  display: none; /* Add the correct display in IE */
}
/* 
Needed for proper display in IE 10-.
*/
.ck [hidden] {
  display: none !important;
}`