import React, { useState } from "react";
import getBlogsByStatus from "services/Blog/getBlogsByStatus";
import useFetchData from "effects/useFetchData";
import AdminSideBar from "components/AdminSideBar";
import Footer from "components/Footer";
import postBlogByStatus from "services/Blog/getBlogsByStatus/publishBlogByStatus";
import EditorModal from "components/EditorModal";
import successNotification from "utils/successNotification";
import { Link } from "react-router-dom";

const AdminManageBlogs = ({ nightMode }) => {
  const [loading, errored, apiResponse, refreshBlogs] = useFetchData(() =>
    getBlogsByStatus()
  );
  const [isOpen, setIsOpen] = useState(false);
  const [blogToEdit, setBlogToEdit] = useState({});

  const { blogs } = apiResponse;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (errored) {
    return <div>Something went wrong</div>;
  }
  return (
    <>
      <AdminSideBar />
      {isOpen && (
        <EditorModal
          setOpen={setIsOpen}
          blog={blogToEdit}
          refreshBlogs={refreshBlogs}
          initialContent={blogToEdit.blogContent}
          nightMode={nightMode}
        />
      )}

      <div className="page-content">
        <div className="page-content-inner">
          <div className="d-flex">
            <nav id="breadcrumbs" className="mb-3">
              <ul>
                <li>
                  <a href="#">
                    {" "}
                    <i className="uil-home-alt"></i>{" "}
                  </a>
                </li>
                <li>
                  <a href="#"> Blogs </a>
                </li>
                <li>Blog's list</li>
              </ul>
            </nav>
          </div>
          <div className="card">
            {/* <!-- Card header --> */}
            <div className="card-header actions-toolbar border-0">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="d-inline-block mb-0">Blogs</h4>
              </div>
            </div>
            {/* <!-- Table --> */}
            <div className="table-responsive">
              <table className="table align-items-center">
                <thead>
                  <tr>
                    <th scope="col">Blog Title</th>
                    <th scope="col">Author </th>
                    <th scope="col">Date</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Publish</th>
                  </tr>
                </thead>
                <tbody>
                  {(blogs || []).map((blog) => {
                    return (
                      <tr>
                        <td>{blog.title}</td>
                        <td>
                          {blog.firstName} {blog.lastName}
                        </td>
                        <td>{blog.publishedAt}</td>
                        <td>
                          <button
                            class="btn btn-success"
                            onClick={() => {
                              setBlogToEdit(blog);
                              setIsOpen(true);
                            }}
                          >
                            Edit
                          </button>
                        </td>
                        <td>
                          <button class="btn btn-success" type="button">
                            Publishing Options{" "}
                          </button>
                          <div data-uk-dropdown="mode: click">
                            <ul
                              style={{
                                backgroundColor: "transparent",
                                color: "#333 !important",
                                border: "#e5e5e5",
                                borderWidth: "10px",
                              }}
                            >
                              <Link to="/blogs">
                                <span
                                  onClick={() => {
                                    postBlogByStatus({
                                      blogStatusId: 4,
                                      blogId: blog.blogId,
                                    })
                                      .then(refreshBlogs)
                                      .then(() => {
                                        successNotification("Blog published.");
                                      });
                                  }}
                                >
                                  Publish Internal
                                </span>
                              </Link>
                              <li>
                                <Link to="/blogs">
                                  <span
                                    onClick={() => {
                                      postBlogByStatus({
                                        blogStatusId: 3,
                                        blogId: blog.blogId,
                                      })
                                        .then(refreshBlogs)
                                        .then(() => {
                                          successNotification(
                                            "Blog published."
                                          );
                                        });
                                    }}
                                  >
                                    Publish Public
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AdminManageBlogs;
