import http from "utils/http";
import { page } from "constants/endpoints";

const postPageComplete = ({ pageId, chapterId, moduleId }) => {
  const POST_PAGECOMPLETE_ENDPOINT = page.postComplete({ pageId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${POST_PAGECOMPLETE_ENDPOINT}`, {
    data: { chapterId, moduleId },
  });
};

export default postPageComplete;
