import http from "utils/http";
import { moduleEndpoint } from "constants/endpoints";

const postUserModule = ({ userId, moduleId }) => {
  const ENDPOINT = moduleEndpoint.postUserModule({ userId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${ENDPOINT}`, {
    data: {
      moduleId,
    },
  });
};

export default postUserModule;
