import React from "react";
import { createPortal } from "react-dom";

const Modal = ({ close, body, title, actionBar }) => {
  return createPortal(
    <div
      id="modal-sections"
      className="uk-open"
      data-uk-modal
      style={{ display: "block" }}
    >
      {" "}
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        {" "}
        <button
          class="uk-modal-close-default"
          type="button"
          data-uk-close
          onClick={close}
        ></button>{" "}
        {title && (
          <div class="uk-modal-header">
            {" "}
            <h2 class="uk-modal-title">{title}</h2>{" "}
          </div>
        )}
        <div class="uk-modal-body">{body}</div>{" "}
        {actionBar && (
          <div class="uk-modal-footer uk-text-right">{actionBar}</div>
        )}{" "}
      </div>{" "}
    </div>,
    document.body
  );
};

export default Modal;
