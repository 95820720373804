import React from "react";
import SideBar from "components/SideBar";
import { Link } from "react-router-dom";
import Footer from "components/Footer";
import Authentication from "services/Authentication";
import getGravatarImg from "utils/getGravatarImg";
import getUserModules from "services/getUserModules";
import useFetchData from "effects/useFetchData";
import { useHistory } from "react-router";
import "./accountSettings.css";

const auth = new Authentication();

const AccountSettings = () => {
  const [loading, errored, apiResponse] = useFetchData(() => getUserModules());
  const { studentModules } = apiResponse;
  let history = useHistory();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (errored) {
    return <div>Something went wrong</div>;
  }
  const jwtPayload = auth.getAccessTokenPayload();
  return (
    <>
      <SideBar />
      {/* <!-- content --> */}
      <div className="page-content">
        {/* 
         <!-- Header Container
        ================================================== --> */}
        <div className="page-content-inner">
          <h2>Profile</h2>

          <nav className="responsive-tab mb-4">
            <li className="uk-active">
              <a href="#">Account</a>
            </li>

            {/* <li>
              <a href="#">Billing</a>
            </li> */}

            <li>
              <a href="user-profile-edit.html">Settings</a>
            </li>
          </nav>

          <div data-uk-grid>
            <div className="uk-width-2-5@m">
              <div className="uk-card-default rounded text-center p-4">
                <div className="user-profile-photo  m-auto">
                  {jwtPayload.image ? (
                    <div className="user-profile-photo  m-auto">
                      <img src={jwtPayload.image} alt="profile" />
                    </div>
                  ) : (
                    <div className="user-profile-photo  m-auto">
                      <img
                        src={getGravatarImg(
                          jwtPayload.firstName,
                          jwtPayload.lastName
                        )}
                        alt="profile"
                      />
                    </div>
                  )}
                </div>

                <h4 className="mb-2 mt-3 text-blue">
                  {" "}
                  {jwtPayload.firstName + " " + jwtPayload.lastName}{" "}
                </h4>
                <div>
                  <p>{jwtPayload.email}</p>
                </div>
                <p className="m-0"> Member since Nov 23 2020 </p>
              </div>

              <div className="uk-card-default rounded mt-4">
                <div className="uk-flex uk-flex-between uk-flex-middle py-3 px-4">
                  <h5 className="mb-0 text-blue"> Progress </h5>
                </div>
                <hr className="m-0" />

                <div className="p-3">
                  {studentModules.map((module) => {
                    return (
                      <div
                        onClick={() => {
                          history.push(`/module/${module.moduleId}`);
                        }}
                        className="uk-grid-small uk-flex-middle clickable"
                        data-uk-grid
                      >
                        <div className="uk-width-auto">
                          <button className="btn btn-icon-only">
                            <img src={module.image}></img>
                          </button>
                        </div>
                        <div className="uk-width-expand">
                          <h5 className="mb-2 text-blue"> {module.title} </h5>
                          <div className="course-progressbar">
                            <div
                              className="course-progressbar-filler"
                              style={{ width: `${module.progress}%` }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="uk-width-expand@m">
              <div className="uk-card-default rounded">
                <div className="uk-flex uk-flex-between uk-flex-middle py-3 px-4">
                  <h5 className="mb-0 text-blue"> Personal details </h5>
                  <Link to="/profile-edit">
                    <i className="icon-feather-settings"></i>{" "}
                  </Link>
                </div>
                <hr className="m-0" />
                <div
                  className="uk-child-width-1-2@s uk-grid-small p-4"
                  data-uk-grid
                >
                  <div>
                    <h6 className="uk-text-bold"> First Name </h6>
                    <p> {jwtPayload.firstName} </p>
                  </div>
                  <div>
                    <h6 className="uk-text-bold"> Last Name </h6>
                    <p> {jwtPayload.lastName} </p>
                  </div>
                  <div>
                    <h6 className="uk-text-bold"> Date of Birth </h6>
                    <p> {jwtPayload.dateOfBirth}</p>
                  </div>

                  <div>
                    <h6 className="uk-text-bold"> Phone </h6>
                    <p> {jwtPayload.phoneNumber}</p>
                  </div>
                </div>
              </div>
              {/* <div className="uk-card-default rounded mt-4">
                <div className="uk-flex uk-flex-between uk-flex-middle py-3 px-4">
                  <h5 className="mb-0"> Account details </h5>
                  <Link to="/profile-edit">
                    <i className="icon-feather-settings"></i>{" "}
                  </Link>
                </div>
                <hr className="m-0" />
                <div
                  className="uk-child-width-1-2@s uk-grid-small p-4"
                  data-uk-grid
                >
                  <div>
                    <h6 className="uk-text-bold"> First Name </h6>
                    <p> {jwtPayload.firstName} </p>
                  </div>
                  <div>
                    <h6 className="uk-text-bold"> Second Name </h6>
                    <p> {jwtPayload.lastName} </p>
                  </div>
                  <div>
                    <h6 className="uk-text-bold"> Email address </h6>
                    <p> {jwtPayload.email}</p>
                  </div>
                  <div>
                    <h6 className="uk-text-bold"> Phone </h6>
                    <p> {jwtPayload.phoneNumber}</p>
                  </div>
                
                </div>
              </div> */}

              <div className="uk-card-default rounded mt-4">
                <div className="uk-flex uk-flex-between uk-flex-middle py-3 px-4">
                  <h5 className="mb-0 text-blue"> Billing Address </h5>
                  <Link to="/profile-edit">
                    <i className="icon-feather-settings"></i>{" "}
                  </Link>
                </div>
                <hr className="m-0" />
                <div
                  className="uk-child-width-1-2@s uk-grid-small p-4"
                  data-uk-grid
                >
                  <div>
                    <h6 className="uk-text-bold"> Address </h6>
                    <p> {jwtPayload.adress}</p>
                  </div>
                  <div>
                    <h6 className="uk-text-bold"> Address Line 2 </h6>
                    <p> Gelibolu</p>
                  </div>
                  <div>
                    <h6 className="uk-text-bold"> City </h6>
                    <p> {jwtPayload.city} </p>
                  </div>
                  <div>
                    <h6 className="uk-text-bold"> Postal Code </h6>
                    <p> {jwtPayload.postcode} </p>
                  </div>
                  <div>
                    <h6 className="uk-text-bold"> Town </h6>
                    <p> {jwtPayload.town} </p>
                  </div>
                  <div>
                    <h6 className="uk-text-bold"> Country </h6>
                    <p> {jwtPayload.country} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AccountSettings;
