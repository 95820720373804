import http from "utils/http";
import { blog } from "constants/endpoints";

const getBlogsByStatus = () => {
  const BLOG_ENDPOINT = blog.getBlogsByStatus();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${BLOG_ENDPOINT}`);
};

export default getBlogsByStatus;
