import React, {useEffect} from "react";

const FullscreenModal = ({ closeModal, children }) => {
  useEffect(() => {
    document.body.style = "overflow: hidden";

    return () => {
      document.body.style = "";
    }
  }, [])

  return (
    <div
      id="modal-full"
      className="uk-modal-full uk-open"
      data-uk-modal
      style={{ display: "block" }}
    >
      <div class="uk-modal-dialog pr-3 pl-5">
        <button
          class="uk-modal-close-full uk-close-large"
          type="button"
          data-uk-close
          onClick={closeModal}
        ></button>

        <div style={{ minHeight: "calc(100vh)" }} data-uk-height-viewport="" className="pt-3">
          {children}
        </div>
      </div>
    </div>
  );
};

export default FullscreenModal;
