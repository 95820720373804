import React, { useState } from "react";
import styled from "styled-components";
import useFetchData from "effects/useFetchData";
import flattenDeep from "utils/flattenDeep";
import getActiveModules from "services/getActiveModules";
import postUserModule from "services/postUserModule";
import successNotification from "utils/successNotification";
import deleteUserModule from "services/deleteUserModule";
import getActiveStudentModules from "services/getActiveStudentModules";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

const ModalContainer = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999 !important;
`;

const ModalInner = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  max-width: 700px;
  margin-left: 30%;
  padding: 0.5rem;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  box-shadow: 0 0 100px rgba(0, 0, 0, 1);
`;

const Button = styled.button`
  background-color: #36a24a;
  border: none;
  box-sizing: border-box;
  color: #fff;
  border-radius: 3px;
  padding: 8px;
  margin-top: 10px;
  margin-left: 6px;
  font-weight: 600;
  cursor: pointer;
  :hover {
    border: none;
    background-color: #3659a2;
  }
`;

const RemoveModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const RemoveModuleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`;

const ButtonWrapper = styled.div`
  display: "flex";
  align-items: "center";
`;

const ManageModules = ({ setShowManageModulesModal, student, nightMode }) => {
  const [loading, setLoading] = useState(false);
  const [loadingModule, error, getModulesResponse] = useFetchData(() =>
    getActiveModules()
  );

  const { userId } = student;

  const [
    loadingActiveModule,
    activeModuleError,
    getActiveStudentModulesResponse,
    fetchActiveStudentModules,
  ] = useFetchData(() => getActiveStudentModules({ userId }));

  if (loading || loadingModule || loadingActiveModule) {
    return <div>Loading...</div>;
  }

  if (error || activeModuleError) {
    return <div>Something went wrong</div>;
  }
  const { activeStudentModules } = getActiveStudentModulesResponse;
  const { activeModules } = getModulesResponse;

  const modules = flattenDeep(
    activeModules.map((item) =>
      item.programs.map((program) => {
        return program.modules.map((module) => {
          return module;
        });
      })
    )
  );

  const formattedStudentModules = activeStudentModules.map(
    (module) => module.module_id
  );

  const handleAddModule = (moduleId, userId) => {
    if (formattedStudentModules.includes(moduleId)) return;
    postUserModule({
      moduleId,
      userId,
    })
      .then(() => {
        setLoading(false);
        fetchActiveStudentModules({ userId });
        successNotification("Module successfully added ✅");
      })
      .catch((err) => console.log(err, "Something went wrong"));
  };

  const handleRemoveModule = (moduleId, userId) => {
    deleteUserModule({
      moduleId,
      userId,
    })
      .then(() => {
        setLoading(false);
        fetchActiveStudentModules({ userId });
        successNotification("Module successfully deleted");
      })
      .catch((err) => console.log(err, "Something went wrong"));
  };

  return (
    <ModalContainer>
      <ModalInner
        style={{ backgroundColor: nightMode && "#232020", border: "none" }}
      >
        <Wrapper>
          <h2 className="title-table-st">Manage Modules</h2>
          <i
            className="icon-line-awesome-close"
            style={{
              cursor: "pointer",
              backgroundColor: "#3659a2",
              color: "#fff",
              padding: "5px",
              borderRadius: "3px",
            }}
            onClick={() => setShowManageModulesModal(false)}
          ></i>
        </Wrapper>
        <RemoveModuleContainer>
          {modules.map((module, idx) => {
            return (
              <RemoveModuleWrapper key={idx}>
                <h6 className="m-1" value={module.moduleId}>
                  {module.title}
                </h6>
                <ButtonWrapper>
                  {!formattedStudentModules.includes(module.moduleId) ? (
                    <Button
                      onClick={() =>
                        handleAddModule(module.moduleId, student.userId)
                      }
                    >
                      Add
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        handleRemoveModule(module.moduleId, student.userId)
                      }
                      style={{
                        background: activeStudentModules && "#FF0000",
                      }}
                    >
                      Remove
                    </Button>
                  )}
                </ButtonWrapper>
              </RemoveModuleWrapper>
            );
          })}
        </RemoveModuleContainer>
      </ModalInner>
    </ModalContainer>
  );
};

export default ManageModules;
