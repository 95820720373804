import React from "react";
import useFetchData from "effects/useFetchData";
import ActivityGraph from "components/ActivityGraph";
import getStudentsLastPages from "services/getStudentsLastPages";
import getStudentsCheckedPages from "services/getStudentsCheckedPages";
import getStudentActivity from "services/getStudentActivity";
import "./style.css";

const StudentPopup = ({ student, setShowDetails, nightMode }) => {
  const [isLoadingLastPage, errorFetchingLastPage, apiResponse] = useFetchData(
    () => getStudentsLastPages({ userId: student.userId })
  );

  const [isLoadingCheckedPage, errorFetchingCheckedPage, apiResponseOne] =
    useFetchData(() => getStudentsCheckedPages({ userId: student.userId }));

  const [isLoadingActivity, errorFetchingActivity, activityResponse] =
    useFetchData(() => getStudentActivity({ userId: student.userId }));

  if (isLoadingActivity) {
    return <p>Loading..</p>;
  }
  if (errorFetchingActivity) {
    return <p>Server Error..</p>;
  }

  const pages = apiResponse;
  const checkedPages = apiResponseOne;
  const studentActivity = activityResponse.summary;

  if (isLoadingLastPage) {
    return <p>Loading..</p>;
  }
  if (errorFetchingLastPage) {
    return <p>Server Error..</p>;
  }

  if (isLoadingCheckedPage) {
    return <p>Loading..</p>;
  }
  if (errorFetchingCheckedPage) {
    return <p>Server Error..</p>;
  }
  return (
    <div className="popup-boxs">
      <div
        className="popup-inner"
        style={{ backgroundColor: nightMode && "#001" }}
      >
        <div className="close-button-div">
          <button
            className="btn btn-danger mr-2"
            onClick={() => setShowDetails(false)}
          >
            Close
          </button>
        </div>
        <h5 className="title-table-st">Students Information</h5>

        <table>
          <table>
            <tr className="table-header">
              <th>
                <h5>Full Name</h5>
              </th>
              <th>
                <h5>Surname</h5>
              </th>
              <th>
                <h5>Email</h5>
              </th>
              <th>
                <h5>Phone</h5>
              </th>
            </tr>
            <tr className="table-data">
              <td>
                <p style={{ color: nightMode && "#fff" }}>
                  {student.firstName}
                </p>
              </td>
              <td>
                <p style={{ color: nightMode && "#fff" }}>{student.lastName}</p>
              </td>
              <td>
                <p style={{ color: nightMode && "#fff" }}>{student.email}</p>
              </td>
              <td>
                <p style={{ color: nightMode && "#fff" }}>
                  {student.phoneNumber}
                </p>
              </td>
            </tr>
          </table>
          <div>
            <h5 className="title-table-st">Last Viewed Pages</h5>
            <tr className="table-header">
              <th>
                <h5>Page Title</h5>
              </th>
              <th>
                <h5>Date</h5>
              </th>
            </tr>
            {checkedPages?.studentsCheckedPage?.map((checkedPage, i) => {
              return (
                <tr key={i} className="table-data">
                  <td>
                    {" "}
                    <p style={{ color: nightMode && "#fff" }}>
                      {checkedPage.title}
                    </p>
                  </td>
                  <td>
                    {" "}
                    <p style={{ color: nightMode && "#fff" }}>
                      {checkedPage.viewedAt}
                    </p>
                  </td>
                </tr>
              );
            })}
            <div>
              <h5 className="title-table-st">Last Completed Pages</h5>
              <tr className="table-header">
                <th>
                  <h5>Page Title</h5>
                </th>
                <th>
                  <h5>Date</h5>
                </th>
              </tr>
              {pages?.studentsLastPage?.map((page, i) => {
                return (
                  <tr key={i} className="table-data">
                    <td>
                      {" "}
                      <p style={{ color: nightMode && "#fff" }}>{page.title}</p>
                    </td>
                    <td>
                      {" "}
                      <p style={{ color: nightMode && "#fff" }}>
                        {page.completedAt}
                      </p>
                    </td>
                  </tr>
                );
              })}
            </div>
            <div
              className="activity-graph-explainer-container"
              style={{
                padding: "4%",
                position: "absolute",
                marginTop: "25px",
              }}
            >
              <div
                className="profile-layout-badge"
                style={{
                  background: "#004db1",
                  padding: "2rem",
                  borderRadius: "10px",
                }}
              >
                <ActivityGraph days={studentActivity} />
              </div>
            </div>
          </div>
        </table>
      </div>
    </div>
  );
};

export default StudentPopup;
