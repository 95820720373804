import { React, useState } from "react";
import successNotification from "utils/successNotification";
import dangerNotification from "utils/dangerNotification";
import deleteStudent from "services/user/deleteStudentDetails";

const StudentPopupDelete = ({ setShowDelete, nightMode, student }) => {
  const [loading, setLoading] = useState(false);
  const handleDelete = () => {
    setLoading(true);
    deleteStudent({ studentId: student.userId })
      .then(() => {
        setLoading(false);
        setShowDelete(false);
        successNotification("Deleted");
      })
      .catch((err) => {
        setLoading(false);
        dangerNotification("Something went wrong!");
      });
  };
  return (
    <div className="popup-boxs">
      <div className="popup-delete boxs" style={{ marginTop: 300 }}>
        <p style={{ margin: 20, color: nightMode && "#001" }}>
          Do you want delete this user ?
        </p>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <button
            type="button"
            className="btn btn-success"
            onClick={(event) => {
              event.preventDefault();
              handleDelete();
            }}
          >
            {loading ? "Deleting..." : "Delete User"}
          </button>
          <button
            type="button"
            className="btn btn-delete"
            onClick={() => setShowDelete(false)}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default StudentPopupDelete;
