import React from "react";

const FailedPayment = () => {
  return (
    <div class="page-content">
      <div class="bg-pricing"></div>

      <div class="page-content-inner">
        <div class="pricing pricing-container">
          <div class="uk-light text-center">
            <h1 style={{ color: "#3e416d" }}> Payment Status</h1>
          </div>

          <div class="pricing-card">
            <div class="text-center">
              <div>
                <h1 class="price ">
                  <i
                    className="icon-line-awesome-close"
                    style={{
                      backgroundColor: "#dc3545",
                      color: "#fff",
                      padding: "10px",
                      borderRadius: "50%",
                    }}
                  ></i>
                </h1>
              </div>
              <h4
                class="plan-name"
                style={{ marginTop: "25px", marginBottom: "10px" }}
              >
                Payment Failed
              </h4>
              <a
                href="/course-payment-module/1"
                className="btn btn-success  btn-small mt-3 mr-4"
              >
                Try Again
              </a>
              <a href="/courses" className="btn btn-info  btn-small mt-3">
                Explore More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FailedPayment;
