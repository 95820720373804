import React, { useState } from "react";
import useForm from "effects/useForm";
import successNotification from "utils/successNotification";
import dangerNotification from "utils/dangerNotification";
import postInstructor from "services/postInstructor";
import styled from "styled-components";

const ModalContainer = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999 !important;
`;

const ModalInner = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  max-width: 700px;
  margin-left: 30%;
  padding: 0.5rem;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
`;

const InstructorModal = ({ handleCloseModal, fetchAllInstructors }) => {
  const [loading, setLoading] = useState(false);

  const {
    email,
    setEmail,
    emailErrorMessage,

    isValid,
  } = useForm({
    email: {
      validation: (email) => {
        if (email === "") {
          return "Email is required";
        }
      },
    },
  });

  const handleSave = () => {
    setLoading(true);
    postInstructor({
      email,
    })
      .then(() => {
        setLoading(false);
        successNotification("Saved");
        fetchAllInstructors();
      })
      .catch((err) => {
        setLoading(false);
        dangerNotification("Something went wrong!");
      });
  };

  return (
    <>
      <ModalContainer style={{ backdropFilter: "blur(5px)" }}>
        <ModalInner>
          <div className="uk-card-default rounded">
            <div className="p-3">
              <h5 className="mb-0 text-blue"> Add Instructor </h5>
            </div>
            <hr className="m-0" />
            <form
              className="uk-child-width-1-2@s uk-grid-small p-4"
              data-uk-grid
            >
              <div style={{ width: "700px" }}>
                <h5 className="uk-text-bold mb-2 text-blue"> Email </h5>
                <input
                  type="text"
                  className="uk-input"
                  placeholder="instructor email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
                {emailErrorMessage && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      "margin-top": "-7px",
                    }}
                  >
                    {emailErrorMessage}
                  </p>
                )}
              </div>
            </form>

            <div className="uk-flex uk-flex-right p-4">
              <button
                className="btn btn-link mr-2"
                onClick={() => handleCloseModal(true)}
              >
                Cancel
              </button>
              <button
                className="btn btn-default grey"
                disabled={!isValid}
                onClick={(event) => {
                  event.preventDefault();
                  handleSave();
                  handleCloseModal(true);
                }}
              >
                {loading ? "Saving..." : "Save changes"}
              </button>
            </div>
          </div>
        </ModalInner>
      </ModalContainer>
    </>
  );
};

export default InstructorModal;
