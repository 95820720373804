import http from "utils/http";
import { moduleEndpoint } from "constants/endpoints";

const deleteUserModule = ({ moduleId, userId }) => {
  const DELETE_PAGECOMPLETE_ENDPOINT = moduleEndpoint.deleteUserModule({
    moduleId,
  });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.delete(`${API_BASE_URL}${DELETE_PAGECOMPLETE_ENDPOINT}`, {
    data: { userId },
  });
};

export default deleteUserModule;
