import React, { useState } from "react";
import StudentPopup from "components/StudentPopupWindow";
import getGravatarImg from "utils/getGravatarImg";
import StudentPopupEdit from "components/StudentPopupEdit";
import StudentPopupDelete from "components/StudentPopupDelete";
import ManageModules from "components/ManageModulesPopup";

import { chapter } from "constants/endpoints";
import AddUserPayment from "components/AddUserPaymentPopup";

const StudentCard = ({ student, nightMode }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showAddUserPayment, setShowAddUserPaymentModal] = useState(false);
  const [showManageModulesModal, setShowManageModulesModal] = useState(false);

  return (
    <>
      <tr>
        <td>
          <div className="media align-items-center">
            <div>
              <div
                className="avatar-parent-child"
                style={{ width: "max-content" }}
              >
                {student.image ? (
                  <img
                    src={student.image}
                    alt="profile"
                    className="header-profile-icon"
                  />
                ) : (
                  <img
                    src={getGravatarImg(student.firstName, student.lastName)}
                    alt="profile"
                    className="header-profile-icon"
                  />
                )}
                <span className="avatar-child avatar-badge bg-success"></span>
              </div>
            </div>
            <div className="media-body ml-4">
              <a href="#" className="name h6 mb-0 text-sm">
                {student.firstName} {student.lastName}
              </a>
            </div>
          </div>
        </td>
        <td className="uk-text-bold mb-2">
          {student.email} {chapter.chapterId}
        </td>
        <td className="uk-text-bold mb-2"> {student.phoneNumber}</td>
        <td>
          <div className="actions">
            <a
              href="#"
              className="btn btn-icon btn-hover btn-sm btn-circle"
              data-uk-tooltip="Quick view"
              onClick={() => setShowDetails(true)}
              style={{ marginRight: "5px" }}
            >
              <i className="uil-external-link-alt"></i>
            </a>
            <a
              href="#"
              className="btn btn-icon btn-hover btn-sm btn-circle"
              data-uk-tooltip="Manage Modules"
              onClick={() => setShowManageModulesModal(true)}
              style={{ marginRight: "5px" }}
            >
              <ion-icon
                name="construct-outline"
                style={{ fontSize: "20px", marginTop: "4px" }}
              ></ion-icon>
            </a>

            <a
              href="#"
              className="btn btn-icon btn-hover btn-sm btn-circle"
              data-uk-tooltip="Add Payment"
              onClick={() => setShowAddUserPaymentModal(true)}
              style={{ marginRight: "5px" }}
            >
              <ion-icon
                name="add-circle-outline"
                style={{ fontSize: "20px", marginTop: "4px" }}
              ></ion-icon>
            </a>
            <div
              className="btn btn-icon btn-hover btn-sm btn-circle"
              data-uk-tooltip="Edit"
              onClick={() => setShowEdit(true)}
              style={{ marginRight: "5px" }}
            >
              <i className="uil-pen "></i>
            </div>
            <div
              className="btn btn-icon btn-hover btn-sm btn-circle"
              data-uk-tooltip="Delete user"
              onClick={() => setShowDelete(true)}
            >
              <i className="uil-trash-alt text-danger"></i>
            </div>
          </div>
        </td>
      </tr>
      {showManageModulesModal && (
        <ManageModules
          setShowManageModulesModal={setShowManageModulesModal}
          student={student}
          nightMode={nightMode}
        />
      )}
      {showAddUserPayment && (
        <AddUserPayment
          setShowAddUserPaymentModal={setShowAddUserPaymentModal}
          student={student}
          nightMode={nightMode}
        />
      )}
      {showDetails && (
        <StudentPopup
          setShowDetails={setShowDetails}
          student={student}
          nightMode={nightMode}
        />
      )}
      {showDelete && (
        <StudentPopupDelete
          setShowDelete={setShowDelete}
          student={student}
          nightMode={nightMode}
        />
      )}
      {showEdit && (
        <StudentPopupEdit setShowEdit={setShowEdit} student={student} />
      )}
    </>
  );
};

export default StudentCard;
