/* eslint-disable import/no-anonymous-default-export */
import http from "utils/http";
import { blog } from "constants/endpoints";

const postBlogByStatus = ({ blogStatusId, blogId }) => {
  const ENDPOINT = blog.postBlogByStatus({ blogId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${ENDPOINT}`, {
    data: {
      blogStatusId,
    },
  });
};

export default postBlogByStatus;
