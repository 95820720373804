/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";

const CourseList = () => {
  return (
    <>
      <div id="wrapper">
        <div
          className="nav-overlay uk-navbar-left uk-position-relative uk-flex-1 bg-grey uk-light p-2"
          hidden
          style={{ zIndex: "10000" }}
        >
          <div
            className="uk-navbar-item uk-width-expand"
            style={{ minHeight: "60px" }}
          >
            <form className="uk-search uk-search-navbar uk-width-1-1">
              <input
                className="uk-search-input"
                type="search"
                placeholder="Search..."
                autoFocus
              />
            </form>
          </div>
          <a
            className="uk-navbar-toggle"
            data-uk-close
            data-uk-toggle="target: .nav-overlay; animation: uk-animation-fade"
            href="#"
          ></a>
        </div>

        {/* Content */}

        <div className="page-content">
          <div className="container">
            <div className="mt-lg-5" data-uk-grid>
              <div className="uk-width-1-4@m">
                <div
                  className="sidebar-filter"
                  data-uk-sticky="top:20 ;offset: 90; bottom: true ; media : @m"
                >
                  <button
                    className="btn-sidebar-filter"
                    data-uk-toggle="target: .sidebar-filter; cls: sidebar-filter-visible"
                  >
                    Filter{" "}
                  </button>

                  <div className="sidebar-filter-contents">
                    <h4> Filter By </h4>

                    <ul
                      className="sidebar-filter-list"
                      data-uk-accordion="multiple: true"
                    >
                      <li className="uk-open">
                        <a className="uk-accordion-title" href="#">
                          {" "}
                          Skill Levels{" "}
                        </a>
                        <div className="uk-accordion-content">
                          <div className="uk-form-controls">
                            <label>
                              <input
                                className="uk-radio"
                                type="radio"
                                name="radio1"
                              />
                              <span className="test">
                                {" "}
                                Beginner <span> (25) </span>{" "}
                              </span>
                            </label>
                            <label>
                              <input
                                className="uk-radio"
                                type="radio"
                                name="radio1"
                              />
                              <span className="test">
                                {" "}
                                Entermidate<span> (32) </span>
                              </span>
                            </label>
                            <label>
                              <input
                                className="uk-radio"
                                type="radio"
                                name="radio1"
                              />
                              <span className="test">
                                {" "}
                                Expert <span> (12) </span>
                              </span>
                            </label>
                          </div>
                        </div>
                      </li>

                      <li className="uk-open">
                        <a className="uk-accordion-title" href="#">
                          {" "}
                          Course type{" "}
                        </a>
                        <div className="uk-accordion-content">
                          <div className="uk-form-controls">
                            <label>
                              <input
                                className="uk-radio"
                                type="radio"
                                name="radio2"
                              />
                              <span className="test"> Free (42) </span>
                            </label>
                            <label>
                              <input
                                className="uk-radio"
                                type="radio"
                                name="radio2"
                              />
                              <span className="test"> Paid </span>
                            </label>
                          </div>
                        </div>
                      </li>

                      <li className="uk-open">
                        <a className="uk-accordion-title" href="#">
                          {" "}
                          Duration time{" "}
                        </a>
                        <div className="uk-accordion-content">
                          <div className="uk-form-controls">
                            <label>
                              <input
                                className="uk-radio"
                                type="radio"
                                name="radio3"
                              />
                              <span className="test"> +5 Hourse (23) </span>
                            </label>
                            <label>
                              <input
                                className="uk-radio"
                                type="radio"
                                name="radio3"
                              />
                              <span className="test"> +10 Hourse (12)</span>
                            </label>
                            <label>
                              <input
                                className="uk-radio"
                                type="radio"
                                name="radio3"
                              />
                              <span className="test"> +20 Hourse (5)</span>
                            </label>
                            <label>
                              <input
                                className="uk-radio"
                                type="radio"
                                name="radio3"
                              />
                              <span className="test"> +30 Hourse (2)</span>
                            </label>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="uk-width-expand@m">
                <div className="section-header mb-lg-3">
                  <div className="section-header-left">
                    <h4> Web Development </h4>
                  </div>
                  <div className="section-header-right">
                    <div className="display-as">
                      <a
                        href="course-card.html"
                        data-uk-tooltip="title: Course list; pos: top-right"
                      >
                        <i className="icon-feather-grid"></i>
                      </a>
                      <a
                        href="#"
                        className="active"
                        data-uk-tooltip="title: Course Grid; pos: top-right"
                      >
                        <i className="icon-feather-list"></i>
                      </a>
                    </div>

                    <select className="selectpicker ml-3">
                      <option value=""> Newest </option>
                      <option value="1">Popular</option>
                      <option value="2">Free</option>
                      <option value="3">Premium</option>
                    </select>
                  </div>
                </div>

                <div className="course-card course-card-list">
                  <div className="course-card-thumbnail">
                    <img src="../assets/images/course/1.png" />
                    <a
                      href="course-intro.html"
                      className="play-button-trigger"
                    ></a>
                  </div>
                  <div className="course-card-body">
                    <a href="course-intro.html">
                      <h4>Ultimate Web Designer And Developer Course </h4>
                    </a>
                    <p>
                      HTML is the building blocks of the web. It gives pages
                      structure and applies meaning to content. Take this course
                      to learn how it all works and create your own pages!
                    </p>
                    <div className="course-details-info">
                      <ul>
                        <li>
                          {" "}
                          <i className="icon-feather-sliders"></i> Advance level{" "}
                        </li>
                        <li>
                          {" "}
                          By <a href="user-profile-1.html">
                            Jonathan Madano{" "}
                          </a>{" "}
                        </li>
                        <li>
                          <div className="star-rating">
                            <span className="avg"> 4.8 </span>{" "}
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="course-card course-card-list">
                  <div className="course-card-thumbnail">
                    <img src="../assets/images/course/2.png" />
                    <a
                      href="course-intro.html"
                      className="play-button-trigger"
                    ></a>
                  </div>
                  <div className="course-card-body">
                    <a href="course-intro.html">
                      <h4> Learn Angular Fundamentals From The Beginning </h4>
                    </a>
                    <p>
                      Kick your Angular skills into action with this
                      comprehensive dive into Angular and getting started the
                      right way. Complete with best practices and architecture
                      practices.
                    </p>
                    <div className="course-details-info">
                      <ul>
                        <li>
                          {" "}
                          <i className="icon-feather-sliders"></i> Binginner
                          level{" "}
                        </li>
                        <li>
                          {" "}
                          By <a href="user-profile-1.html">
                            {" "}
                            Stella Johnson
                          </a>{" "}
                        </li>
                        <li>
                          <div className="star-rating">
                            <span className="avg"> 4.4 </span>{" "}
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star half"></span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="course-card course-card-list">
                  <div className="course-card-thumbnail">
                    <img src="../assets/images/course/3.png" />
                    <a
                      href="course-intro.html"
                      className="play-button-trigger"
                    ></a>
                  </div>
                  <div className="course-card-body">
                    <a href="course-intro.html">
                      <h4> The Complete JavaScript From Scratch To Experts</h4>
                    </a>
                    <p>
                      JavaScript is how you build interactivity on the web. Take
                      this course to learn how to work with the DOM, create
                      Objects, make AJAX calls, and much more!
                    </p>
                    <div className="course-details-info">
                      <ul>
                        <li>
                          {" "}
                          <i className="icon-feather-sliders"></i> Advance level{" "}
                        </li>
                        <li>
                          {" "}
                          By <a href="user-profile-1.html">
                            {" "}
                            Adrian Mohani{" "}
                          </a>{" "}
                        </li>
                        <li>
                          <div className="star-rating">
                            <span className="avg"> 4.9 </span>{" "}
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star half"></span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="course-card course-card-list">
                  <div className="course-card-thumbnail">
                    <img src="../assets/images/course/7.png" />
                    <a
                      href="course-intro.html"
                      className="play-button-trigger"
                    ></a>
                  </div>
                  <div className="course-card-body">
                    <a href="course-intro.html">
                      <h4> Bootstrap 4 From Scratch With 5 Real Projects </h4>
                    </a>
                    <p>
                      {" "}
                      Lorem ipsum dolor sit amet, consectetur adipisici elit,
                      sed eiusmod tempor incidunt ut labore et dolore magna
                      aliqua. enim ad minim veniam,
                    </p>
                    <div className="course-details-info">
                      <ul>
                        <li>
                          {" "}
                          <i className="icon-feather-sliders"></i> Binginner
                          level{" "}
                        </li>
                        <li>
                          {" "}
                          By <a href="user-profile-1.html">
                            Jonathan Madano{" "}
                          </a>{" "}
                        </li>
                        <li>
                          <div className="star-rating">
                            <span className="avg"> 4.4 </span>{" "}
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star half"></span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="course-card course-card-list">
                  <div className="course-card-thumbnail">
                    <img src="../assets/images/course/6.png" />
                    <a
                      href="course-intro.html"
                      className="play-button-trigger"
                    ></a>
                  </div>
                  <div className="course-card-body">
                    <a href="course-intro.html">
                      <h4> The Complete 2020 Web Development Bootcamp</h4>
                    </a>
                    <p>
                      {" "}
                      HTML is the building blocks of the web. It gives pages
                      structure and applies meaning to content. Take this course
                      to learn how it all works and create your own pages!
                    </p>
                    <div className="course-details-info">
                      <ul>
                        <li>
                          {" "}
                          <i className="icon-feather-sliders"></i> Binginner
                          level{" "}
                        </li>
                        <li>
                          {" "}
                          By <a href="#"> Stella Johnson </a>{" "}
                        </li>
                        <li>
                          <div className="star-rating">
                            <span className="avg"> 4.9 </span>{" "}
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star half"></span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <ul
                  className="uk-pagination my-5 uk-flex-center"
                  data-uk-margin
                >
                  <li>
                    <a href="#">
                      <span data-uk-pagination-previous></span>
                    </a>
                  </li>
                  <li className="uk-disabled">
                    <span>...</span>
                  </li>
                  <li>
                    <a href="#">4</a>
                  </li>
                  <li className="uk-active">
                    <span>7</span>
                  </li>
                  <li>
                    <a href="#">8</a>
                  </li>
                  <li>
                    <a href="#">10</a>
                  </li>
                  <li className="uk-disabled">
                    <span>...</span>
                  </li>
                  <li>
                    <a href="#">
                      <span data-uk-pagination-next></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseList;
