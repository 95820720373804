import React, { useState } from "react";
import { useHistory } from "react-router";
import useForm from "effects/useForm";
import requestRecoverPassword from "services/passwordRecovery/requestRecoverPassword";

const RecoverPasswordRequest = () => {
  let history = useHistory();
  const [invalidCredentialsError, setInvalidCredentialsError] = useState("");
  const [loading, setLoading] = useState(false);

  const { email, emailErrorMessage, setEmail, isValid } = useForm({
    email: {
      validation: (email) => {
        if (email === "") {
          return "Email is required";
        }
      },
      initialValue: "",
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    requestRecoverPassword({ requestedEmail: email })
      .then(() => {
        setLoading(false);
        history.push("/recover-password-request-sent");
      })
      .catch((err) => {
        setLoading(false);
        setInvalidCredentialsError("Please provide your registered Email here");
      });
  };

  return (
    <>
      <div data-uk-height-viewport className="uk-flex uk-flex-middle">
        <div className="uk-width-1-2@s m-auto rounded">
          <div className="uk-card-default p-5 rounded">
            <div className="mb-4 uk-text-center">
              <h3 className="mb-0"> Reset Password</h3>
              <p className="my-2">
                {" "}
                Enter your email and instructions will be sent to you!
              </p>
            </div>
            <form>
              <div className="uk-form-group">
                <label className="uk-form-label"> Email</label>

                <div className="uk-position-relative w-100">
                  <span className="uk-form-icon">
                    <i className="icon-feather-mail"></i>
                  </span>
                  <input
                    className="uk-input"
                    type="email"
                    placeholder="name@example.com"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                  />
                </div>
              </div>
              {emailErrorMessage && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    "margin-top": "-7px",
                  }}
                >
                  {emailErrorMessage}
                </p>
              )}
              {invalidCredentialsError && (
                <div className="login-error-wrapper">
                  <p className="text-danger">{invalidCredentialsError}</p>
                </div>
              )}
              <div
                className="mt-4 uk-flex-middle uk-grid-small"
                data-uk-grid
                style={{ justifyContent: "center" }}
              >
                <div className="uk-width-auto@s">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <button
                      type="submit"
                      className="btn btn-default"
                      disabled={!isValid || loading}
                      onClick={handleSubmit}
                    >
                      {loading ? "Sending Email.." : "Send Recovery Email"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoverPasswordRequest;
