import React, { useState } from "react";
import { Link } from "react-router-dom";
import useFetchData from "effects/useFetchData";
import getBlogByUser from "services/Blog/getBlogsByUser";
import EditorModal from "components/EditorModal";

const Blog = ({ nightMode }) => {
  const [isOpen, setOpen] = useState(false);
  const [loading, errored, blogs, refreshBlogs] = useFetchData(() =>
    getBlogByUser()
  );
  const [blogToEdit, setBlogToEdit] = useState({});

  if (errored) {
    return <div>Something went wrong. Please refresh the page.</div>;
  }
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {isOpen && (
        <EditorModal
          setOpen={setOpen}
          blog={blogToEdit}
          initialContent={blogToEdit.blogContent}
          refreshBlogs={refreshBlogs}
          nightMode={nightMode}
        />
      )}
      <div>
        <div className="section-small">
          <div className="uk-child-width-1-4@m uk-child-width-1-3@s">
            {[].length === 0 &&
              "Writing is a great way to consolidate what you have learned."}
            <div className="uk-width-auto">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  setBlogToEdit({});
                  setOpen(true);
                }}
              >
                Write a blog
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-child-width-1-2@ uk-child-width-1-3@m" data-uk-grid>
        {blogs.blogsByUser.map((blog) => {
          return (
            <>
              <div>
                <div className="blog-post blog-post-card">
                  <div className="blog-post-thumbnail">
                    <div className="blog-post-thumbnail-inner">
                      <a>
                        <Link to={`/blog/${blog.slug}`}>
                          <img
                            src={
                              blog.image || "../assets/images/blog/img-1.jpg"
                            }
                          />
                        </Link>
                      </a>
                    </div>
                  </div>
                  <div
                    className="blog-post-content"
                    style={{ minHeight: "150px", maxHeight: "150px" }}
                  >
                    <a>
                      <Link to={`/blog/${blog.slug}`}>
                        <h3>{blog.title}</h3>
                      </Link>
                    </a>

                    <a>
                      <Link to={`/blog/${blog.slug}`}>
                        <p>{blog.description}</p>
                      </Link>
                    </a>
                  </div>
                  <div>
                    <button
                      style={{ marginLeft: "20px", marginBottom: "10px" }}
                      type="button"
                      className="btn btn-success"
                      onClick={() => {
                        setBlogToEdit(blog);
                        setOpen(true);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                  <div className="blog-post-footer">
                    <div
                      className="blog-post-content-info"
                      style={{ justifyContent: "left" }}
                    >
                      {blog.tags
                        .filter((tag) => tag.tag !== null)
                        .map(({ tag }) => {
                          return (
                            <span
                              className="blog-post-info-tag btn btn-soft-primary"
                              style={{ margin: "3px" }}
                            >
                              {" "}
                              {tag}{" "}
                            </span>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default Blog;
