import React, { useState } from "react";
import getStudents from "services/getStudents";
import useFetchData from "effects/useFetchData";
import AdminSideBar from "components/AdminSideBar";
import Footer from "components/Footer";
import StudentCard from "./student-card";

const AdminStudents = ({ nightMode }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, errored, apiResponse] = useFetchData(() => getStudents());
  const { students } = apiResponse;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (errored) {
    return <div>Something went wrong</div>;
  }

  return (
    <>
      <AdminSideBar />
      <div className="page-content">
        <div className="page-content-inner">
          <div className="d-flex">
            <nav id="breadcrumbs" className="mb-3">
              <ul>
                <li>
                  <a href="#">
                    {" "}
                    <i className="uil-home-alt"></i>{" "}
                  </a>
                </li>
                <li>
                  <a href="#">Students </a>
                </li>
                <li>Students list</li>
              </ul>
            </nav>
          </div>

          <div className="card">
            <div className="card-header actions-toolbar border-0">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="d-inline-block mb-0 text-blue">Students</h4>

                <div className="d-flex">
                  <a
                    href="#"
                    className="btn btn-icon btn-hover  btn-circle"
                    data-uk-tooltip="Search Student"
                    style={{ marginRight: "5px" }}
                  >
                    <i className="uil-search"></i>
                  </a>
                  <div
                    className="uk-drop"
                    data-uk-drop="mode: click; pos: left-center; offset: 0"
                  >
                    <form className="uk-search uk-search-navbar uk-width-1-1">
                      <input
                        className="uk-search-input shadow-0 uk-form-small"
                        type="search"
                        placeholder="Search..."
                        onChange={(event) => {
                          setSearchTerm(event.target.value);
                        }}
                        autoFocus
                      />
                    </form>
                  </div>
                  <a
                    href="#"
                    className="btn btn-icon btn-hover  btn-circle"
                    data-uk-tooltip="filter"
                    style={{ marginRight: "5px" }}
                  >
                    <i className="uil-filter"></i>
                  </a>
                  <div data-uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
                    <ul className="uk-nav uk-dropdown-nav">
                      <li className="uk-active">
                        <a href="#"> Newest </a>
                      </li>
                      <li>
                        <a href="#">From A-Z</a>
                      </li>
                      <li>
                        <a href="#">From Z-A</a>
                      </li>
                    </ul>
                  </div>
                  <a
                    href="#"
                    className="btn btn-icon btn-hover  btn-circle"
                    data-uk-tooltip="More"
                  >
                    <i className="uil-ellipsis-h"></i>
                  </a>
                  <div data-uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
                    <ul className="uk-nav uk-dropdown-nav">
                      <li>
                        <a href="#">Refresh</a>
                      </li>
                      <li>
                        <a href="#">Manage</a>
                      </li>
                      <li>
                        <a href="#">Settings</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table align-items-center">
                <thead>
                  <tr>
                    <th scope="col">Full Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {students
                    .filter((student) => {
                      if (searchTerm === "") {
                        return student;
                      } else if (
                        student.firstName
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return student;
                      }
                    })
                    .map((student, idx) => {
                      return (
                        <StudentCard
                          student={student}
                          key={idx}
                          nightMode={nightMode}
                        />
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AdminStudents;
