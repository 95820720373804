import http from "utils/http";
import { students } from "constants/endpoints";

const getStudentsLastPages = ({ userId }) => {
  const ENDPOINT = students.getStudentsLastPages({ userId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${ENDPOINT}`);
};

export default getStudentsLastPages;
