import React from "react";
import PropTypes from "prop-types";

const YouTube = ({ id }) => (
  <div className="embed-container">
    <iframe
      title="YouTube Video"
      src={`https://www.youtube.com/embed/${id}`}
      frameBorder="0"
      allowFullScreen
    />
  </div>
);

YouTube.propTypes = {
  id: PropTypes.string.isRequired,
};

export default YouTube;
