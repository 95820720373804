import http from "utils/http";
import { admin } from "constants/endpoints";

const postInstructor = ({ userId, email }) => {
  const ENDPOINT = admin.postInstructor({ userId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${ENDPOINT}`, {
    data: {
      email,
    },
  });
};

export default postInstructor;
