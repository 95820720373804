import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useReducer } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import StudentDashboard from "./pages/StudentDashboard";
import AdminDashboard from "./pages/Admin/Dashboard";
import AdminCourses from "./pages/Admin/Courses";
import AdminAddCourse from "./pages/Admin/AddCourse";
import AdminManageBlogs from "./pages/Admin/ManageBlogs";
import AdminStudents from "./pages/Admin/Students";
import AdminInstructors from "pages/Admin/Instructors";
import AdminInstructorsListView from "pages/Admin/InstructorsListView";
import Module from "./pages/Module";
import Course from "./pages/Course";
import CourseList from "./pages/CourseList";
import CourseGrid from "./pages/CourseGrid";
import CoursePaymentFull from "./pages/CoursePaymentFull";
import SuccessPayment from "./pages/SuccessPayment";
import FailedPayment from "./pages/FailedPayment";
import LessonPage from "./pages/LessonPage";
import useLocalStorage from "effects/useLocalStorage";
import Blogs from "pages/Blogs";
import BlogPage from "pages/BlogPage";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsOfService from "pages/TermsOfService";
import AccountSettings from "pages/AccountSettings";
import AccountSettingsEdit from "pages/AccountSettingsEdit";
import AuthRoute from "components/AuthRoute";
import "./styles/feedback.css";
import RenderRouteIf from "components/RenderPath";
import userTypes from "constants/userTypes";
import RecoverPasswordRequestSent from "pages/PasswordRecoveryRequestSent";
import RecoverPasswordRequest from "pages/RecoverPasswordRequest";
import RecoverPassword from "pages/RecoverPassword";
import CoursePaymentModule from "pages/CoursePaymentModule";
import AdminPayments from "pages/Admin/Payments";
//import CoursePaymentModuleTest from "pages/CoursePaymentModuleTest";
import RegistrationCofirmation from "pages/RegistrationCofirmation";

export default function App() {
  const [nightMode, setNightMode] = useLocalStorage("gmtNightMode", "asda");
  const [updateCount, forceUpdate] = useReducer((x) => x + 1, 0);

  return (
    <Router>
      <div id="wrapper">
        <Header
          nightMode={nightMode}
          setNightMode={setNightMode}
          forceUpdate={forceUpdate}
          updateCount={updateCount}
        />
        <div className="page-content">
          <Switch>
            <Route path="/register">
              <SignUp />
            </Route>
            <Route path="/registration-confirmation">
              <RegistrationCofirmation />
            </Route>
            <Route path="/login">
              <Login forceUpdate={forceUpdate} updateCount={updateCount} />
            </Route>
            <Route path="/course/:cohortId">
              <Course />
            </Route>
            <Route path="/courses">
              <CourseGrid />
            </Route>
            <Route path="/courses-list">
              <CourseList />
            </Route>
            <Route path="/course-payment-full/:cohortId">
              <CoursePaymentFull />
            </Route>
            <Route path="/course-payment-module/:cohortId">
              <CoursePaymentModule />
            </Route>
            {/* <Route path="/course-payment-module/:cohortId">
              <CoursePaymentModuleTest />
            </Route> */}
            <Route path="/success-payment">
              <SuccessPayment />
            </Route>
            <Route path="/failed-payment">
              <FailedPayment />
            </Route>
            <AuthRoute path="/module/:moduleId/chapter/:chapterId/page/:pageId">
              <LessonPage />
            </AuthRoute>
            <AuthRoute path="/module/:moduleId">
              <Module />
            </AuthRoute>
            <Route path="/blog/:slug">
              <BlogPage />
            </Route>
            <Route path="/blogs">
              <Blogs />
            </Route>
            <Route path="/recover-password-request-sent">
              <RecoverPasswordRequestSent />
            </Route>
            <Route path="/recover-password-request">
              <RecoverPasswordRequest />
            </Route>
            <Route path="/recover-password/:shortcode">
              <RecoverPassword />
            </Route>
            <Route path="/privacy">
              <PrivacyPolicy />
            </Route>
            <AuthRoute path="/profile">
              <AccountSettings />
            </AuthRoute>
            <AuthRoute path="/profile-edit">
              <AccountSettingsEdit forceUpdate={forceUpdate} />
            </AuthRoute>
            <Route path="/terms">
              <TermsOfService />
            </Route>
            <AuthRoute role={userTypes.ADMIN} path="/admin-courses">
              <AdminCourses />
            </AuthRoute>
            <AuthRoute role={userTypes.ADMIN} path="/admin-manageblogs">
              <AdminManageBlogs />
            </AuthRoute>
            <AuthRoute role={userTypes.ADMIN} path="/admin-add-course">
              <AdminAddCourse />
            </AuthRoute>
            <AuthRoute role={userTypes.ADMIN} path="/admin-students">
              <AdminStudents nightMode={nightMode} />
            </AuthRoute>
            <AuthRoute role={userTypes.ADMIN} path="/admin-instructors">
              <AdminInstructors />
            </AuthRoute>
            <AuthRoute role={userTypes.ADMIN} path="/admin-instructors-list">
              <AdminInstructorsListView />
            </AuthRoute>
            <AuthRoute role={userTypes.ADMIN} path="/payments">
              <AdminPayments />
            </AuthRoute>
            <AuthRoute role={userTypes.ADMIN} path="/admin">
              <AdminDashboard />
            </AuthRoute>
            <AuthRoute path="/">
              <StudentDashboard nightMode={nightMode} />
            </AuthRoute>
          </Switch>
        </div>
        <RenderRouteIf
          notOnRoutes={[
            /admin*/,
            "/profile",
            "/profile-edit",
            "/terms",
            "/privacy",
            "/payments",
          ]}
        >
          <Footer />
        </RenderRouteIf>
      </div>
    </Router>
  );
}
