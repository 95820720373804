import http from "utils/http";
import { user } from "constants/endpoints";

const getStudents = () => {
  const GET_USER_MODULES_ENDPOINT = user.getStudents();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${GET_USER_MODULES_ENDPOINT}`);
};

export default getStudents;
