import React, { useState, useEffect } from "react";
import AdminSideBar from "components/AdminSideBar";
import Footer from "components/Footer";
import useFetchData from "effects/useFetchData";
import getPayments from "services/getPayments";
import PaymentRow from "./PaymentRow";

const AdminPayments = () => {
  const [loading, errored, apiResponse] = useFetchData(getPayments);
  const { payments } = apiResponse;

  const [searchPayment, setSearchPayment] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    getPayments()
      .then((payments) => {
        setTotalPages(payments.totalPages);
      })
      .catch((error) => console.log(error));
  }, [currentPage]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (errored) {
    return <div>Something went wrong</div>;
  }

  return (
    <>
      <AdminSideBar />
      <div className="page-content">
        <div className="page-content-inner">
          <div className="card">
            <div
              className="card-header actions-toolbar border-0"
              style={{ padding: "12px" }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="d-inline-block mb-0 text-blue">Payments</h4>

                <div className="d-flex">
                  <a
                    href="#"
                    className="btn btn-icon btn-hover btn-circle"
                    data-uk-tooltip="Search Payment"
                    style={{ marginRight: "5px" }}
                  >
                    <i className="uil-search"></i>
                  </a>
                  <div
                    className="uk-drop"
                    data-uk-drop="mode: click; pos: left-center; offset: 0"
                  >
                    <form className="uk-search uk-search-navbar uk-width-1-1">
                      <input
                        className="uk-search-input shadow-0 uk-form-small"
                        type="search"
                        placeholder="Search..."
                        onChange={(event) => {
                          setSearchPayment(event.target.value);
                        }}
                        autoFocus
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table align-items-center">
                <thead>
                  <tr>
                    <th scope="col">Full Name</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Payment Method</th>
                    <th scope="col">Transaction Date-Time</th>
                  </tr>
                </thead>
                <tbody>
                  {payments
                    // eslint-disable-next-line array-callback-return
                    .filter((payment) => {
                      if (searchPayment === "") {
                        return payment;
                      } else if (
                        payment.firstName
                          .toLowerCase()
                          .includes(searchPayment.toLowerCase()) ||
                        payment.transactionDate.includes(searchPayment) ||
                        payment.paymentMethod
                          .toLowerCase()
                          .includes(searchPayment.toLowerCase())
                      ) {
                        return payment;
                      }
                    })
                    .map((payment, index) => (
                      <PaymentRow payment={payment} index={index + 1} />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ul className="uk-pagination my-5 uk-flex-center" data-uk-margin>
          <li className={currentPage === 1 ? "uk-disabled" : ""}>
            <a href="#" onClick={() => handlePageChange(currentPage - 1)}>
              <span data-uk-pagination-previous></span>
            </a>
          </li>

          {[...Array(totalPages)].map((_, index) => (
            <li
              className={currentPage === index + 1 ? "uk-active" : ""}
              key={index}
            >
              <span onClick={() => handlePageChange(index + 1)}>
                {index + 1}
              </span>
            </li>
          ))}

          <li className={currentPage === totalPages ? "uk-disabled" : ""}>
            <a href="#" onClick={() => handlePageChange(currentPage + 1)}>
              <span data-uk-pagination-next></span>
            </a>
          </li>
        </ul>
        <Footer />
      </div>
    </>
  );
};

export default AdminPayments;
