import React from "react";
import Chapter from "./components/Chapter";
import getModule from "services/getModule";
import getResumeModule from "services/getResumeModule";
import useFetchData from "effects/useFetchData";
import flattenDeep from "utils/flattenDeep";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import Authentication from "services/Authentication";
import PaymentPopup from "../../components/PaymentPopupWindow";
import getPaymentStatus from "services/payment/getPaymentStatus";
import getStudentActivity from "services/getStudentActivity";
import getUserModules from "services/getUserModules";

let successCount = 0;
const fixSticky = () => {
  const sticky = document.getElementById("sticky-continue");
  if (!sticky?.attributes?.style) {
    setTimeout(() => {
      fixSticky();
    }, 50);
    return;
  }
  const style = sticky.attributes.style.value;
  if (style === "") {
    // eslint-disable-next-line no-undef
    UIkit.update();
    setTimeout(() => {
      fixSticky();
    }, 50);
    successCount = 0;
  } else {
    successCount++;
    if (successCount < 5) {
      setTimeout(() => {
        fixSticky();
      }, 100);
    }
  }
};
const auth = new Authentication();
const jwtPayload = auth.getAccessTokenPayload();

const Module = () => {
  let { moduleId } = useParams();
  let history = useHistory();
  const [
    isLoadingActivity,
    errorFetchingActivity,
    activityResponse,
    refreshActivity,
  ] = useFetchData(() => getStudentActivity({ userId: jwtPayload.userId }));

  const [loading, errored, apiResponse] = useFetchData(() => getUserModules());
  const [getModuleLoading, getModuleError, getModuleResponse] = useFetchData(
    () => getModule({ moduleId })
  );
  const [resumeLoading, resumeError, resumeResponse] = useFetchData(() =>
    getResumeModule({ moduleId })
  );
  const [paymentLoading, paymentErrored, paymentApiResponse] = useFetchData(
    () => getPaymentStatus({ userId: jwtPayload.userId })
  );

  setTimeout(() => {
    fixSticky();
  }, 100);

  if (getModuleLoading || resumeLoading) {
    return <div>Loading...</div>;
  }

  if (getModuleError || resumeError) {
    return <div>Something went wrong</div>;
  }

  const { pageId: pageIdToContinue } = resumeResponse;
  const { courseModule } = getModuleResponse;
  const allPages = flattenDeep(
    courseModule.chapters.map((chapter) =>
      chapter.pages.map((page) => {
        return { ...page, chapterId: chapter.chapterId, chapter };
      })
    )
  );
  const pageToContinue = allPages.find(
    (p) => Number(p.pageId) === Number(pageIdToContinue)
  );

  const { hasPaid } = paymentApiResponse;
  if (loading || isLoadingActivity || paymentLoading) {
    return <div>Loading...</div>;
  }

  if (errored || errorFetchingActivity || paymentErrored) {
    return <div>Something went wrong</div>;
  }

  return (
    <>
      <div className="page-content">
        <div className="course-details-wrapper topic-1 uk-light">
          <div className="container p-sm-0">
            <div data-uk-grid>
              <div className="uk-width-2-3@m">
                <div className="course-details">
                  <h1>{courseModule.moduleTitle}</h1>
                  <p>{courseModule.moduleDescription}</p>
                </div>
                <nav className="responsive-tab style-5">
                  <ul data-uk-switcher="connect: #course-intro-tab ;animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium">
                    <li>
                      <a href="#">Chapters</a>
                    </li>
                    <li>{/*<a href="#">Overview</a>*/}</li>
                    <li>{/*<a href="#">FAQ</a>*/}</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="uk-grid-large mt-4" data-uk-grid>
            <div className="uk-width-2-3@m">
              <ul id="course-intro-tab" className="uk-switcher mt-4">
                {!hasPaid && <PaymentPopup />}
                <li>
                  <ul
                    className="course-curriculum"
                    data-uk-accordion="multiple: true"
                  >
                    {courseModule.chapters.map((chapter) => {
                      return (
                        <Chapter
                          chapter={chapter}
                          pageToContinue={pageToContinue}
                          moduleId={moduleId}
                          allPages={allPages}
                        />
                      );
                    })}
                  </ul>
                </li>

                <li className="course-description-content">
                  <h4> Description </h4>
                  <p>
                    {" "}
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat. Ut wisi enim ad minim laoreet
                    dolore magna aliquam erat volutpat. Ut wisi enim ad minim
                    veniam, quis nostrud exerci tation ullamcorper suscipit
                    lobortis nisl ut aliquip ex ea commodo consequat
                  </p>
                  <p>
                    {" "}
                    consectetuer adipiscing elit, sed diam nonummy nibh euismod
                    tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                    wisi enim ad minim veniam, quis nostrud exerci
                  </p>

                  <h4> What you’ll learn </h4>
                  <div className="uk-child-width-1-2@s" data-uk-grid>
                    <div>
                      <ul className="list-2">
                        <li>Setting up the environment </li>
                        <li>Advanced HTML Practices</li>
                        <li>Build a portfolio website</li>
                        <li>Responsive Designs</li>
                      </ul>
                    </div>
                    <div>
                      <ul className="list-2">
                        <li>Understand HTML Programming</li>
                        <li>Code HTML</li>
                        <li>Start building beautiful websites</li>
                      </ul>
                    </div>
                  </div>

                  <h4> Requirements </h4>
                  <ul className="list-1">
                    <li>Any computer will work: Windows, macOS or Linux</li>
                    <li>Basic programming HTML and CSS.</li>
                    <li>Basic/Minimal understanding of JavaScript</li>
                  </ul>

                  <h4> Here is exactly what we cover in this course: </h4>
                  <p>
                    {" "}
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat. Ut wisi enim ad minim laoreet
                    dolore magna aliquam erat volutpat. Ut wisi enim ad minim
                    veniam, quis nostrud exerci tation ullamcorper suscipit
                    lobortis nisl ut aliquip ex ea commodo consequat
                  </p>

                  <p>
                    {" "}
                    consectetuer adipiscing elit, sed diam nonummy nibh euismod
                    tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                    wisi enim ad minim veniam, quis nostrud exerci
                  </p>

                  <p>
                    {" "}
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                    quis nostrud exerci tation ullamcorper suscipit lobortis
                    nisl ut aliquip ex ea commodo consequat. Nam liber tempor
                    cum soluta nobis eleifend option congue nihil imperdiet
                    doming id quod mazim placerat facer possim assum. Lorem
                    ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                    nonummy nibh euismod tincidunt ut laoreet dolore magna
                    aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                    nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                    aliquip ex ea commodo consequat.
                  </p>
                </li>

                <li>
                  <h4 className="my-4"> Course Faq</h4>

                  <ul className="course-faq" data-uk-accordion>
                    <li className="uk-open">
                      <a className="uk-accordion-title" href="#">
                        {" "}
                        Html Introduction{" "}
                      </a>
                      <div className="uk-accordion-content">
                        <p>
                          {" "}
                          The primary goal of this quick start guide is to
                          introduce you to Unreal Engine 4`s (UE4) development
                          environment. By the end of this guide, you`ll know how
                          to set up and develop C++ Projects in UE4. This guide
                          shows you how to create a new Unreal Engine project,
                          add a new C++ class to it, compile the project, and
                          add an instance of a new class to your level. By the
                          time you reach the end of this guide, you`ll be able
                          to see your programmed Actor floating above a table in
                          the level.{" "}
                        </p>
                      </div>
                    </li>

                    <li>
                      <a className="uk-accordion-title" href="#">
                        {" "}
                        Your First webpage
                      </a>
                      <div className="uk-accordion-content">
                        <p>
                          {" "}
                          The primary goal of this quick start guide is to
                          introduce you to Unreal Engine 4`s (UE4) development
                          environment. By the end of this guide, you`ll know how
                          to set up and develop C++ Projects in UE4. This guide
                          shows you how to create a new Unreal Engine project,
                          add a new C++ class to it, compile the project, and
                          add an instance of a new class to your level. By the
                          time you reach the end of this guide, you`ll be able
                          to see your programmed Actor floating above a table in
                          the level.{" "}
                        </p>
                      </div>
                    </li>

                    <li>
                      <a className="uk-accordion-title" href="#">
                        {" "}
                        Some Special Tags{" "}
                      </a>
                      <div className="uk-accordion-content">
                        <p>
                          {" "}
                          The primary goal of this quick start guide is to
                          introduce you to Unreal Engine 4`s (UE4) development
                          environment. By the end of this guide, you`ll know how
                          to set up and develop C++ Projects in UE4. This guide
                          shows you how to create a new Unreal Engine project,
                          add a new C++ class to it, compile the project, and
                          add an instance of a new class to your level. By the
                          time you reach the end of this guide, you`ll be able
                          to see your programmed Actor floating above a table in
                          the level.{" "}
                        </p>
                      </div>
                    </li>

                    <li>
                      <a className="uk-accordion-title" href="#">
                        {" "}
                        Html Introduction{" "}
                      </a>
                      <div className="uk-accordion-content">
                        <p>
                          {" "}
                          The primary goal of this quick start guide is to
                          introduce you to Unreal Engine 4`s (UE4) development
                          environment. By the end of this guide, you`ll know how
                          to set up and develop C++ Projects in UE4. This guide
                          shows you how to create a new Unreal Engine project,
                          add a new C++ class to it, compile the project, and
                          add an instance of a new class to your level. By the
                          time you reach the end of this guide, you`ll be able
                          to see your programmed Actor floating above a table in
                          the level.{" "}
                        </p>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="uk-width-1-3@m">
              <div
                className="course-card-trailer"
                data-uk-sticky="offset: 100px;"
                id="sticky-continue"
              >
                <div className="p-3">
                  <p className="uk-text-bold">{pageToContinue?.title}</p>
                  <p>
                    {courseModule.moduleTitle} ({courseModule.moduleProgress}%)
                  </p>
                  <div
                    className="uk-child-width-1 uk-grid-small mb-4"
                    data-uk-grid
                  >
                    <div>
                      <a
                        href=""
                        className="uk-width-1-1 btn btn-default transition-3d-hover"
                        onClick={() => {
                          const chapterId = allPages.find(
                            (p) => p.pageId === pageToContinue?.pageId
                          ).chapterId;
                          history.push(
                            `/module/${moduleId}/chapter/${chapterId}/page/${pageToContinue?.pageId}`
                          );
                        }}
                      >
                        <i className="uil-play"></i> Continue{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Module;
