import React from "react";
import getUserModules from "services/getUserModules";
import useFetchData from "effects/useFetchData";
import AdminSideBar from "components/AdminSideBar";
import Authentication from "services/Authentication";
import RenderIfRole from "components/RenderIfRole";
import userTypes from "constants/userTypes";
import Footer from "components/Footer";
import getStudentCount from "services/getStudentCount";

const auth = new Authentication();

const AdminDashboard = () => {
  const [loading, errored, apiResponse] = useFetchData(() => getUserModules());
  const [
    loadingStudentCount,
    erroredStudentCount,
    apiResponseStudentCount,
  ] = useFetchData(() => getStudentCount());
  const { studentModules } = apiResponse;
  if (loading) {
    return <div>Loading...</div>;
  }

  if (errored) {
    return <div>Something went wrong</div>;
  }
  const { studentCount } = apiResponseStudentCount;

  if (loadingStudentCount) {
    return <div>Loading...</div>;
  }

  if (erroredStudentCount) {
    return <div>Something went wrong</div>;
  }

  const jwtPayload = auth.getAccessTokenPayload();
  return (
    <>
      <AdminSideBar />
      <div className="page-content">
        <div className="page-content-inner pt-lg-0  pr-lg-0">
          <div className="ro" data-uk-grid>
            <div className="uk-width-expand@m">
              <div className="section-small">
                <RenderIfRole
                  role={userTypes.ADMIN}
                  fallback={
                    <div>You are not authorized to view this page.</div>
                  }
                >
                  <h3> Welcome {jwtPayload.firstName} </h3>
                </RenderIfRole>

                <div
                  className="uk-position-relative uk-visible-toggle"
                  tabIndex="-1"
                  data-uk-slider="finite: true"
                >
                  <ul className="uk-slider-items uk-child-width-1-2@m uk-child-width-1-2@s uk-grid">
                    <li>
                      <div className="card">
                        <div className="card-body">
                          <div className="uk-flex-middle" data-uk-grid>
                            <div className="uk-width-auto">
                              <h5 className="mb-2"> Total sales </h5>
                              <h1> 220.9$</h1>
                              <span className="badge badge-soft-success mt-n1">
                                {" "}
                                +12.9$
                              </span>
                            </div>
                            <div className="uk-width-expand">
                              <img
                                src="../assets/images/demos/d-sales.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>

                        <div className="card-footer d-flex justify-content-between py-2">
                          <p className="mb-0"> 124 total sales </p>
                          <a href="#" className=" ">
                            {" "}
                            Veiw report
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="card">
                        <div className="card-body">
                          <div className="uk-flex-middle" data-uk-grid>
                            <div className="uk-width-auto">
                              <h5 className="mb-2"> Total Students </h5>
                              <h1>{studentCount}</h1>
                            </div>
                            <div className="uk-width-expand">
                              <img
                                src="../assets/images/demos/d-student.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="card">
                        <div className="card-body">
                          <div className="uk-flex-middle" data-uk-grid>
                            <div className="uk-width-auto">
                              <h5 className="mb-2"> Total Courses </h5>
                              <h1> 190</h1>
                              <span className="badge badge-soft-success mt-n1">
                                {" "}
                                +16
                              </span>
                            </div>
                            <div className="uk-width-expand">
                              <img
                                src="../assets/images/demos/d-graduation.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>

                        <div className="card-footer d-flex justify-content-between py-2">
                          <p className="mb-0"> 230 total Courses </p>
                          <a href="#" className=" ">
                            {" "}
                            Veiw report
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <ul className="uk-slider-nav uk-dotnav uk-flex-center mt-3"></ul>
                </div>

                <div className="uk-child-width-1-2@m" data-uk-grid>
                  <div></div>

                  <div></div>
                </div>
              </div>

              <div className="uk-card-default uk-card-small rounded">
                <div className="uk-card-header py-3">
                  <span className="uk-h5"> Recent Courses</span>
                  <a href="courses.html" className="uk-float-right">
                    {" "}
                    See all{" "}
                  </a>
                </div>

                <div className="uk-height-large" data-simplebar>
                  <div className="uk-grid-small p-4" data-uk-grid>
                    <div className="uk-width-1-3@m">
                      <img
                        src="../assets/images/course/2.png"
                        className="  rounded"
                        alt=""
                      />
                    </div>
                    <div className="uk-width-expand">
                      <h5 className="mb-2"> Learn Anglular Fonduments </h5>
                      <p className="uk-text-small mb-2">
                        {" "}
                        Created by <a href="#"> Jonathan Madano </a>
                      </p>
                      <p className="mb-0 uk-text-small mt-3">
                        <span className="mr-3 bg-light p-2 mt-1">
                          {" "}
                          16 Enerolled
                        </span>
                        <span> Last updated 10/2019 </span>
                      </p>
                    </div>
                  </div>

                  <hr className="m-0"></hr>

                  <div className="uk-grid-small p-4" data-uk-grid>
                    <div className="uk-width-1-3@m">
                      <img
                        src="../assets/images/course/3.png"
                        className="  rounded"
                        alt=""
                      />
                    </div>
                    <div className="uk-width-expand">
                      <h5 className="mb-2"> The Complete JavaScript </h5>
                      <p className="uk-text-small mb-2">
                        {" "}
                        Created by <a href="#"> Jonathan Madano </a>
                      </p>
                      <p className="mb-0 uk-text-small mt-3">
                        <span className="mr-3 bg-light p-2 mt-1">
                          {" "}
                          16 Enerolled
                        </span>
                        <span> Last updated 10/2019 </span>
                      </p>
                    </div>
                  </div>

                  <hr className="m-0"></hr>

                  <div className="uk-grid-small p-4" data-uk-grid>
                    <div className="uk-width-1-3@m">
                      <img
                        src="../assets/images/course/1.png"
                        className="  rounded"
                        alt=""
                      />
                    </div>
                    <div className="uk-width-expand">
                      <h5 className="mb-2"> Ultimate Web Developer Course </h5>
                      <p className="uk-text-small mb-2">
                        {" "}
                        Created by <a href="#"> Jonathan Madano </a>
                      </p>
                      <p className="mb-0 uk-text-small mt-3">
                        <span className="mr-3 bg-light p-2 mt-1">
                          {" "}
                          16 Enerolled
                        </span>
                        <span> Last updated 10/2019 </span>
                      </p>
                    </div>
                  </div>

                  <hr className="m-0" />

                  <div className="uk-grid-small p-4" data-uk-grid>
                    <div className="uk-width-1-3@m">
                      <img
                        src="../assets/images/course/2.png"
                        className="  rounded"
                        alt=""
                      />
                    </div>
                    <div className="uk-width-expand">
                      <h5 className="mb-2"> Learn Anglular Fonduments </h5>
                      <p className="uk-text-small mb-2">
                        {" "}
                        Created by <a href="#"> Jonathan Madano </a>
                      </p>
                      <p className="mb-0 uk-text-small mt-3">
                        <span className="mr-3 bg-light p-2 mt-1">
                          {" "}
                          16 Enerolled
                        </span>
                        <span> Last updated 10/2019 </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Popular Instructers --> */}
              <div className="section-small">
                <div
                  className="uk-position-relative uk-visible-toggle "
                  tabIndex="-1"
                  data-uk-slider="finite: true"
                >
                  <div className="grid-slider-header">
                    <h4> Popular Instructers </h4>
                    <div className="grid-slider-header-link">
                      <a
                        href="browse-channals.html"
                        className="btn uk-visible@m"
                      >
                        {" "}
                        View all{" "}
                      </a>
                      <a
                        href="#"
                        className="slide-nav-prev"
                        data-uk-slider-item="previous"
                      ></a>
                      <a
                        href="#"
                        className="slide-nav-next"
                        data-uk-slider-item="next"
                      ></a>
                    </div>
                  </div>

                  <ul className="uk-slider-items uk-child-width-1-3@m uk-child-width-1-2@s pb-3 uk-grid">
                    <li>
                      <div className="card">
                        <div className="card-body text-center">
                          <div className="avatar-parent-child">
                            <img
                              alt="Image placeholder"
                              src="../assets/images/avatars/avatar-2.jpg"
                              className="avatar  rounded-circle avatar-lg"
                            />
                            <span className="avatar-child avatar-badge bg-success"></span>
                          </div>
                          <h5 className="h6 mt-4 mb-0"> Stella Johnson </h5>
                          <a
                            href="#"
                            className="d-block text-sm text-muted mb-3"
                          >
                            @john.sullivan
                          </a>

                          <div className="d-flex justify-content-between px-4">
                            <a
                              href="#"
                              className="btn btn-icon btn-hover btn-circle"
                              data-uk-tooltip="Edit"
                            >
                              <i className="uil-edit-alt"></i>{" "}
                            </a>
                            <a
                              href="#"
                              className="btn btn-icon btn-hover btn-circle"
                              data-uk-tooltip="Send Message"
                            >
                              <i className="uil-envelope"></i>
                            </a>
                            <a
                              href="#"
                              className="btn btn-icon btn-hover btn-circle"
                              data-uk-tooltip="Delete"
                            >
                              <i className="uil-trash-alt"></i>{" "}
                            </a>
                          </div>
                        </div>
                        <div className="card-footer text-center py-2">
                          <a href="#" className="text-muted uk-text-small">
                            {" "}
                            13 Courses{" "}
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="card">
                        <div className="card-body text-center">
                          <div className="avatar-parent-child">
                            <img
                              alt="Image placeholder"
                              src="../assets/images/avatars/avatar-1.jpg"
                              className="avatar  rounded-circle avatar-lg"
                            />
                            <span className="avatar-child avatar-badge bg-success"></span>
                          </div>
                          <h5 className="h6 mt-4 mb-0"> Alex Dolgove</h5>
                          <a
                            href="#"
                            className="d-block text-sm text-muted mb-3"
                          >
                            @Alex.Dolgove
                          </a>

                          <div className="d-flex justify-content-between px-4">
                            <a
                              href="#"
                              className="btn btn-icon btn-hover btn-circle"
                              data-uk-tooltip="Edit"
                            >
                              <i className="uil-edit-alt"></i>{" "}
                            </a>
                            <a
                              href="#"
                              className="btn btn-icon btn-hover btn-circle"
                              data-uk-tooltip="Send Message"
                            >
                              <i className="uil-envelope"></i>
                            </a>
                            <a
                              href="#"
                              className="btn btn-icon btn-hover btn-circle"
                              data-uk-tooltip="Delete"
                            >
                              <i className="uil-trash-alt"></i>{" "}
                            </a>
                          </div>
                        </div>
                        <div className="card-footer text-center py-2">
                          <a href="#" className="text-muted uk-text-small">
                            {" "}
                            21 Courses{" "}
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="card">
                        <div className="card-body text-center">
                          <div className="avatar-parent-child">
                            <img
                              alt="Image placeholder"
                              src="../assets/images/avatars/avatar-3.jpg"
                              className="avatar  rounded-circle avatar-lg"
                            />
                            <span className="avatar-child avatar-badge bg-success"></span>
                          </div>
                          <h5 className="h6 mt-4 mb-0">Adrian Mohani</h5>
                          <a
                            href="#"
                            className="d-block text-sm text-muted mb-3"
                          >
                            @Adrian.Mohani
                          </a>

                          <div className="d-flex justify-content-between px-4">
                            <a
                              href="#"
                              className="btn btn-icon btn-hover btn-circle"
                              data-uk-tooltip="Edit"
                            >
                              <i className="uil-edit-alt"></i>{" "}
                            </a>
                            <a
                              href="#"
                              className="btn btn-icon btn-hover btn-circle"
                              data-uk-tooltip="Send Message"
                            >
                              <i className="uil-envelope"></i>
                            </a>
                            <a
                              href="#"
                              className="btn btn-icon btn-hover btn-circle"
                              data-uk-tooltip="Delete"
                            >
                              <i className="uil-trash-alt"></i>{" "}
                            </a>
                          </div>
                        </div>
                        <div className="card-footer text-center py-2">
                          <a href="#" className="text-muted uk-text-small">
                            {" "}
                            25 Courses{" "}
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="card">
                        <div className="card-body text-center">
                          <div className="avatar-parent-child">
                            <img
                              alt="Image placeholder"
                              src="../assets/images/avatars/avatar-4.jpg"
                              className="avatar  rounded-circle avatar-lg"
                            />
                            <span className="avatar-child avatar-badge bg-success"></span>
                          </div>
                          <h5 className="h6 mt-4 mb-0"> Stella Johnson</h5>
                          <a
                            href="#"
                            className="d-block text-sm text-muted mb-3"
                          >
                            @john.Johnson
                          </a>

                          <div className="d-flex justify-content-between px-4">
                            <a
                              href="#"
                              className="btn btn-icon btn-hover btn-circle"
                              data-uk-tooltip="Edit"
                            >
                              <i className="uil-edit-alt"></i>{" "}
                            </a>
                            <a
                              href="#"
                              className="btn btn-icon btn-hover btn-circle"
                              data-uk-tooltip="Send Message"
                            >
                              <i className="uil-envelope"></i>
                            </a>
                            <a
                              href="#"
                              className="btn btn-icon btn-hover btn-circle"
                              data-uk-tooltip="Delete"
                            >
                              <i className="uil-trash-alt"></i>{" "}
                            </a>
                          </div>
                        </div>
                        <div className="card-footer text-center py-2">
                          <a href="#" className="text-muted uk-text-small">
                            {" "}
                            8 Courses{" "}
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="card">
                        <div className="card-body text-center">
                          <div className="avatar-parent-child">
                            <img
                              alt="Image placeholder"
                              src="../assets/images/avatars/avatar-3.jpg"
                              className="avatar  rounded-circle avatar-lg"
                            />
                            <span className="avatar-child avatar-badge bg-success"></span>
                          </div>
                          <h5 className="h6 mt-4 mb-0"> Richard Ali </h5>
                          <a
                            href="#"
                            className="d-block text-sm text-muted mb-3"
                          >
                            @Richard.Ali
                          </a>

                          <div className="d-flex justify-content-between px-4">
                            <a
                              href="#"
                              className="btn btn-icon btn-hover btn-circle"
                              data-uk-tooltip="Edit"
                            >
                              <i className="uil-edit-alt"></i>{" "}
                            </a>
                            <a
                              href="#"
                              className="btn btn-icon btn-hover btn-circle"
                              data-uk-tooltip="Send Message"
                            >
                              <i className="uil-envelope"></i>
                            </a>
                            <a
                              href="#"
                              className="btn btn-icon btn-hover btn-circle"
                              data-uk-tooltip="Delete"
                            >
                              <i className="uil-trash-alt"></i>{" "}
                            </a>
                          </div>
                        </div>
                        <div className="card-footer text-center py-2">
                          <a href="#" className="text-muted uk-text-small">
                            {" "}
                            13 Courses{" "}
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="uk-width-1-3@m">
              <div
                className="bg-white uk-card-default uk-height-1-1"
                data-simplebar
                data-uk-sticky="offset:70; bottom:true"
              >
                <div className="p-3 row align-items-center">
                  <div className="col-auto">
                    <p className="mb-0"> View data on </p>
                  </div>
                  <div className="col">
                    <select className="selectpicker">
                      <option value=""> Today </option>
                      <option value="1"> This week </option>
                      <option value="2"> Last week</option>
                    </select>
                  </div>
                </div>

                <hr className="m-0" />

                <div className="p-3">
                  <h5> Total Sales</h5>
                  <h2> 830.98$</h2>
                  <canvas id="cjs-linechart"></canvas>
                </div>

                <hr className="m-0" />

                <div className="p-3">
                  <h5> Latest Enrolled</h5>

                  <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                    <div className="uk-width-auto">
                      {" "}
                      <img
                        className="rounded border border-200"
                        src="../assets/images/course/2.png"
                        width="60"
                        alt=""
                      />{" "}
                    </div>
                    <div className="uk-width-expand">
                      <p className="uk-text-truncate mb-0">
                        {" "}
                        Javascript for Begginers
                      </p>
                    </div>
                    <div className="uk-width-auto">
                      {" "}
                      <span className="badge badge-soft-success mt-n1">
                        +12.9$
                      </span>
                    </div>
                  </div>

                  <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                    <div className="uk-width-auto">
                      {" "}
                      <img
                        className="rounded border border-200"
                        src="../assets/images/course/2.png"
                        width="60"
                        alt=""
                      />{" "}
                    </div>
                    <div className="uk-width-expand">
                      <p className="uk-text-truncate mb-0">
                        {" "}
                        Javascript for Begginers
                      </p>
                    </div>
                    <div className="uk-width-auto">
                      {" "}
                      <span className="badge badge-soft-success mt-n1">
                        +12.9$
                      </span>
                    </div>
                  </div>

                  <div className="uk-grid-small  uk-flex-middle" data-uk-grid>
                    <div className="uk-width-auto">
                      {" "}
                      <img
                        className="rounded border border-200"
                        src="../assets/images/course/3.png"
                        width="60"
                        alt=""
                      />{" "}
                    </div>
                    <div className="uk-width-expand">
                      <p className="uk-text-small uk-text-truncate mb-0">
                        {" "}
                        Learn Bootstrap framework
                      </p>
                    </div>
                    <div className="uk-width-auto">
                      {" "}
                      <span className="badge badge-soft-success mt-n1">
                        +10.9$
                      </span>
                    </div>
                  </div>

                  <a href="#">
                    <p className="uk-heading-line uk-text-center mt-2 uk-text-small">
                      <span> Veiw more</span>
                    </p>
                  </a>

                  <h5> Top Sales</h5>

                  <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                    <div className="uk-width-auto">
                      {" "}
                      <img
                        className="rounded border border-200"
                        src="../assets/images/course/2.png"
                        width="80"
                        alt=""
                      />{" "}
                    </div>
                    <div className="uk-width-expand">
                      <p className="uk-text-truncate mb-0">
                        {" "}
                        Javascript for Begginers
                      </p>
                      <span className="badge badge-soft-secondary mt-n1">
                        {" "}
                        5 enrolled
                      </span>
                    </div>
                  </div>

                  <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                    <div className="uk-width-auto">
                      {" "}
                      <img
                        className="rounded border border-200"
                        src="../assets/images/course/2.png"
                        width="80"
                        alt=""
                      />{" "}
                    </div>
                    <div className="uk-width-expand">
                      <p className="uk-text-truncate mb-0">
                        {" "}
                        Javascript for Begginers
                      </p>
                      <span className="badge badge-soft-secondary mt-n1">
                        {" "}
                        5 enrolled
                      </span>
                    </div>
                  </div>

                  <div className="uk-grid-small  uk-flex-middle" data-uk-grid>
                    <div className="uk-width-auto">
                      {" "}
                      <img
                        className="rounded border border-200"
                        src="../assets/images/course/3.png"
                        width="60"
                        alt=""
                      />{" "}
                    </div>
                    <div className="uk-width-expand">
                      <p className="uk-text-small uk-text-truncate mb-0">
                        {" "}
                        Learn Bootstrap framework
                      </p>
                    </div>
                    <div className="uk-width-auto">
                      {" "}
                      <span className="badge badge-soft-secondary mt-n1">
                        {" "}
                        1 enrolled
                      </span>
                    </div>
                  </div>

                  <a href="#">
                    <p className="uk-heading-line uk-text-center mt-2 uk-text-small">
                      <span> Veiw more</span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AdminDashboard;
