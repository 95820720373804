import React from "react";

const SuccessPayment = () => {
  return (
    <div class="page-content">
      <div class="bg-pricing"></div>

      <div class="page-content-inner">
        <div class="pricing pricing-container">
          <div class="uk-light text-center">
            <h1 style={{ color: "#3e416d" }}> Payment Status</h1>
          </div>

          <div class="pricing-card">
            <div class="text-center">
              <div>
                <h1 class="price ">
                  <i
                    className="icon-feather-check"
                    style={{
                      backgroundColor: "#28a745",
                      color: "#fff",
                      padding: "10px",
                      borderRadius: "50%",
                    }}
                  ></i>
                </h1>
              </div>
              <h4
                class="plan-name"
                style={{ marginTop: "25px", marginBottom: "10px" }}
              >
                Payment Success
              </h4>
              <a href="/" className="btn btn-success  btn-small mt-3 mr-4">
                Go to Dashboard
              </a>
              <a href="/courses" className="btn btn-info  btn-small mt-3">
                Explore More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPayment;
