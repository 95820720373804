import React, { useState } from "react";
import useForm from "effects/useForm";
import Authentication from "services/Authentication";
import successNotification from "utils/successNotification";
import dangerNotification from "utils/dangerNotification";
import putAddressDetails from "services/user/putAddressDetails";

const auth = new Authentication();
const AddressDetails = () => {
  const [loading, setLoading] = useState(false);
  const jwtPayload = auth.getAccessTokenPayload();

  const {
    address,
    setAddress,
    addressErrorMessage,
    addressLine2,
    setAddressLine2,
    city,
    setCity,
    cityErrorMessage,
    town,
    setTown,
    townErrorMessage,
    postcode,
    setPostcode,
    postcodeErrorMessage,
    country,
    setCountry,
    countryErrorMessage,
    isValid,
  } = useForm({
    address: {
      validation: (address) => {
        if (address === "") {
          return "Address is required";
        }
      },
      initialValue: jwtPayload.address,
    },
    addressLine2: {
      validation: () => {}, //Is this the correct way?
      initialValue: jwtPayload.addressLine2,
    },
    city: {
      validation: (city) => {
        if (city === "") {
          return "City is required";
        }
      },
      initialValue: jwtPayload.city,
    },
    town: {
      validation: (town) => {
        if (town === "") {
          return "Town is required";
        }
      },
      initialValue: jwtPayload.town,
    },
    postcode: {
      validation: (postcode) => {
        if (postcode === "") {
          return "Postcode is required";
        }
      },
      initialValue: jwtPayload.postcode,
    },
    country: {
      validation: (country) => {
        if (country === "") {
          return "Country is required";
        }
      },
      initialValue: jwtPayload.country,
    },
  });

  const handleSave = () => {
    setLoading(true);
    putAddressDetails({
      address,
      addressLine2,
      city,
      town,
      postcode,
      country,
    })
      .then(() => {
        setLoading(false);
        setAddress(address);
        setAddressLine2(addressLine2);
        setCity(city);
        setTown(town);
        setPostcode(postcode);
        setCountry(country);
        successNotification("Saved");
      })
      .catch((err) => {
        setLoading(false);
        dangerNotification("Something went wrong!");
      });
  };

  return (
    <>
      <div className="uk-card-default rounded mt-4">
        <div className="p-3">
          <h5 className="mb-0 text-blue"> Billing address </h5>
        </div>
        <hr className="m-0"></hr>
        <form className="uk-child-width-1-2@s uk-grid-small p-4" data-uk-grid>
          <div>
            <h5 className="uk-text-bold mb-2 text-blue"> Address Line 1</h5>
            <input
              type="text"
              className="uk-input"
              placeholder="Address "
              value={address}
              onChange={(event) => {
                setAddress(event.target.value);
              }}
            />
            {addressErrorMessage && (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  "margin-top": "-7px",
                }}
              >
                {addressErrorMessage}
              </p>
            )}
          </div>
          <div>
            <h5 className="uk-text-bold mb-2 text-blue"> Address Line 2 </h5>
            <input
              type="text"
              className="uk-input"
              placeholder="Address Line 2"
              value={addressLine2}
              onChange={(event) => {
                setAddressLine2(event.target.value);
              }}
            />
          </div>
          <div>
            <h5 className="uk-text-bold mb-2 text-blue"> City </h5>
            <input
              type="text"
              className="uk-input"
              placeholder="City Name"
              value={city}
              onChange={(event) => {
                setCity(event.target.value);
              }}
            />
            {cityErrorMessage && (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  "margin-top": "-7px",
                }}
              >
                {cityErrorMessage}
              </p>
            )}
          </div>
          <div>
            <h5 className="uk-text-bold mb-2 text-blue"> Postal Code </h5>
            <input
              type="text"
              className="uk-input"
              placeholder="Postal Code"
              value={postcode}
              onChange={(event) => {
                setPostcode(event.target.value);
              }}
            />
            {postcodeErrorMessage && (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  "margin-top": "-7px",
                }}
              >
                {postcodeErrorMessage}
              </p>
            )}
          </div>
          <div>
            <h5 className="uk-text-bold mb-2 text-blue"> Town </h5>
            <input
              type="text"
              className="uk-input"
              placeholder="State"
              value={town}
              onChange={(event) => {
                setTown(event.target.value);
              }}
            />
            {townErrorMessage && (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  "margin-top": "-7px",
                }}
              >
                {townErrorMessage}
              </p>
            )}
          </div>
          <div>
            <h5 className="uk-text-bold mb-2 text-blue"> Country </h5>
            <input
              type="text"
              className="uk-input"
              placeholder="Your Country"
              value={country}
              onChange={(event) => {
                setCountry(event.target.value);
              }}
            />
            {countryErrorMessage && (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  "margin-top": "-7px",
                }}
              >
                {countryErrorMessage}
              </p>
            )}
          </div>
        </form>
        <div className="uk-flex uk-flex-right p-4">
          <button className="btn btn-link mr-2">Cancel</button>
          <button
            className="btn btn-default grey"
            disabled={!isValid}
            onClick={(event) => {
              event.preventDefault();
              handleSave();
            }}
          >
            {loading ? "Saving..." : "Save changes"}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddressDetails;
