//import { useHistory } from "react-router";
import React from "react";
import styled from "styled-components";
import Authentication from "services/Authentication";
import { motion } from "framer-motion";
import "./styles.css";

// const PaymentBtn = styled(motion.a)`
//   padding: 15px 31px;
//   background-color: #3659a2;
//   color: #fff;
//   width: 250px;
//   font-size: 18px;
//   font-weight: 600;
//   border: none;
//   :hover {
//     background-color: #36a24a;
//   }
// `;

const Text = styled.p`
  color: #000;
  font-size: 24px;
  font-weight: 600;
  border: none;
  margin: 30px;
`;

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    duration: 0,

    transition: {
      type: "spring",
      damping: 65,
      stiffness: 500,
      delay: 0.3,
    },
  },
};

const auth = new Authentication();

const PaymentPopup = (props) => {
  //const history = useHistory();
  const jwtPayload = auth.getAccessTokenPayload();
  return (
    <>
      {!props.hasPaid && jwtPayload.userTypeId !== 1 && (
        <motion.div
          className="payment-popup"
          variants={dropIn}
          initial="hidden"
          animate="visible"
          style={{
            opacity: !props.hasPaid && 0.5,
          }}
        >
          <div className="payment-message">
            <Text {...props}>
              Dear {jwtPayload.firstName}, please make a transfer to this
              account and send your receipt to{" "}
              <span style={{ color: "#3659a2" }}>info@cypruscodes.com</span> for
              access.
            </Text>
            <div
              className="d-flex justify-content-center align-items-start flex-column"
              style={{ fontSize: "22px", margin: "30px" }}
            >
              <span style={{ fontWeight: "600", color: "#3659a2" }}>
                <span style={{ fontWeight: "600", color: "#000" }}>
                  Account IBAN:
                </span>{" "}
                TR750006400000168130038670
              </span>
              <span style={{ fontWeight: "600", color: "#3659a2" }}>
                <span style={{ fontWeight: "600", color: "#000" }}>
                  Account Name:
                </span>{" "}
                Akdemy Teknoloji Ltd
              </span>
              <span style={{ fontWeight: "600", color: "#3659a2" }}>
                <span style={{ fontWeight: "600", color: "#000" }}>
                  Account Currency:
                </span>{" "}
                Turkish Lira ₺
              </span>
            </div>
            <br />

            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              {/* <PaymentBtn
                className="btn btn-success"
                href=""
                onClick={() => {
                  history.push(`/course-payment-module/1`);
                }}
                whileHover={{ scale: 1.1, duration: 1.9 }}
                whileTap={{ scale: 0.2 }}
              >
                Pay
              </PaymentBtn> */}
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};
export default PaymentPopup;
