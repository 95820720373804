import React from "react";
import PropTypes from "prop-types";
import ScriptLoader from "react-script-loader-hoc";

const CodePen = ({ id, title }) => (
  <p
    className="codepen"
    data-height="265"
    data-theme-id="dark"
    data-default-tab="html,result"
    data-user="manchestercodes"
    data-slug-hash={id}
    style={{
      height: 265,
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "2px solid",
      margin: "1em 0",
      padding: "1em",
    }}
    data-pen-title={title}
  >
    <span>
      See the Pen{" "}
      <a href={`https://codepen.io/manchestercodes/pen/${id}`}>{title}</a> by
      Manchester Codes (
      <a href="https://codepen.io/manchestercodes">@manchestercodes</a>) on{" "}
      <a href="https://codepen.io">CodePen</a>.
    </span>
  </p>
);

CodePen.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ScriptLoader("https://static.codepen.io/assets/embed/ei.js")(
  CodePen
);
