import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const ModalContainer = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999 !important;
`;

const ModalInner = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  max-width: 700px;
  margin-left: 25%;
  padding: 1rem;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

const Login = styled(motion.button)`
  padding: 15px 31px;
  background-color: #3659a2;
  color: #fff;
  width: 250px;
  font-size: 18px;
  font-weight: 600;
  border: none;
  :hover {
    background-color: #36a24a;
  }
`;

const Text = styled.p`
  font-size: 20px;
  font-weight: 600;
`;
const RegistrationCofirmation = () => {
  return (
    <ModalContainer>
      <ModalInner>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ion-icon
            style={{ fontSize: "100px", color: "#36a24a" }}
            name="checkmark-circle-outline"
          ></ion-icon>
        </div>
        <div style={{ textAlign: "center" }}>
          <Text>
            Congratulations, your account has been successfully created.
          </Text>
        </div>
        <Wrapper>
          <Link to="/login">
            <Login
              className="btn btn-success"
              whileHover={{ scale: 1.1, duration: 1.9 }}
              whileTap={{ scale: 0.2 }}
            >
              Login
            </Login>
          </Link>
        </Wrapper>
      </ModalInner>
    </ModalContainer>
  );
};

export default RegistrationCofirmation;
