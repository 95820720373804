import PersonalDetails from "../../components/StudentPersonalDetailsEdit/index.js";

const StudentPopupEdit = ({ student, setShowEdit }) => {
  return (
    <>
      <div className="popup-boxs">
        <div className="boxs">
          <div className="uk-card-default rounded">
              <PersonalDetails student={student} setShowEdit={setShowEdit} />
            <div className="uk-flex uk-flex-right p-4"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StudentPopupEdit;
