import React from "react";
import AdminSideBar from "components/AdminSideBar";
import { Link } from "react-router-dom";
import Authentication from "services/Authentication";
import Footer from "components/Footer";

const auth = new Authentication();

const AdminInstructorsListView = () => {
  const jwtPayload = auth.getAccessTokenPayload();

  return (
    <>
      <AdminSideBar />
      <div className="page-content">
        <div className="page-content-inner">
          <div className="d-flex">
            <nav id="breadcrumbs" className="mb-3">
              <ul>
                <li>
                  <a href="#">
                    {" "}
                    <i className="uil-home-alt"></i>{" "}
                  </a>
                </li>
                <li>
                  <a href="#"> Instructors </a>
                </li>
                <li>Instructors list</li>
              </ul>
            </nav>
          </div>
          <div class="section-header mb-lg-2 border-0 uk-flex-middle">
            <div class="section-header-left">
              <h2 class="uk-heading-line text-left">
                <span> Instructors </span>
              </h2>
            </div>
            <div class="section-header-right">
              <div class="display-as">
                <Link
                  to="/admin-instructors-list"
                  class="active"
                  data-uk-tooltip="title: Veiw as list; pos: top-right"
                  title=""
                  aria-expanded="false"
                >
                  <i class="icon-feather-list"></i>
                </Link>
              </div>
              <div class="display-as">
                <Link
                  to="/admin-instructors"
                  data-uk-tooltip="title: Veiw as Grid; pos: top-right"
                  title=""
                  aria-expanded="false"
                >
                  <i class="icon-feather-grid"></i>
                </Link>
              </div>

              <a href="#" class="btn btn-default uk-visible@s">
                {" "}
                <i class="uil-plus"></i> Add Instructors
              </a>
            </div>
          </div>

          <div className="card">
            {/* <!-- Card header --> */}
            <div className="card-header actions-toolbar border-0">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="d-inline-block mb-0">Instructors</h4>
                <div className="d-flex">
                  <a
                    href="#"
                    className="btn btn-icon btn-hover  btn-circle"
                    data-uk-tooltip="Search Instructor"
                  >
                    <i className="uil-search"></i>
                  </a>
                  <div
                    className="uk-drop"
                    data-uk-drop="mode: click; pos: left-center; offset: 0"
                  >
                    <form className="uk-search uk-search-navbar uk-width-1-1">
                      <input
                        className="uk-search-input shadow-0 uk-form-small"
                        type="search"
                        placeholder="Search..."
                        autoFocus
                      />
                    </form>
                  </div>

                  <a
                    href="#"
                    className="btn btn-icon btn-hover  btn-circle"
                    data-uk-tooltip="filter"
                  >
                    <i className="uil-filter"></i>
                  </a>
                  <div data-uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
                    <ul className="uk-nav uk-dropdown-nav">
                      <li className="uk-active">
                        <a href="#"> Newest </a>
                      </li>
                      <li>
                        <a href="#">From A-Z</a>
                      </li>
                      <li>
                        <a href="#">From Z-A</a>
                      </li>
                    </ul>
                  </div>

                  <a
                    href="#"
                    className="btn btn-icon btn-hover  btn-circle"
                    data-uk-tooltip="More"
                  >
                    <i className="uil-ellipsis-h"></i>
                  </a>
                  <div data-uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
                    <ul className="uk-nav uk-dropdown-nav">
                      <li>
                        <a href="#">Refresh</a>
                      </li>
                      <li>
                        <a href="#">Manage</a>
                      </li>
                      <li>
                        <a href="#">Setting</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Table --> */}
            <div className="table-responsive">
              <table className="table align-items-center">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Joined at</th>
                    <th scope="col">Enerolled</th>
                    <th scope="col">Total payments</th>
                    <th scope="col">Activity</th>
                    <th scope="col"> </th>
                  </tr>
                </thead>
                <tbody className="list">
                  <tr>
                    <th scope="row">
                      <div className="media align-items-center">
                        <div>
                          <div
                            className="avatar-parent-child"
                            style={{ width: "max-content" }}
                          >
                            <img
                              alt="Image placeholder"
                              src="../assets/images/avatars/avatar-2.jpg"
                              className="avatar  rounded-circle"
                            />
                            <span className="avatar-child avatar-badge bg-success"></span>
                          </div>
                        </div>
                        <div className="media-body ml-4">
                          <a href="#" className="name h6 mb-0 text-sm">
                            Ulaş Öğüç
                          </a>
                          <small className="d-block font-weight-bold">
                            #1001
                          </small>
                        </div>
                      </div>
                    </th>
                    <td> 10/09/2018 </td>
                    <td> 15 </td>
                    <td> $1035,00 </td>
                    <td>
                      <div className="progress" style={{ height: "7px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "45%" }}
                        ></div>
                      </div>
                    </td>
                    <td className="text-right">
                      {/* <!-- Actions --> */}
                      <div className="actions ml-3">
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Quick view"
                        >
                          <i className="uil-external-link-alt "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Edit"
                        >
                          <i className="uil-pen "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Delete user"
                        >
                          <i className="uil-trash-alt text-danger"></i>{" "}
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="media align-items-center">
                        <div>
                          <div className="avatar-parent-child">
                            <img
                              alt="Image placeholder"
                              src="../assets/images/avatars/avatar-3.jpg"
                              className="avatar  rounded-circle"
                            />
                            <span className="avatar-child avatar-badge bg-light"></span>
                          </div>
                        </div>
                        <div className="media-body ml-4">
                          <a href="#" className="name h6 mb-0 text-sm">
                            Serhan Candas
                          </a>
                          <small className="d-block font-weight-bold">
                            #1002
                          </small>
                        </div>
                      </div>
                    </th>
                    <td> 31/09/2018 </td>
                    <td> 17 </td>
                    <td> $3010,00 </td>
                    <td>
                      <div className="progress" style={{ height: "7px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "25%" }}
                        ></div>
                      </div>
                    </td>
                    <td className="text-right">
                      {/* <!-- Actions --> */}
                      <div className="actions ml-3">
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Quick view"
                        >
                          <i className="uil-external-link-alt "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Edit"
                        >
                          <i className="uil-pen "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Delete user"
                        >
                          <i className="uil-trash-alt text-danger"></i>{" "}
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="media align-items-center">
                        <div>
                          <div className="avatar-parent-child">
                            <img
                              alt="Image placeholder"
                              src="../assets/images/avatars/avatar-4.jpg"
                              className="avatar  rounded-circle"
                            />
                            <span className="avatar-child avatar-badge bg-light"></span>
                          </div>
                        </div>
                        <div className="media-body ml-4">
                          <a href="#" className="name h6 mb-0 text-sm">
                            Alex Dolgove
                          </a>
                          <small className="d-block font-weight-bold">
                            #1003
                          </small>
                        </div>
                      </div>
                    </th>
                    <td> 31/09/2018 </td>
                    <td> 35 </td>
                    <td> $1340,00 </td>
                    <td>
                      <div className="progress" style={{ height: "7px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "55%" }}
                        ></div>
                      </div>
                    </td>
                    <td className="text-right">
                      {/* <!-- Actions --> */}
                      <div className="actions ml-3">
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Quick view"
                        >
                          <i className="uil-external-link-alt "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Edit"
                        >
                          <i className="uil-pen "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Delete user"
                        >
                          <i className="uil-trash-alt text-danger"></i>{" "}
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="media align-items-center">
                        <div>
                          <div className="avatar-parent-child">
                            <img
                              alt="Image placeholder"
                              src="../assets/images/avatars/avatar-1.jpg"
                              className="avatar  rounded-circle"
                            />
                            <span className="avatar-child avatar-badge bg-success"></span>
                          </div>
                        </div>
                        <div className="media-body ml-4">
                          <a href="#" className="name h6 mb-0 text-sm">
                            Adrian Mohani
                          </a>
                          <small className="d-block font-weight-bold">
                            #1004
                          </small>
                        </div>
                      </div>
                    </th>
                    <td> 31/09/2018 </td>
                    <td> 22 </td>
                    <td> $3010,00 </td>
                    <td>
                      <div className="progress" style={{ height: "7px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "25%" }}
                        ></div>
                      </div>
                    </td>
                    <td className="text-right">
                      {/* <!-- Actions --> */}
                      <div className="actions ml-3">
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Quick view"
                        >
                          <i className="uil-external-link-alt "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Edit"
                        >
                          <i className="uil-pen "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Delete user"
                        >
                          <i className="uil-trash-alt text-danger"></i>{" "}
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="media align-items-center">
                        <div>
                          <div className="avatar-parent-child">
                            <img
                              alt="Image placeholder"
                              src="../assets/images/avatars/avatar-2.jpg"
                              className="avatar  rounded-circle"
                            />
                            <span className="avatar-child avatar-badge bg-success"></span>
                          </div>
                        </div>
                        <div className="media-body ml-4">
                          <a href="#" className="name h6 mb-0 text-sm">
                            {" "}
                            John Sullivan
                          </a>
                          <small className="d-block font-weight-bold">
                            #1005
                          </small>
                        </div>
                      </div>
                    </th>
                    <td> 31/02/2018 </td>
                    <td> 253 </td>
                    <td> $2310,00 </td>
                    <td>
                      <div className="progress" style={{ height: "7px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "65%" }}
                        ></div>
                      </div>
                    </td>
                    <td className="text-right">
                      {/* <!-- Actions --> */}
                      <div className="actions ml-3">
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Quick view"
                        >
                          <i className="uil-external-link-alt "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Edit"
                        >
                          <i className="uil-pen "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Delete user"
                        >
                          <i className="uil-trash-alt text-danger"></i>{" "}
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="media align-items-center">
                        <div>
                          <div className="avatar-parent-child">
                            <img
                              alt="Image placeholder"
                              src="../assets/images/avatars/avatar-3.jpg"
                              className="avatar  rounded-circle"
                            />
                            <span className="avatar-child avatar-badge bg-success"></span>
                          </div>
                        </div>
                        <div className="media-body ml-4">
                          <a href="#" className="name h6 mb-0 text-sm">
                            Stella Johnson
                          </a>
                          <small className="d-block font-weight-bold">
                            #1001
                          </small>
                        </div>
                      </div>
                    </th>
                    <td> 10/09/2018 </td>
                    <td> 15 </td>
                    <td> $1035,00 </td>
                    <td>
                      <div className="progress" style={{ height: "7px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "45%" }}
                        ></div>
                      </div>
                    </td>
                    <td className="text-right">
                      {/* <!-- Actions --> */}
                      <div className="actions ml-3">
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Quick view"
                        >
                          <i className="uil-external-link-alt "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Edit"
                        >
                          <i className="uil-pen "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Delete user"
                        >
                          <i className="uil-trash-alt text-danger"></i>{" "}
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="media align-items-center">
                        <div>
                          <div className="avatar-parent-child">
                            <img
                              alt="Image placeholder"
                              src="../assets/images/avatars/avatar-4.jpg"
                              className="avatar  rounded-circle"
                            />
                            <span className="avatar-child avatar-badge bg-light"></span>
                          </div>
                        </div>
                        <div className="media-body ml-4">
                          <a href="#" className="name h6 mb-0 text-sm">
                            Jonathan Madano
                          </a>
                          <small className="d-block font-weight-bold">
                            #1002
                          </small>
                        </div>
                      </div>
                    </th>
                    <td> 31/09/2018 </td>
                    <td> 17 </td>
                    <td> $3010,00 </td>
                    <td>
                      <div className="progress" style={{ height: "7px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "25%" }}
                        ></div>
                      </div>
                    </td>
                    <td className="text-right">
                      {/* <!-- Actions --> */}
                      <div className="actions ml-3">
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Quick view"
                        >
                          <i className="uil-external-link-alt "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Edit"
                        >
                          <i className="uil-pen "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Delete user"
                        >
                          <i className="uil-trash-alt text-danger"></i>{" "}
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="media align-items-center">
                        <div>
                          <div className="avatar-parent-child">
                            <img
                              alt="Image placeholder"
                              src="../assets/images/avatars/avatar-1.jpg"
                              className="avatar  rounded-circle"
                            />
                            <span className="avatar-child avatar-badge bg-light"></span>
                          </div>
                        </div>
                        <div className="media-body ml-4">
                          <a href="#" className="name h6 mb-0 text-sm">
                            Alex Dolgove
                          </a>
                          <small className="d-block font-weight-bold">
                            #1003
                          </small>
                        </div>
                      </div>
                    </th>
                    <td> 31/09/2018 </td>
                    <td> 35 </td>
                    <td> $1340,00 </td>
                    <td>
                      <div className="progress" style={{ height: "7px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "55%" }}
                        ></div>
                      </div>
                    </td>
                    <td className="text-right">
                      {/* <!-- Actions --> */}
                      <div className="actions ml-3">
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Quick view"
                        >
                          <i className="uil-external-link-alt "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Edit"
                        >
                          <i className="uil-pen "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Delete user"
                        >
                          <i className="uil-trash-alt text-danger"></i>{" "}
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="media align-items-center">
                        <div>
                          <div className="avatar-parent-child">
                            <img
                              alt="Image placeholder"
                              src="../assets/images/avatars/avatar-3.jpg"
                              className="avatar  rounded-circle"
                            />
                            <span className="avatar-child avatar-badge bg-success"></span>
                          </div>
                        </div>
                        <div className="media-body ml-4">
                          <a href="#" className="name h6 mb-0 text-sm">
                            Adrian Mohani
                          </a>
                          <small className="d-block font-weight-bold">
                            #1004
                          </small>
                        </div>
                      </div>
                    </th>
                    <td> 31/09/2018 </td>
                    <td> 22 </td>
                    <td> $3010,00 </td>
                    <td>
                      <div className="progress" style={{ height: "7px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "25%" }}
                        ></div>
                      </div>
                    </td>
                    <td className="text-right">
                      {/* <!-- Actions --> */}
                      <div className="actions ml-3">
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Quick view"
                        >
                          <i className="uil-external-link-alt "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Edit"
                        >
                          <i className="uil-pen "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Delete user"
                        >
                          <i className="uil-trash-alt text-danger"></i>{" "}
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="media align-items-center">
                        <div>
                          <div className="avatar-parent-child">
                            <img
                              alt="Image placeholder"
                              src="../assets/images/avatars/avatar-1.jpg"
                              className="avatar  rounded-circle"
                            />
                            <span className="avatar-child avatar-badge bg-success"></span>
                          </div>
                        </div>
                        <div className="media-body ml-4">
                          <a href="#" className="name h6 mb-0 text-sm">
                            {" "}
                            John Sullivan
                          </a>
                          <small className="d-block font-weight-bold">
                            #1005
                          </small>
                        </div>
                      </div>
                    </th>
                    <td> 31/02/2018 </td>
                    <td> 253 </td>
                    <td> $2310,00 </td>
                    <td>
                      <div className="progress" style={{ height: "7px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "65%" }}
                        ></div>
                      </div>
                    </td>
                    <td className="text-right">
                      {/* <!-- Actions --> */}
                      <div className="actions ml-3">
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Quick view"
                        >
                          <i className="uil-external-link-alt "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Edit"
                        >
                          <i className="uil-pen "></i>{" "}
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-sm btn-circle"
                          data-uk-tooltip="Delete user"
                        >
                          <i className="uil-trash-alt text-danger"></i>{" "}
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AdminInstructorsListView;
