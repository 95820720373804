import React from "react";
import { useHistory } from "react-router";
import useFetchData from "effects/useFetchData";
import getActiveModules from "services/getActiveModules";
import formatDateRelativeOrAbsolute from "utils/formatDateRelativeOrAbsolute";

const CourseGrid = () => {
  const history = useHistory();
  const [loading, error, activeModulesResponse] = useFetchData(() =>
    getActiveModules()
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Something went wrong</div>;
  }
  const { activeModules } = activeModulesResponse;
  return (
    <>
      <div id="wrapper">
        <div className="page-content">
          <div className="container">
            <h1 className="uk-margin-top">Active Courses</h1>

            {/* <h4> Starting soon</h4> */}

            {/* <div className="section-small">
              <div
                className="uk-child-width-1-4@m uk-child-width-1-3@s course-card-grid"
                data-uk-grid
              >
                {activeModules
                  .filter((m) => m.endDate !== null)
                  .map((course) => {
                    return (
                      <div>
                        <a
                          onClick={() => {
                            history.push(`/course/${course.cohortId}`);
                          }}
                        >
                          <div className="course-card">
                            <div className="course-card-thumbnail ">
                              <img src={course.programs[0].programImage} />
                              <span className="play-button-trigger"></span>
                            </div>
                            <div className="course-card-body">
                              <div className="course-card-info">
                                <div>
                                  <span className="catagroy">
                                    {course.cohortTitle}
                                  </span>
                                </div>
                                <div>
                                  <i className="icon-feather-bookmark icon-small"></i>
                                </div>
                              </div>

                              <h4>{course.programs[0].programName}</h4>
                              <p>
                                This program contains{" "}
                                {course.programs[0].modules.length}
                                {course.programs[0].modules.length <= 1
                                  ? " module"
                                  : " modules"}
                              </p>

                              <div className="course-card-footer">
                                <h5>
                                  {" "}
                                  <i className="icon-feather-calendar"></i>
                                  Start: {course.startDate}
                                </h5>
                                <h5>
                                  {" "}
                                  <i className="icon-feather-clock"></i>{" "}
                                  {course.programs[0].durationWeeks} weeks
                                </h5>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
              </div>
            </div> */}

            {/* <div className="section-header pb-0 mt-5">
              <div className="section-header-left">
                <h4> self-study courses</h4>
              </div>
              <div className="section-header-right"></div>
            </div> */}

            <div className="section-small">
              <div
                className="uk-child-width-1-4@m uk-child-width-1-3@s course-card-grid uk-grid-match"
                data-uk-grid
              >
                {activeModules
                  .filter((m) => m.endDate == null)
                  .map((course) => {
                    return (
                      <div>
                        <a
                          onClick={() => {
                            history.push(`/course/${course.cohortId}`);
                          }}
                        >
                          <div className="course-card">
                            <div className="course-card-thumbnail ">
                              <img src={course.programs[0].programImage} />
                              <span className="play-button-trigger"></span>
                            </div>
                            <div className="course-card-body">
                              <div className="course-card-info">
                                <div>
                                  <span className="catagroy">
                                    {course.cohortTitle}
                                  </span>
                                </div>
                                <div>
                                  <i className="icon-feather-bookmark icon-small"></i>
                                </div>
                              </div>

                              <h4>{course.programs[0].programName}</h4>
                              <p>
                                This program contains{" "}
                                {course.programs[0].modules.length}
                                {course.programs[0].modules.length <= 1
                                  ? " module"
                                  : " modules"}
                              </p>

                              <div className="course-card-footer">
                                <h5>
                                  {" "}
                                  <i className="icon-feather-calendar"></i>
                                  Start: {course.startDate}
                                </h5>
                                <h5>
                                  {" "}
                                  <i className="icon-feather-clock"></i>{" "}
                                  {course.programs[0].durationWeeks} weeks
                                </h5>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseGrid;
