import http from "utils/http";
import { blog } from "constants/endpoints";

const deleteBlog = ({ blogId }) => {
  const DELETE_BLOGCOMPLETE_ENDPOINT = blog.deleteBlog({
    blogId,
  });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.delete(`${API_BASE_URL}${DELETE_BLOGCOMPLETE_ENDPOINT}`);
};

export default deleteBlog;
