import React from "react";
import { Link } from "react-router-dom";

const PasswordRecoveryRequestSent = () => {
  return (
    <>
      <div data-uk-height-viewport className="uk-flex uk-flex-middle">
        <div className="uk-width-2-3@m uk-width-1-2@s m-auto rounded">
          <div className="uk-card-default p-5 rounded">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ion-icon
                style={{ fontSize: "70px", color: "green" }}
                name="checkmark-circle-outline"
              ></ion-icon>
            </div>

            <div className="mb-4 uk-text-center">
              <p className="my-2">
                Your unique password recovery link has been sent to your email.
                Please check your inbox.
              </p>
            </div>
            <form>
              <div className="uk-form-group"></div>

              <div className="uk-form-group"></div>

              <div
                className="mt-4 uk-flex-middle uk-grid-small"
                data-uk-grid
                style={{ justifyContent: "center" }}
              >
                <div className="uk-width-auto@s">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Link to="/login">
                      <button
                        type="submit"
                        className="btn btn-default"
                        style={{ minWidth: "115px" }}
                      >
                        Login
                      </button>
                    </Link>
                    <Link to="/recover-password-request">
                      <button
                        type="submit"
                        className="btn btn-default"
                        style={{ padding: "10px 13px" }}
                      >
                        Send Another
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordRecoveryRequestSent;
