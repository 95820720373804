import React, { useEffect, useRef, useState } from "react";
import RichTextEditor from "components/RichTextEditor";
import RenderPeriodically from "components/RenderPeriodically";
import DocumentStatus from "./components/DocumentStatus";
import FullscreenModal from "components/FullScreenModal";
import ScrollDetector from "components/ScrollDetector";
import ImageUpload from "components/ImageUpload";
import EditableLine from "./components/EditableLine";
import debounce from "utils/debounce";
import styled from "styled-components";
import useStates from "effects/useStates";
import useFetchData from "effects/useFetchData";
import getBlogCategories from "services/Blog/getBlogCategories";
import postBlog from "services/Blog/postBlog";
import slugify from "utils/slugify";
import putBlog from "services/Blog/putBlog";
import successNotification from "utils/successNotification";
import dangerNotification from "utils/dangerNotification";
import deleteBlog from "services/Blog/deleteBlog";

const BlogHeaderWrapper = styled.div`
  display: flex;
`;

const CategoryWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 10px;
`;

let stickyPanelRef;

const cumulativeOffset = function (element) {
  let top = 0;
  let left = 0;
  do {
    top += element?.offsetTop || 0;
    left += element?.offsetLeft || 0;
    element = element?.offsetParent;
  } while (element);

  return {
    top: top,
    left: left,
  };
};

const EditorModal = ({
  setOpen,
  initialContent,
  blog = {},
  refreshBlogs,
  nightMode,
}) => {
  const {
    blogId,
    setBlogId,
    title,
    setTitle,
    description,
    setDescription,
    image,
    setImage,
    category,
    setCategory,
  } = useStates({
    blogId: blog.blogId ?? null,
    title: blog.title ?? "",
    description: blog.description ?? "",
    image: blog.image ?? null,
    category: blog.categoryId ?? 1,
  });

  const editorRef = useRef(null);
  const toolbarOffset = useRef(0);
  const [isDirty, setDirty] = useState(false);
  const [isPublished, setPublished] = useState(false);
  const [lastSavedAt, setLastSavedAt] = useState(null);
  const [categoryLoading, categoryError, categories] = useFetchData(
    () => getBlogCategories(),
    []
  );

  const calculateOffset = () => {
    const toolbarRef = document.getElementsByClassName("ck-toolbar__items")[0];
    toolbarOffset.current = cumulativeOffset(toolbarRef).top;
  };

  useEffect(calculateOffset, []);

  const handleScroll = debounce(() => {
    const modalRef = document.getElementById("modal-full");
    const toolbarRef = document.getElementsByClassName("ck-toolbar__items")[0];

    if (!modalRef && !toolbarRef) {
      return;
    }

    let offset = modalRef.scrollTop;
    if (Math.round(offset) < toolbarOffset.current) {
      stickyPanelRef.isActive = false;
      stickyPanelRef.isSticky = false;
    } else {
      stickyPanelRef.isActive = true;
      stickyPanelRef.isSticky = true;
    }
  }, 5);
  const removeBlog = () => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("are you sure?")) {
      return;
    }
    deleteBlog({ blogId })
      .then(() => {
        refreshBlogs();
        setOpen(false);
        successNotification("Blog deleted.");
      })
      .catch(() => {
        dangerNotification("Something went wrong. Please try again.");
      });
  };

  if (categoryLoading) {
    return <div>Loading...</div>;
  }

  if (categoryError) {
    return <div>Something went wrong</div>;
  }

  return (
    <FullscreenModal
      closeModal={() => {
        setOpen(false);
      }}
    >
      <div className="row" data-uk-grid>
        <ScrollDetector handleScroll={handleScroll} />
        <div className="uk-width-expand@m">
          <BlogHeaderWrapper>
            <div style={{ flex: 1, color: nightMode ? "white" : "black" }}>
              <EditableLine
                text={title}
                onChange={setTitle}
                placeholder="Title"
              />
              <EditableLine
                text={description}
                onChange={setDescription}
                placeholder="Description"
              />
              <div>
                <CategoryWrapper>
                  <div className="mr-2">
                    <label for="category">Category:</label>
                  </div>
                  <div>
                    <select
                      name="category"
                      id="category"
                      style={{ height: "50px" }}
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="unselected" disabled>
                        Please choose category
                      </option>
                      {Boolean(categories?.allBlogCategories?.length > 0) &&
                        categories?.allBlogCategories.map((category) => (
                          <option
                            key={category.categoryId}
                            value={category.categoryId}
                          >
                            {category.category}
                          </option>
                        ))}
                    </select>
                  </div>
                </CategoryWrapper>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <ImageUpload
                image={image}
                type="banner"
                onChange={(img) => {
                  setImage(img);
                  setTimeout(() => {
                    calculateOffset();
                  }, 200);
                }}
              />
            </div>
          </BlogHeaderWrapper>
          <div className="mb-2 mt-1">
            <small>
              {" "}
              {isPublished ? (
                <span className="badge badge-success m-1 p-2">
                  <i className="icon-feather-upload-cloud p-2"></i> PUBLISHED
                </span>
              ) : (
                <span className="badge badge-warning m-1 p-2">
                  <i className="icon-feather-upload-cloud p-2"></i> DRAFT
                </span>
              )}
              <RenderPeriodically refreshMs={5000}>
                <DocumentStatus isDirty={isDirty} lastSavedAt={lastSavedAt} />
              </RenderPeriodically>
            </small>
          </div>
          <div style={{ color: "black" }}>
            <RichTextEditor
              data={initialContent}
              onReady={(editor) => {
                stickyPanelRef = editor.ui.view.stickyPanel;
                editorRef.current = editor;
              }}
              onChange={(event, editor) => {
                setDirty(true);
              }}
            />
          </div>{" "}
          <div className="editor-button-actions-wrapper">
            <div className="p-4 pl-0">
              <button
                type="button"
                className="btn btn-delete"
                onClick={removeBlog}
              >
                Delete Blog
              </button>
            </div>
            <div className="uk-flex uk-flex-right p-4">
              <button
                class="btn btn-info mr-2"
                onClick={() => {
                  setPublished(!isPublished);
                  refreshBlogs();
                }}
              >
                {isPublished ? "Unpublish" : "Publish for review"}
              </button>
              <button
                class="btn btn-default"
                onClick={() => {
                  const data = editorRef.current?.getData();
                  setDirty(false);
                  setLastSavedAt(new Date().toISOString());
                  const payload = {
                    published: isPublished,
                    categoryId: category,
                    description,
                    image,
                    slug: slugify(title),
                    title,
                    blogContent: data,
                    blogId,
                  };
                  let action;

                  if (blogId) {
                    action = putBlog;
                  } else {
                    action = postBlog;
                  }
                  action(payload)
                    .then((response) => {
                      if (action === postBlog) {
                        const blogId = response.data.blog;
                        setBlogId(blogId);
                        successNotification("Blog saved.");
                      } else {
                        successNotification("Blog updated.");
                      }
                    })
                    .catch(() => {
                      successNotification(
                        "Something went wrong. Please try again."
                      );
                    });
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </FullscreenModal>
  );
};

export default EditorModal;
