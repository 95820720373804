import React from "react";
import Authentication from "services/Authentication";

const auth = new Authentication();

const RenderIfRole = ({ children, role, fallback }) => {
  const isAuthenticated = auth.isAuthenticated();
  const accessTokenPayload = auth.getAccessTokenPayload();

  if (isAuthenticated && accessTokenPayload?.userRole === role) {
    return <> {children} </>;
  }

  if (fallback) {
    return <>{fallback}</>;
  }

  return null;
};

export default RenderIfRole;
