import { useState } from "react";
import InstructorPopup from "components/InstructorPopupWindow";
import getGravatarImg from "utils/getGravatarImg";

const InstructorCard = ({ instructor }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <div>
        <div class="card animate-this">
          <div class="card-body text-center" style={{ width: "300px" }}>
            <div class="avatar-parent-child">
              <img
                src={getGravatarImg(instructor.firstName, instructor.lastName)}
                alt=""
                className="header-profile-icon"
              />
              <span class="avatar-child avatar-badge bg-success"></span>
            </div>
            <h5 class="h6 mt-4 mb-0">
              {instructor.firstName} {instructor.lastName}
            </h5>
            <a href="#" class="d-block text-sm text-muted mb-3">
              {instructor.email}
            </a>
            <a href="#" class="d-block text-sm text-muted mb-3">
              {instructor.phoneNumber}
            </a>
            <div class="d-flex justify-content-between px-4">
              <a
                href="#"
                class="btn btn-icon btn-hover btn-circle"
                data-uk-tooltip="Edit"
              >
                <i class="uil-edit-alt"></i>{" "}
              </a>
              <a
                href="#"
                class="btn btn-icon btn-hover btn-circle"
                data-uk-tooltip="Send Message"
              >
                <i class="uil-envelope"></i>
              </a>
              <a
                href="#"
                class="btn btn-icon btn-hover btn-circle"
                data-uk-tooltip="Delete"
              >
                <i class="uil-trash-alt"></i>{" "}
              </a>
            </div>
          </div>
          <div class="card-footer text-center py-2">
            <a href="#" class="text-muted uk-text-small">
              {" "}
              {/* {instructors.noOfCourses} Courses{" "} */}
            </a>
            <button
              className="btn btn-warning"
              onClick={() => setShowDetails(true)}
            >
              Details
            </button>
          </div>
        </div>
      </div>
      {showDetails && (
        <InstructorPopup
          setShowDetails={setShowDetails}
          instructor={instructor}
        />
      )}
    </>
  );
};

export default InstructorCard;
