import React, { useState } from "react";
import SideBar from "components/SideBar";
import Footer from "components/Footer";
import Authentication from "services/Authentication";
import AddressDetails from "./components/AddressDetails";
import PersonalDetails from "./components/PersonalDetails";
import getGravatarImg from "utils/getGravatarImg";
import ImageUpload from "components/ImageUpload";
import putProfilePicture from "services/user/putProfilePicture";
import successNotification from "utils/successNotification";
import dangerNotification from "utils/dangerNotification";
import useStates from "effects/useStates";

const auth = new Authentication();

const AccountSettingsEdit = ({ forceUpdate }) => {
  const jwtPayload = auth.getAccessTokenPayload();
  const { image, setImage } = useStates({
    image: null,
  });
  const [loading, setLoading] = useState(false);
  // const toolbarOffset = useRef(0);

  // const cumulativeOffset = function (element) {
  //   let top = 0;
  //   let left = 0;
  //   do {
  //     top += element.offsetTop || 0;
  //     left += element.offsetLeft || 0;
  //     element = element.offsetParent;
  //   } while (element);

  //   return {
  //     top: top,
  //     left: left,
  //   };
  // };
  // const calculateOffset = () => {
  //   const toolbarRef = document.getElementsByClassName("ck-toolbar__items")[0];
  //   toolbarOffset.current = cumulativeOffset(toolbarRef).top;
  // };

  // useEffect(calculateOffset, []);

  const handleSavePhoto = async () => {
    setLoading(true);
    await putProfilePicture({
      image,
    })
      .then(() => {
        setLoading(false);
        setImage(image);
        successNotification("Saved");
        forceUpdate();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        dangerNotification("Something went wrong!");
      });
  };

  return (
    <>
      <SideBar />
      <div className="page-content">
        <div className="page-content-inner">
          <h2>Account Settings</h2>

          {/* <div class="section-header mb-lg-2 border-0 uk-flex-middle">
            <div class="section-header-left">
              <h2 class="uk-heading-line text-left">
                <span> Account Setting </span>
              </h2>
            </div>
            <div class="section-header-right">
              <a href="#" class="btn btn-default uk-visible@s">
                {" "}
                <i class="uil-plus"></i> Save Changes
              </a>
            </div>
          </div> */}

          <nav className="responsive-tab mb-4">
            <li className="uk-active">
              <a href="#">Account</a>
            </li>
            {/* <li>
              <a href="#">Billing</a>
            </li> */}
            <li>
              <a href="#">Settings</a>
            </li>
          </nav>
          {/* <Link to="#" class="btn btn-default uk-visible@s">
            {" "}
            <i class="uil-plus"></i> Save Changes
          </Link> */}

          <div data-uk-grid>
            <div className="uk-width-2-5@m uk-flex-first@m">
              <div className="uk-card-default rounded text-center p-4">
                <div className="uk-position-relative my-4">
                  {jwtPayload.image ? (
                    <div className="user-profile-photo  m-auto">
                      <img src={jwtPayload.image} alt="profile" />
                    </div>
                  ) : (
                    <div className="user-profile-photo  m-auto">
                      <img
                        src={getGravatarImg(
                          jwtPayload.firstName,
                          jwtPayload.lastName
                        )}
                        alt="profile"
                      />
                    </div>
                  )}

                  <div className="uk-position-center">
                    <div data-uk-form-custom>
                      <ImageUpload
                        image={image}
                        type="profile"
                        onChange={(img) => {
                          setImage(img);
                          setTimeout(() => {}, 1000);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <button
                  disabled={loading && image !== ""}
                  className="btn btn-default m-auto mb-3"
                  onClick={() => {
                    handleSavePhoto();
                    setImage("");
                  }}
                >
                  {loading ? "Saving..." : "Save photo"}
                </button>
              </div>
            </div>
            <div className="uk-width-expand@m">
              <PersonalDetails />
              <AddressDetails />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AccountSettingsEdit;
