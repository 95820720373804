import React, { useState } from "react";
import { useParams } from "react-router";
import useFetchData from "effects/useFetchData";
import getActiveModules from "services/getActiveModules";
import postPaypointPayment from "services/payment/postPaypointPayment";
import dangerNotification from "utils/dangerNotification";
import currencyCodes from "constants/currencyCodes";

const CoursePaymentFull = () => {
  const { REACT_APP_PAYPOINT_BASE_URL: PAYPOINT_API_BASE_URL } = process.env;
  const { cohortId } = useParams();
  const [paymentDetailsReceived, setPaymentDetailsReceived] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [loading, error, getModulesResponse] = useFetchData(() =>
    getActiveModules()
  );
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Something went wrong</div>;
  }

  const cohort = getModulesResponse.activeModules.find((item) => {
    return Number(item.cohortId) === Number(cohortId);
  });

  const program = cohort.programs[0];

  const handlePayment = (event) => {
    event.preventDefault();
    setIsLoading(true);
    postPaypointPayment({
      amount: program.price * 100,
      currency: currencyCodes[`${program.currency}`],
      description: program.programName,
      merchantField: "teaching platform card",
    })
      .then((response) => {
        setIsLoading(false);
        setPaymentDetailsReceived(true);
        setPaymentDetails(response.data);

        setTimeout(() => {
          document.forms["initiate-payment"].submit();
        }, 300);
      })
      .catch((err) => {
        setIsLoading(false);
        dangerNotification("Please login and try again.");
      });
  };
  const {
    merchantCode,
    merchantUser,
    merchantTransactionId,
    trnKey,
    amount,
    currency,
    description,
    lang,
  } = paymentDetails;

  return (
    <div class="page-content">
      {paymentDetailsReceived && (
        <div style={{ display: "none" }}>
          <form
            id="initiate-payment"
            method="POST"
            action={PAYPOINT_API_BASE_URL}
          >
            <input type="hidden" name="MerchantCode" value={merchantCode} />
            <input type="hidden" name="MerchantUser" value={merchantUser} />
            <input
              type="hidden"
              name="MerchantTrn"
              value={merchantTransactionId}
            />
            <input type="hidden" name="TrnKey" value={trnKey} />
            <input type="hidden" name="Amount" value={amount} />
            <input type="hidden" name="Currency" value={currency} />
            <input type="hidden" name="Description" value={description} />
            <input type="hidden" name="Lang" value={lang} />
          </form>
        </div>
      )}
      <div class="bg-pricing"></div>

      <div class="page-content-inner">
        <div class="pricing pricing-container">
          <div class="uk-light text-center">
            <h1 style={{ color: "#3e416d" }}> {program.programName}</h1>
            <h5 style={{ color: "#3e416d" }}>
              {" "}
              This program contains {program.modules.length}
              {program.modules.length <= 1 ? " module" : " modules"}
            </h5>
          </div>

          <div class="pricing-card" style={{ fontVariant: "all-petite-caps" }}>
            <div class="text-center" style={{ marginLeft: "60px" }}>
              <h1 class="price ">
                {" "}
                {program.price}
                <span> {program.currency}</span>
              </h1>
              <span class="price-type">lifetime</span>
              <button
                href="#"
                className="btn btn-success  btn-small mt-3"
                onClick={handlePayment}
                disabled={isLoading}
              >
                {isLoading ? "Redirecting..." : "PAY NOW"}
              </button>
            </div>
            <div class="pt-sm-0">
              <ul class="list-2 ml-3 pl-3 mt-3">
                <h3>Modules</h3>

                {program.modules.map((module) => {
                  return (
                    <>
                      <li>{module.title}</li>
                    </>
                  );
                })}
              </ul>
            </div>
          </div>

          <h6 style={{ color: "#F24A72" }}>
            Notice: this pack will include all the module of the class at{" "}
            {program.price} {program.currency} payed once.
          </h6>
        </div>
      </div>
    </div>
  );
};

export default CoursePaymentFull;
