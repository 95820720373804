import http from "utils/http";
import { students } from "constants/endpoints";

const getStudentActivity = ({ userId }) => {
  const ENDPOINT = students.getStudentActivity({ userId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${ENDPOINT}`);
};

export default getStudentActivity;
