import http from "utils/http";
import { payments } from "constants/endpoints";

const postPaypointPayment = ({
  amount,
  currency,
  description,
  merchantField,
  lang,
}) => {
  const ENDPOINT = payments.postPaypointPayment();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${ENDPOINT}`, {
    data: { amount, currency, description, merchantField, lang },
  });
};

export default postPaypointPayment;
