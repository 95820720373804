import React from "react";
import formatDateRelativeOrAbsolute from "utils/formatDateRelativeOrAbsolute";

const DocumentStatus = ({ isDirty, lastSavedAt }) => {
  if (isDirty && lastSavedAt) {
    return (
      <span className="text-danger">
        You have unsaved changes. Last saved{" "}
        {formatDateRelativeOrAbsolute(lastSavedAt)}...
      </span>
    );
  }

  if (isDirty) {
    return <span className="text-danger">You have unsaved changes.</span>;
  }

  return <span className="text-success">All changes are saved.</span>;
};

export default DocumentStatus;
