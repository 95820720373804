import http from "utils/http";
import { blog } from "constants/endpoints";

const postSubscription = ({ email, channel = "teaching-platform" }) => {
  const ENDPOINT = blog.postSubscription();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${ENDPOINT}`, {
    data: { email, channel },
  });
};

export default postSubscription;
