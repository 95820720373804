import React, { useState } from "react";
import ChapterView from "./components/ChapterView.js";
import PageContentView from "./components/PageContentView";
import getModule from "services/getModule";
import getPage from "services/page/getPage";
import postPageView from "services/page/postPageView";
import useFetchData from "effects/useFetchData";
import flattenDeep from "utils/flattenDeep";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import Feedback from "components/Feedback";
import { chapter } from "constants/endpoints.js";
import EditorModalLesson from "components/EditorModalLesson";
import RenderIfRole from "components/RenderIfRole";
import userTypes from "constants/userTypes";

const LessonPage = ({ nightMode }) => {
  const [isOpen, setOpen] = useState(false);
  let { pageId, chapterId, moduleId } = useParams();
  let history = useHistory();

  const [getModuleLoading, getModuleError, getModuleResponse, refreshModule] =
    useFetchData(() => getModule({ moduleId }));

  const [getPageLoading, getPageError, getPageResponse, refreshPage] =
    useFetchData(() => getPage({ pageId }), [pageId]);

  useFetchData(() => postPageView({ pageId, moduleId }), [pageId, moduleId]);

  if (getModuleLoading || getPageLoading) {
    return <div>Loading...</div>;
  }

  if (getModuleError || getPageError) {
    return <div>Something went wrong</div>;
  }

  const { page } = getPageResponse;
  const { courseModule } = getModuleResponse;
  const allPages = flattenDeep(
    courseModule.chapters.map((chapter) =>
      chapter.pages.map((page) => {
        return { ...page, chapterId: chapter.chapterId, chapter };
      })
    )
  );
  const activePage = allPages.find((p) => Number(p.pageId) === Number(pageId));
  const activePageIndex = allPages.findIndex(
    (p) => Number(p.pageId) === Number(pageId)
  );
  const nextPageIndex = activePageIndex + 1;
  const nextPage =
    allPages[activePageIndex > allPages.length ? null : nextPageIndex];
  const previousPageIndex = activePageIndex - 1;
  const previousPage =
    allPages[previousPageIndex < 0 ? null : previousPageIndex];

  return (
    <>
      {isOpen && (
        <EditorModalLesson
          setOpen={setOpen}
          pageId={pageId}
          page={{ ...page, title: activePage.title }}
          nightMode={nightMode}
          refreshPage={refreshPage}
          refreshModule={refreshModule}
        />
      )}
      {/* <!-- for mobile  --> */}
      <div className="uk-card-default bg-gradient-grey p-4 uk-light uk-hidden@s">
        <div className="uk-flex uk-flex-between uk-flex-middle">
          <h4 className="mb-0">{courseModule.moduleTitle}</h4>
          <i
            className="icon-feather-video icon-small"
            data-uk-toggle="target: #open-side-course"
          ></i>
        </div>
      </div>

      <div
        className="section-small bg-white uk-visible@s"
        style={{ paddingBottom: "10px" }}
      >
        <div className="uk-position-center-left uk-margin-medium-left">
          <button
            type="button"
            className="btn btn-primary rounded-circle btn-icon-only icon-xsmall"
            data-uk-toggle="target: #open-side-course"
          >
            <span className="btn-inner--icon">
              <i className="uil-list-ul"></i>
            </span>
          </button>
        </div>

        <div className="uk-container-small uk-margin-auto">
          <div className="uk-width-2-3@m">
            <span className="uk-text-bold uk-h4">
              {" "}
              {courseModule.moduleTitle}: {activePage.chapter.title}
            </span>
            <br />
            <h1 className="uk-text-light">{activePage.title}</h1>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <RenderIfRole role={userTypes.ADMIN}>
                <button
                  type="button"
                  className="btn btn-info icon-label "
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <span className="inner-text"> Edit Page </span>
                  <span className="inner-icon">
                    <i class="icon-feather-edit"></i>
                  </span>
                </button>
              </RenderIfRole>
            </div>

            <br />
            <a
              href=""
              className="uk-text-muted"
              onClick={() => {
                history.push(`/module/${moduleId}`);
              }}
            >
              <i className="icon-line-awesome-long-arrow-left"></i> back to
              module
            </a>
          </div>
        </div>
      </div>
      <div className="uk-container-small uk-padding-small uk-margin-auto">
        <ul
          id="video-slider"
          className="uk-switcher uk-position-relative uk-margin-medium-bottom"
        >
          <PageContentView
            page={page}
            pageId={pageId}
            chapterId={chapterId}
            moduleId={moduleId}
            nextPage={nextPage}
            previousPage={previousPage}
          />
        </ul>
        <Feedback pageId={pageId} moduleId={moduleId} />
      </div>

      <div id="open-side-course" data-uk-offcanvas="overlay: true">
        <div className="uk-offcanvas-bar p-0 uk-overflow-hidden">
          <button
            className="uk-offcanvas-close"
            type="button"
            data-uk-close
          ></button>
          <h4 className="bg-gradient-grey text-white py-4 p-3 mb-0">
            {" "}
            {courseModule.moduleTitle}
          </h4>

          <ul
            className="uk-child-width-expand mb-0"
            data-uk-switcher="animation: uk-animation-slide-right-small, uk-animation-slide-left-small"
            data-uk-tab
          >
            <li className="uk-active">
              <a href="#"> Contents</a>
            </li>
            <li>
              <a href="#"> Details</a>
            </li>
          </ul>

          <ul className="uk-switcher uk-overflow-hidden">
            <li>
              <div
                className={
                  chapter.progress === 100
                    ? "vidlist-4-content"
                    : "vidlist-3-content"
                }
                data-simplebar
              >
                <ul
                  className={
                    chapter.progress === 100
                      ? "vidlist-4-section"
                      : "vidlist-3-section"
                  }
                  data-uk-accordion
                >
                  {courseModule.chapters.map((chapter) => {
                    return (
                      <ChapterView
                        chapter={chapter}
                        activePage={activePage}
                        moduleId={moduleId}
                        allPages={allPages}
                      />
                    );
                  })}
                </ul>
              </div>
            </li>

            <li>
              <img src="../assets/images/course/8.png" alt="" />
              <div className="p-3">
                <h6> Description</h6>
                <p>{courseModule.moduleDescription}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default LessonPage;
