import React, { useState } from "react";
import useFetchData from "effects/useFetchData";
import { useParams } from "react-router-dom";
import getBlog from "services/Blog/getBlog";
import { Link } from "react-router-dom";
import MarkdownWrapper from "components/MarkdownWrapper";
import getBlogComments from "services/Blog/getBlogComments";
import postBlogComment from "services/Blog/postBlogComment";
import postBlogCommentReply from "services/Blog/postBlogCommentReply";
import SignedIn from "components/SignedIn";
import SignedOut from "components/SignedOut";
import Authentication from "services/Authentication";
import formatDateRelativeOrAbsolute from "utils/formatDateRelativeOrAbsolute";
import getGravatarImg from "utils/getGravatarImg";
import { useLocation } from "react-router-dom";

const auth = new Authentication();

const BlogPage = () => {
  const location = useLocation();

  const { slug } = useParams();
  const [commentContent, setCommentContent] = useState("");
  const [commentReplyContent, setReplyContent] = useState("");
  const [activeThread, setActiveThread] = useState("");
  const [loading, error, apiResponse] = useFetchData(
    () => getBlog({ slug }),
    [slug]
  );
  const { blog } = apiResponse;
  const jwtPayload = auth.getAccessTokenPayload();

  const [loadingComment, errorComment, apiResponseComment, refreshComments] =
    useFetchData(
      () => getBlogComments({ blogId: blog?.blogId }),
      [blog?.blogId]
    );
  const { blogComments } = apiResponseComment;

  const handleCommentReply = (commentId) => {
    postBlogCommentReply({
      commentId,
      commentReplyContent,
    })
      .then(() => {
        refreshComments();
        setReplyContent("");
      })
      .catch((err) => console.log(err, "nope"));
  };

  const handlePostComment = () => {
    postBlogComment({
      blogId: blog?.blogId,
      commentContent,
    })
      .then(() => {
        refreshComments();
        setCommentContent("");
      })
      .catch((err) => console.log(err, "nope"));
  };

  if (loading || loadingComment) {
    return <div>Loading...</div>;
  }

  if (error || errorComment) {
    return <div>Something went wrong</div>;
  }

  return (
    <>
      <div
        className="blog-article-single mt-9"
        data-src={blog.image}
        data-uk-img={""}
      >
        <div className="container-small">
          <p className="blog-article-meta mb-3">
            <strong>{blog.title}</strong>
            <a href="#">
              {" "}
              {blog.firstName} {blog.lastName}{" "}
            </a>
          </p>

          <h1>{blog.description}</h1>

          <div className="blog-article-auther">
            {jwtPayload.image ? (
              <div className="user-profile-photo  m-auto">
                <img src={jwtPayload.image} alt="profile" />
              </div>
            ) : (
              <div className="user-profile-photo  m-auto">
                <img
                  src={getGravatarImg(
                    jwtPayload.firstName,
                    jwtPayload.lastName
                  )}
                  alt="profile"
                />
              </div>
            )}
            <span className="blog-auther-user-name">
              {" "}
              {blog.firstName} {blog.lastName}{" "}
            </span>
          </div>
        </div>
      </div>
      {/* 
        <!-- Content
        ================================================== --> */}
      <div className="container p-0">
        {/* <!-- Blog Post Content --> */}

        <div className="container-small blog-article-content-read">
          <MarkdownWrapper content={blog.blogContent} />

          <div className="my-lg-7">
            <h4>Author </h4>
            <div
              className="uk-card-default rounded px-3 pb-md-3 uk-flex uk-flex-between@m uk-flex-middle"
              uk-toggle="cls: uk-flex uk-flex-between@m uk-flex-middle; mode: media; media: @m"
            >
              <div className="user-details-card">
                <div className="user-details-card-avatar">
                  <img src="../assets/images/avatars/avatar-2.jpg" alt="" />
                </div>
                <div className="user-details-card-name">
                  {blog.firstName + " " + blog.lastName}
                  <span>
                    Developer
                    {Boolean(blog.publishedAt) && (
                      <span>
                        {" "}
                        {formatDateRelativeOrAbsolute(blog.publishedAt)}{" "}
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <div>
                <strong className="mx-3 uk-visible@s"> Share on </strong>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=platform.cypruscodes.com${location.pathname}`}
                  className="btn btn-facebook  rounded-circle btn-icon-only transition-3d-hover"
                  target="blank"
                  style={{ marginRight: 5 }}
                >
                  <span className="btn-inner--icon">
                    <i className="icon-brand-facebook-f"></i>
                  </span>
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?text=platform.cypruscodes.com${location.pathname}`}
                  target="blank"
                  className="btn btn-twitter rounded-circle btn-icon-only transition-3d-hover"
                >
                  <span className="btn-inner--icon">
                    <i className="icon-brand-twitter"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>

          <div className="comments mt-4">
            <h4>
              Comments
              <span className="comments-amount">{blogComments.length}</span>
            </h4>
            {blogComments.map((comment) => {
              return (
                <ul>
                  <li>
                    <div className="comments-avatar">
                      <img src="../assets/images/avatars/avatar-2.jpg" alt="" />
                    </div>
                    <div className="comment-content">
                      <div className="comment-by">
                        {comment.firstName} {comment.lastName}
                        <span>
                          Student -{" "}
                          {formatDateRelativeOrAbsolute(comment.createdAt)}{" "}
                        </span>
                        <a
                          className="reply"
                          onClick={() => {
                            setActiveThread(comment.commentId);
                          }}
                        >
                          <i className="icon-line-awesome-undo"></i>
                          Reply
                        </a>
                      </div>
                      <p> {comment.commentContent}</p>
                    </div>
                    <ul>
                      {comment.blogCommentReplies.map((reply) => {
                        return (
                          <li>
                            <div className="comments-avatar">
                              <img
                                src="../assets/images/avatars/avatar-2.jpg"
                                alt=""
                              />
                            </div>
                            <div className="comment-content">
                              <div className="comment-by">
                                {reply.firstName} {reply.lastName}
                                <span>
                                  Student -{" "}
                                  {formatDateRelativeOrAbsolute(
                                    reply.createdAt
                                  )}{" "}
                                </span>
                                <a
                                  className="reply"
                                  onClick={() => {
                                    setActiveThread(comment.commentId);
                                  }}
                                >
                                  <i className="icon-line-awesome-undo"></i>
                                  Reply
                                </a>
                              </div>
                              <p>{reply.commentReply}</p>
                            </div>
                          </li>
                        );
                      })}
                      {comment.commentId === activeThread && (
                        <div className="comment-content">
                          <form className="uk-grid-small" data-uk-grid>
                            <div className="uk-width-1-1@s">
                              <textarea
                                className="uk-textarea"
                                placeholder="Write a reply..."
                                style={{ height: "75px", minHeight: "40px" }}
                                onChange={(e) =>
                                  setReplyContent(e.target.value)
                                }
                                value={commentReplyContent}
                              ></textarea>
                            </div>
                            <div className="uk-grid-margin">
                              <SignedIn>
                                <input
                                  type="submit"
                                  value="Reply"
                                  className="button success"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    handleCommentReply(comment.commentId);
                                  }}
                                />
                              </SignedIn>
                              <SignedOut>
                                <p>
                                  Please <Link to="/login">login </Link>or{" "}
                                  <Link to="/register">sign up</Link> to write a
                                  reply...
                                </p>
                              </SignedOut>
                            </div>
                          </form>
                        </div>
                      )}
                    </ul>
                  </li>
                </ul>
              );
            })}
          </div>
          <div className="comments">
            <h4>Your Comment </h4>
            <ul>
              <li>
                <div className="comments-avatar">
                  <img src="../assets/images/avatars/avatar-2.jpg" alt="" />
                </div>
                <div className="comment-content">
                  <form className="uk-grid-small" data-uk-grid>
                    <div className="uk-width-1-1@s">
                      <label className="uk-form-label">
                        Tell us what you think {jwtPayload.firstName}
                      </label>
                      <textarea
                        className="uk-textarea"
                        placeholder="Write a comment..."
                        style={{ height: "160px" }}
                        onChange={(e) => setCommentContent(e.target.value)}
                        value={commentContent}
                      ></textarea>
                    </div>
                    <div className="uk-grid-margin">
                      <SignedIn>
                        <input
                          type="submit"
                          value="submit"
                          className="button success"
                          onClick={(event) => {
                            event.preventDefault();
                            handlePostComment();
                          }}
                        />
                      </SignedIn>
                      <SignedOut>
                        <p>
                          Please <Link to="/login">login </Link> or{" "}
                          <Link to="/register">sign up</Link> to write a
                          comment...
                        </p>
                      </SignedOut>
                    </div>
                  </form>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPage;
