import React, { useState } from "react";
import FullscreenModal from "components/FullScreenModal";
import RenderPeriodically from "components/RenderPeriodically";
import DocumentStatus from "../EditorModal/components/DocumentStatus";
import EditableLine from "../EditorModal/components/EditableLine";
import styled from "styled-components";
import useStates from "effects/useStates";
import successNotification from "utils/successNotification";
import MDEditor from "@uiw/react-md-editor";
import putPage from "services/page/putPage";
const BlogHeaderWrapper = styled.div`
  display: flex;
`;

const EditorModalLesson = ({
  setOpen,
  page = {},
  pageId,
  nightMode,
  refreshPage,
  refreshModule,
}) => {
  const { title, setTitle, content, setContent } = useStates({
    title: page.title ?? "",
    content: page.pageContent ?? "",
  });

  const [isDirty, setDirty] = useState(false);
  const [lastSavedAt, setLastSavedAt] = useState(null);

  return (
    <FullscreenModal
      closeModal={() => {
        setOpen(false);
      }}
    >
      <div className="ro" data-uk-grid>
        <div className="uk-width-expand@m">
          <BlogHeaderWrapper>
            <div style={{ flex: 1, color: nightMode ? "white" : "black" }}>
              <EditableLine
                text={title}
                onChange={setTitle}
                placeholder="Title"
              />
            </div>
          </BlogHeaderWrapper>
          <div className="mb-2 mt-1">
            <small>
              <RenderPeriodically refreshMs={5000}>
                <DocumentStatus isDirty={isDirty} lastSavedAt={lastSavedAt} />
              </RenderPeriodically>
            </small>
          </div>
          <div>
            <MDEditor
              height={"70vh"}
              value={content}
              onChange={(content) => {
                setContent(content);
                setDirty(true);
              }}
            />
          </div>
          <div className="editor-button-actions-wrapper">
            <div className="uk-flex uk-flex-right p-4">
              <button
                class="btn btn-default"
                onClick={() => {
                  setDirty(false);
                  setLastSavedAt(new Date().toISOString());
                  const payload = {
                    pageId,
                    pageContent: content,
                    title,
                  };

                  putPage(payload)
                    .then((response) => {
                      successNotification("Page updated.");
                      refreshPage();
                      refreshModule();
                    })
                    .catch(() => {
                      successNotification(
                        "Something went wrong. Please try again."
                      );
                    });
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </FullscreenModal>
  );
};

export default EditorModalLesson;
