import http from "utils/http";
import { payments } from "constants/endpoints";

const getStudentPaymentHistory = ({ userId }) => {
  const ENDPOINT = payments.getStudentPaymentHistory({ userId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${ENDPOINT}`);
};

export default getStudentPaymentHistory;
