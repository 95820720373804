import React, { useState } from "react";
import { useParams, useHistory } from "react-router";
import useFetchData from "effects/useFetchData";

import getActiveModules from "services/getActiveModules";

const Course = () => {
  const [status, setStatus] = useState(1);
  const history = useHistory();

  const { cohortId } = useParams();
  const [loading, error, getModulesResponse] = useFetchData(() =>
    getActiveModules()
  );
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Something went wrong</div>;
  }

  const program = getModulesResponse.activeModules.find((item) => {
    return Number(item.cohortId) === Number(cohortId);
  });

  const programs = program.programs[0];

  const radioHandler = (status) => {
    setStatus(status);
  };
  return (
    <div id="wrapper">
      <div className="page-content">
        <div className="course-details-wrapper topic-1 uk-light">
          <div className="container p-sm-0 top-card-course">
            <div className="uk-grid-large mt-4" data-uk-grid>
              <div>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <div className="course-details">
                    <h1> {programs.programName}</h1>
                    <p>
                      {" "}
                      Course Duration: {programs.durationHours} hours&{" "}
                      {programs.durationWeeks} weeks
                    </p>

                    <li>
                      {" "}
                      <i className="icon-line-awesome-book"></i> This program
                      contains {programs.modules.length}
                      {programs.modules.length <= 1 ? " module" : " modules"}
                    </li>
                    <div className="course-details-info mt-4">
                      <ul>
                        <li>
                          <div className="star-rating">
                            <span className="avg"> 4.9 </span>{" "}
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="course-details-info">
                      <ul>
                        <li>
                          {" "}
                          Created by <a href="#"> The Akdemy Team </a>{" "}
                        </li>
                        <li> Last updated {program.startDate}</li>
                      </ul>
                    </div>
                    <div
                      className="course-card-trailer top-course-card-trailer"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div className="course-thumbnail">
                        <img src={programs.programImage} alt="" />
                      </div>
                      <div
                        className="uk-grid-small uk-child-width-auto uk-grid p-3"
                        style={{ flexDirection: "column" }}
                      >
                        <h4 style={{ marginBottom: "10px", color: "#3e416d" }}>
                          Choose Category
                        </h4>
                        <h6
                          style={{
                            color: "#3e416d",
                            marginBottom: "10px",
                            cursor: "pointer",
                          }}
                          checked={status === 1}
                          onClick={(e) => radioHandler(1)}
                        >
                          <input
                            class="uk-radio"
                            type="radio"
                            name="radio2"
                            checked={status === 1}
                            onClick={(e) => radioHandler(1)}
                          />{" "}
                          Pay Full Class ({programs.price} {programs.currency} )
                        </h6>
                        <h6
                          style={{ color: "#3e416d", cursor: "pointer" }}
                          checked={status === 2}
                          onClick={(e) => radioHandler(2)}
                        >
                          <input
                            class="uk-radio"
                            type="radio"
                            name="radio2"
                            checked={status === 2}
                            onClick={(e) => radioHandler(2)}
                          />{" "}
                          Pay Monthly ({programs.monthlyTotalPrice}{" "}
                          {programs.currency})
                        </h6>
                      </div>

                      <div className="p-3">
                        <div
                          className="uk-child-width-1 uk-grid-small"
                          data-uk-grid
                        >
                          <div>
                            {status === 1 && (
                              <a
                                href=""
                                onClick={() => {
                                  history.push(
                                    `/course-payment-full/${program.cohortId}`
                                  );
                                }}
                                className="uk-width-1-1 btn btn-success transition-3d-hover"
                              >
                                <i className="uil-graduation-hat"></i> Enroll
                                Full Class
                              </a>
                            )}
                            {status === 2 && (
                              <a
                                href=""
                                onClick={() => {
                                  history.push(
                                    `/course-payment-module/${program.cohortId}`
                                  );
                                }}
                                className="uk-width-1-1 btn btn-success transition-3d-hover"
                              >
                                <i className="uil-graduation-hat"></i> Enroll
                                Monthly
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <nav className="responsive-tab style-5">
                  <ul uk-switcher="connect: #course-intro-tab ;animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium">
                    <li>
                      <a href="#">Overview</a>
                    </li>
                    <li>
                      <a href="#">Chapters</a>
                    </li>
                  </ul>
                </nav>
              </div>

              <div
                className="uk-width-1-3@m"
                style={{
                  zIndex: 3,
                }}
              >
                <div
                  className="course-card-trailer bottom-course-card-trailer"
                  data-uk-sticky="offset: 100px;"
                  id="sticky-continue"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    right: "70px",
                  }}
                >
                  <div className="course-thumbnail">
                    <img src={programs.programImage} alt="" />
                  </div>
                  <div
                    className="uk-grid-small uk-child-width-auto uk-grid p-3"
                    style={{ flexDirection: "column" }}
                  >
                    <h4 style={{ marginBottom: "10px", color: "#3e416d" }}>
                      Choose Category
                    </h4>
                    <h6
                      style={{
                        color: "#3e416d",
                        marginBottom: "10px",
                        cursor: "pointer",
                      }}
                      checked={status === 1}
                      onClick={(e) => radioHandler(1)}
                    >
                      <input
                        class="uk-radio"
                        type="radio"
                        name="radio2"
                        checked={status === 1}
                        onClick={(e) => radioHandler(1)}
                      />{" "}
                      Pay Full Class ({programs.price} {programs.currency} )
                    </h6>
                    <h6
                      style={{ color: "#3e416d", cursor: "pointer" }}
                      checked={status === 2}
                      onClick={(e) => radioHandler(2)}
                    >
                      <input
                        class="uk-radio"
                        type="radio"
                        name="radio2"
                        checked={status === 2}
                        onClick={(e) => radioHandler(2)}
                      />{" "}
                      Pay Monthly ({programs.monthlyTotalPrice}{" "}
                      {programs.currency})
                    </h6>
                  </div>

                  <div className="p-3">
                    <div
                      className="uk-child-width-1 uk-grid-small mb-4"
                      data-uk-grid
                    >
                      <div>
                        {status === 1 && (
                          <a
                            href=""
                            onClick={() => {
                              history.push(
                                `/course-payment-full/${program.cohortId}`
                              );
                            }}
                            className="uk-width-1-1 btn btn-success transition-3d-hover"
                          >
                            <i className="uil-graduation-hat"></i> Enroll Full
                            Class
                          </a>
                        )}
                        {status === 2 && (
                          <a
                            href=""
                            onClick={() => {
                              history.push(
                                `/course-payment-module/${program.cohortId}`
                              );
                            }}
                            className="uk-width-1-1 btn btn-success transition-3d-hover"
                          >
                            <i className="uil-graduation-hat"></i> Enroll
                            Monthly
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="uk-grid-large mt-4" uk-grid>
            <div className="uk-width-2-3@m">
              <ul id="course-intro-tab" className="uk-switcher mt-4">
                <li className="course-description-content">
                  {programs.modules.map((module) => {
                    return (
                      <p>
                        <h4 style={{ color: "#3659a2" }}> {module.title}</h4>

                        <div
                          className="review-summary-container"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="review-summary-avg">
                            <img src={module.image} />
                          </div>
                        </div>
                        {/* <ul className="list-2">
                          <li>
                            Module Price:{" "}
                            {module.modulePrice + " " + module.moduleCurrency}{" "}
                          </li>
                        </ul> */}
                        {module.description}
                      </p>
                    );
                  })}
                </li>
                <li>
                  <ul
                    className="course-curriculum"
                    uk-accordion="multiple: true"
                  >
                    <li className="uk-open">
                      <a className="uk-accordion-title" href="#">
                        {" "}
                        Html Introduction{" "}
                      </a>
                      <div className="uk-accordion-content">
                        <ul className="course-curriculum-list">
                          <li>
                            What is HTML <span> 23 min </span>
                          </li>
                          <li>
                            What is a Web page? <span> 23 min </span>
                          </li>
                          <li>
                            Your First Web Page
                            <a href="#trailer-modal" uk-toggle>
                              Preview
                            </a>
                            <span> 23 min </span>
                          </li>
                          <li>
                            Brain Streak <span> 23 min </span>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <a className="uk-accordion-title" href="#">
                        Your First webpage
                      </a>
                      <div className="uk-accordion-content">
                        <ul className="course-curriculum-list">
                          <li>
                            {" "}
                            Headings <span> 23 min </span>
                          </li>
                          <li>
                            Paragraphs <span> 23 min </span>
                          </li>
                          <li>
                            Emphasis and Strong Tag
                            <a href="#trailer-modal" uk-toggle>
                              Preview
                            </a>
                            <span> 23 min</span>
                          </li>
                          <li>
                            {" "}
                            Brain Streak <span> 23 min </span>{" "}
                          </li>
                          <li>
                            {" "}
                            Live Preview Feature <span> 23 min </span>{" "}
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <a className="uk-accordion-title" href="#">
                        {" "}
                        Some Special Tags{" "}
                      </a>
                      <div className="uk-accordion-content">
                        <ul className="course-curriculum-list">
                          <li>
                            {" "}
                            The paragraph tag <span> 23 min </span>{" "}
                          </li>
                          <li>
                            {" "}
                            The break tag{" "}
                            <a href="#trailer-modal" uk-toggle>
                              {" "}
                              Preview{" "}
                            </a>
                            <span> 23 min </span>{" "}
                          </li>
                          <li>
                            {" "}
                            Headings in HTML <span> 23 min </span>{" "}
                          </li>
                          <li>
                            {" "}
                            Bold, Italics Underline <span> 23 min </span>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <a className="uk-accordion-title" href="#">
                        {" "}
                        HTML Advanced level{" "}
                      </a>
                      <div className="uk-accordion-content">
                        <ul className="course-curriculum-list">
                          <li>
                            {" "}
                            Something to Ponder<span> 23 min </span>{" "}
                          </li>
                          <li>
                            {" "}
                            Tables <span> 23 min </span>{" "}
                          </li>
                          <li>
                            {" "}
                            HTML Entities{" "}
                            <a href="#trailer-modal" uk-toggle>
                              {" "}
                              Preview
                            </a>
                            <span> 23 min </span>{" "}
                          </li>
                          <li>
                            {" "}
                            HTML Iframes <span> 23 min </span>{" "}
                          </li>
                          <li>
                            {" "}
                            Some important things <span> 23 min </span>{" "}
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
