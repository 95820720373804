import http from "utils/http";
import { blog } from "constants/endpoints";


const postBlogCommentReply = ({ commentId, commentReplyContent }) => {
    const ENDPOINT = blog.postBlogCommentReply({ commentId });
    const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
    return http.post(`${API_BASE_URL}${ENDPOINT}`, {
        data: {
            commentReply:commentReplyContent
        },
    });
};

export default postBlogCommentReply;