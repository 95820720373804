import { useState } from "react";
const camelize = s => s.charAt(0).toUpperCase() + s.substring(1);

// { progress: 0, file: null } => {progress, setProgress, file, useFile}
const useStates = initialState => {
  return Object.entries(initialState)
    .sort((a, b) => b[0].localeCompare(a[0]))
    .map(([k, v]) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [initialValue, setValue] = useState(v);
      const setStateName = `set${camelize(k)}`;
      return {
        [k]: initialValue,
        [setStateName]: setValue
      };
    })
    .reduce((acc, el) => {
      return {
        ...acc,
        ...el
      };
    }, {});
};

export default useStates;
