import http from "utils/http";
import { page } from "constants/endpoints";

const deletePageComplete = ({pageProgressId }) => {
  const DELETE_PAGECOMPLETE_ENDPOINT = page.deleteComplete({ pageProgressId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.delete(`${API_BASE_URL}${DELETE_PAGECOMPLETE_ENDPOINT}`);
};

export default deletePageComplete;


