import { baseRequestWithFormData } from "utils/http/baseRequest";
import { assets } from "constants/endpoints";

const uploadFile = ({ file }) => {
  const endpoint = assets.post();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  const formData = new FormData();
  formData.append("upload", file);
  return baseRequestWithFormData(`${API_BASE_URL}${endpoint}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    method: "post",
  });
};

export default uploadFile;
