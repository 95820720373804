import React from "react";
import styled from "styled-components";
import EditableDiv from "components/EditableDiv";

const Wrapper = styled.div`
  display: flex;
`;

const EditableLine = (
  { fontSize, text, onChange, placeholder } = {
    fontSize: "12px",
  }
) => {
  return (
    <Wrapper>
      <div>
        <i className="icon-feather-edit-3 icon-small"></i>
      </div>
      <EditableDiv
        html={text}
        onChange={onChange}
        singleLine={true}
        style={{
          fontSize,
          minWidth: "100px",
          background: "transparent",
          marginLeft: "10px",
          marginBottom: "10px",
        }}
        placeholder={placeholder}
      />
    </Wrapper>
  );
};

export default EditableLine;
