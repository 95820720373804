import React from "react";
import InProgressModuleCard from "components/InProgressModuleCard";

const CourseList = ({ studentModules }) => {
  return (
    <>
      <div className="section-small">
        <h4 className="mb-lg-5 text-blue">In Progress</h4>
        <div
          className="uk-child-width-1-4@m uk-child-width-1-3@s course-card-grid"
          data-uk-grid
        >
          {studentModules.map((module) => (
            <InProgressModuleCard
              moduleId={module.moduleId}
              title={module.title}
              description={module.description}
              progress={module.progress}
              courseImg={module.image}
            />
          ))}
        </div>
      </div>
      <div className="section-small">
        {/*
        <h4 className="mb-lg-5">Courses picked for you</h4>
        <div
          className="uk-child-width-1-4@m uk-child-width-1-3@s course-card-grid"
          data-uk-grid
        > 
          {[
            {
              category: "Business",
              title: "Venture Development",
              subtitle: "Learn how to launch and scale a startup",
              summaryLine1: "8 Weeks",
              summaryLine2: "12th Aug",
              courseImg: "../assets/images/vd.png",
            },
            {
              category: "Design",
              title: "UX/UI Design",
              subtitle: "Learn how to design delightful apps",
              summaryLine1: "8 Weeks",
              summaryLine2: "24th Aug",
              courseImg: "../assets/images/uxui.png",
            },
            {
              category: "Digital",
              title: "Digital Marketing",
              subtitle: "Marketing automation and more.",
              summaryLine1: "12 Weeks",
              summaryLine2: "6th Aug",
              courseImg: "../assets/images/dgm.png",
            },
          ].map(
            ({
              category,
              title,
              subtitle,
              summaryLine1,
              summaryLine2,
              courseImg,
            }) => (
              <DetailedModuleCard
                category={category}
                title={title}
                subtitle={subtitle}
                summaryLine1={summaryLine1}
                summaryLine2={summaryLine2}
                courseImg={courseImg}
              />
            )
          )}
            </div>*/}
      </div>
    </>
  );
};

export default CourseList;
