import http from "utils/http";
import { students } from "constants/endpoints";

const putGithubUsername = ({ githubUsername }) => {
  const ENDPOINT = students.putGithubUsername();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.put(`${API_BASE_URL}${ENDPOINT}`, { data: { githubUsername } });
};

export default putGithubUsername;
