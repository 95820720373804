import http from "utils/http";
import { page } from "constants/endpoints";

const getPage = ({ pageId }) => {
  const GET_PAGE_ENDPOINT = page.getPage({ pageId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${GET_PAGE_ENDPOINT}`);
};

export default getPage;
