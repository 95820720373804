import React, { useState, useEffect } from "react";

const RenderPeriodically = ({ refreshMs, children }) => {
  const [_renderPeriodicallyTimeInject, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), refreshMs);
    return () => {
      clearInterval(interval);
    };
  }, [refreshMs]);

  return React.createElement(
    children.type,
    { _renderPeriodicallyTimeInject, ...children.props },
    children
  );
};

export default RenderPeriodically;
