import React, { useState } from "react";
import { Link } from "react-router-dom";
import Authentication from "services/Authentication";
import { useHistory } from "react-router";
import useForm from "effects/useForm";

let auth = new Authentication();

const Login = ({ forceUpdate, updateCount }) => {
  let history = useHistory();
  const [invalidCredentialsError, setInvalidCredentialsError] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const {
    email,
    emailErrorMessage,
    password,
    passwordErrorMessage,
    setEmail,
    setPassword,
    isValid,
  } = useForm({
    email: {
      validation: (email) => {
        if (email === "") {
          return "Email is required";
        }
      },
      initialValue: "",
    },
    password: {
      validation: (password) => {
        if (password === "") {
          return "Password is required";
        }
      },
      initialValue: "",
    },
  });

  const handleLogin = (event) => {
    event.preventDefault();
    auth
      .login({ email, password })
      .then(() => {
        history.push("/");
        forceUpdate();
      })
      .catch((err) => {
        setInvalidCredentialsError("Incorrect Email or Password");
      });
  };

  const handleShowPassword = (event) => {
    event.preventDefault();
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <div data-uk-height-viewport className="uk-flex uk-flex-middle">
        <span style={{ display: "none" }}>{updateCount}</span>
        <div className="uk-width-2-3@m uk-width-1-2@s m-auto rounded">
          <div
            className="uk-child-width-1-2@m uk-grid-collapse bg-gradient-grey"
            data-uk-grid
          >
            <div className="uk-margin-auto-vertical uk-text-center uk-animation-scale-up p-3 uk-light">
              <i className=" uil-graduation-hat icon-large"></i>
              <h3 className="mb-4"> CyprusCodes</h3>
              <p>#TeknolojininAmbargosuOlmaz</p>
            </div>

            <div className="uk-card-default p-5 rounded">
              <div className="mb-4 uk-text-center">
                <h3 className="mb-0"> Welcome back</h3>
                <p className="my-2">Login to manage your account.</p>
              </div>
              <form>
                <div className="uk-form-group">
                  <label className="uk-form-label"> Email</label>

                  <div className="uk-position-relative w-100">
                    <span className="uk-form-icon">
                      <i className="icon-feather-mail"></i>
                    </span>
                    <input
                      className="uk-input"
                      type="email"
                      placeholder="name@example.com"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                    />
                  </div>
                </div>
                {emailErrorMessage && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      "margin-top": "-7px",
                    }}
                  >
                    {emailErrorMessage}
                  </p>
                )}
                <div className="uk-form-group">
                  <label className="uk-form-label"> Password</label>

                  <div className="uk-position-relative w-100">
                    <button
                      className="uk-form-icon"
                      onClick={(event) => handleShowPassword(event)}
                      style={{
                        border: "none",
                        background: "rgba(0,0,0,0)",
                        outline: "0px",
                        cursor: "pointer",
                      }}
                    >
                      <i
                        className={
                          passwordShown
                            ? "icon-feather-unlock"
                            : "icon-feather-lock"
                        }
                      ></i>
                    </button>

                    <input
                      className="uk-input"
                      type={passwordShown ? "text" : "password"}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      value={password}
                    />
                  </div>
                </div>
                {passwordErrorMessage && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      "margin-top": "-7px",
                    }}
                  >
                    {passwordErrorMessage}
                  </p>
                )}
                {invalidCredentialsError && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      "margin-top": "-7px",
                    }}
                  >
                    {invalidCredentialsError}
                  </p>
                )}

                <div className="mt-4 uk-flex-middle uk-grid-small" data-uk-grid>
                  <div className="uk-width-expand@s">
                    <p>
                      {" "}
                      Dont have account <Link to="/register">
                        Sign Up{" "}
                      </Link>{" "}
                    </p>
                    <p>
                      <Link to="/recover-password-request">
                        Forgot Password?{" "}
                      </Link>{" "}
                    </p>
                  </div>
                  <div className="uk-width-auto@s">
                    <button
                      type="submit"
                      className="btn btn-default"
                      disabled={!isValid}
                      onClick={handleLogin}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
