import React from "react";

const PaymentRow = ({ payment, index }) => {
  return (
    <>
      <tr>
        <td>
          <div className="media-body">
            <a href="#" className="name h6 mb-0 text-sm">
              {index} - {payment.firstName} {payment.lastName}
            </a>
          </div>
        </td>
        <td className="uk-text-bold mb-2">{payment.amount}</td>
        <td className="uk-text-bold mb-2">{payment.paymentMethod}</td>
        <td className="uk-text-bold mb-2"> {payment.transactionDate}</td>
      </tr>
    </>
  );
};

export default PaymentRow;
