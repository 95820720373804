import http from "utils/http";
import { students } from "constants/endpoints";

const putCodewarsUsername = ({ codewarsUsername, userId }) => {
  const ENDPOINT = students.putCodewarsUsername();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.put(`${API_BASE_URL}${ENDPOINT}`, {
    data: { codewarsUsername, userId },
  });
};

export default putCodewarsUsername;
