import http from "utils/http";
import { user } from "constants/endpoints";

const putPersonalDetails = ({
  userId,
  firstName,
  lastName,
  dateOfBirth,
  phoneNumber,
}) => {
  const ENDPOINT = user.putPersonalDetails({
    userId,
    firstName,
    lastName,
    dateOfBirth,
    phoneNumber,
  });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http
    .put(`${API_BASE_URL}${ENDPOINT}`, {
      data: {
        firstName,
        lastName,
        dateOfBirth,
        phoneNumber,
      },
    })
    .then((response) => {
      const accessToken = response.data.accessToken;
      localStorage.setItem("creds", accessToken);
      return response;
    });
};

export default putPersonalDetails;
