import http from "utils/http";
import { page } from "constants/endpoints";

const postPageView = ({ pageId, moduleId }) => {
  const POST_PAGEVIEW_ENDPOINT = page.postPageView({ pageId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${POST_PAGEVIEW_ENDPOINT}`, {
    data: { moduleId },
  });
};

export default postPageView;
