import React, { useState } from "react";
import useForm from "effects/useForm";
import Authentication from "services/Authentication";
import successNotification from "utils/successNotification";
import dangerNotification from "utils/dangerNotification";
import putPersonalDetails from "services/user/putPersonalDetails";

const auth = new Authentication();
const PersonalDetails = ({ setShowEdit }) => {
  const [loading, setLoading] = useState(false);
  const jwtPayload = auth.getAccessTokenPayload();
  const {
    firstName,
    setFirstName,
    firstNameErrorMessage,
    lastName,
    setLastName,
    lastNameErrorMessage,
    dateOfBirth,
    setDateOfBirth,
    dateOfBirthErrorMessage,
    phoneNumber,
    setPhoneNumber,
    phoneNumberErrorMessage,
    isValid,
  } = useForm({
    firstName: {
      validation: (firstName) => {
        if (firstName === "") {
          return "First name is required";
        }
      },
      initialValue: jwtPayload.firstName,
    },
    lastName: {
      validation: (lastName) => {
        if (lastName === "") {
          return "Surname is required";
        }
      },
      initialValue: jwtPayload.lastName,
    },
    dateOfBirth: {
      validation: (dateOfBirth) => {
        if (dateOfBirth === "") {
          return "Date of birth is required";
        }
      },
      initialValue: jwtPayload.dateOfBirth,
    },

    phoneNumber: {
      validation: (phoneNumber) => {
        if (phoneNumber === "") {
          return "Phone number is required";
        }
      },
      initialValue: jwtPayload.phoneNumber,
    },
  });

  const handleSave = () => {
    setLoading(true);
    putPersonalDetails({
      firstName,
      lastName,
      dateOfBirth,
      phoneNumber,
    })
      .then(() => {
        setLoading(false);
        setFirstName(firstName);
        setLastName(lastName);
        setDateOfBirth(dateOfBirth);
        setPhoneNumber(phoneNumber);
        successNotification("Saved");
      })
      .catch((err) => {
        setLoading(false);
        dangerNotification("Something went wrong!");
      });
  };

  return (
    <>
      <div className="uk-card-default rounded">
        <div className="p-3">
          <h5 className="mb-0 text-blue"> Personal info </h5>
        </div>
        <hr className="m-0" />
        <form className="uk-child-width-1-2@s uk-grid-small p-4" data-uk-grid>
          <div>
            <h5 className="uk-text-bold mb-2 text-blue">First Name</h5>
            <input
              type="text"
              className="uk-input"
              placeholder="Your name"
              value={firstName}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
            />
            {firstNameErrorMessage && (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  "margin-top": "-7px",
                }}
              >
                {firstNameErrorMessage}
              </p>
            )}
          </div>

          <div>
            <h5 className="uk-text-bold mb-2 text-blue"> Last Name </h5>
            <input
              type="text"
              className="uk-input"
              placeholder="Your surname"
              value={lastName}
              onChange={(event) => {
                setLastName(event.target.value);
              }}
            />
            {lastNameErrorMessage && (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  "margin-top": "-7px",
                }}
              >
                {lastNameErrorMessage}
              </p>
            )}
          </div>
          <div>
            <h5 className="uk-text-bold mb-2 text-blue"> Date of Birth </h5>
            <input
              className="uk-input"
              type="date"
              value={dateOfBirth}
              onChange={(event) => {
                setDateOfBirth(event.target.value);
              }}
            />
            {dateOfBirthErrorMessage && (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  "margin-top": "-7px",
                }}
              >
                {dateOfBirthErrorMessage}
              </p>
            )}
          </div>
          <div>
            <h5 className="uk-text-bold mb-2 text-blue"> Phone </h5>
            <input
              type="text"
              className="uk-input"
              placeholder="+90 533 862 35 68"
              value={phoneNumber}
              onChange={(event) => {
                setPhoneNumber(event.target.value);
              }}
            />
            {phoneNumberErrorMessage && (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  "margin-top": "-7px",
                }}
              >
                {phoneNumberErrorMessage}
              </p>
            )}
          </div>
        </form>

        <div className="uk-flex uk-flex-right p-4">
          <button
            className="btn btn-link mr-2"
            onClick={() => setShowEdit(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-default grey"
            disabled={!isValid}
            onClick={(event) => {
              event.preventDefault();
              handleSave();
            }}
          >
            {loading ? "Saving..." : "Save changes"}
          </button>
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;
