import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { useDropzone } from "react-dropzone";
import uploadAssets from "services/uploadAssets";
import dangerNotification from "utils/dangerNotification";

const PreviewImg = styled.img`
  max-height: 100px;
  width: auto;
`;
const ProfilePreviewImg = styled.img`
  max-height: 150px;
  height: 150px;
  min-width: 150px;
  border-radius: 100%;
  width: auto;
`;

const PreviewContainer = styled.div`
  max-height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;
const ProfilePreviewContainer = styled.div`
  max-height: 100%;
  width: 150px;
  height: 150px;
  position: absolute;
  top: -55px;
`;

const DropContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-right: 50px;

  ${(props) => css`
    cursor: ${props.type === "profile" && "pointer"};
    margin-right: ${props.type === "profile" && "0px"};
    background-color: ${props.type === "profile" && "transparent"};
    padding: ${props.type === "profile" && "0px"};
    border-radius: ${props.type === "profile" && "100%"};
    border: ${props.type === "profile" && "none"};
  `}
`;

const ImageContainer = styled.input`
  ${(props) => css`
    cursor: ${props.type === "profile" && "pointer"};
  `}
`;

function ImageUpload({ image, onChange, type }) {
  const [isLoading, setIsLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections.length) {
      dangerNotification("Something went wrong. Please try again.");
      return;
    }
    setIsLoading(true);
    uploadAssets({ file: acceptedFiles[0] })
      .then((response) => {
        onChange(response.data.url);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        dangerNotification("Something went wrong. Please try again.");
      });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    maxFiles: 1,
    onDrop,
  });

  return (
    <DropContainer {...getRootProps()} type={type}>
      {image && type === "profile" ? (
        <ProfilePreviewContainer>
          <ProfilePreviewImg src={image} type={type} />
        </ProfilePreviewContainer>
      ) : (
        <PreviewContainer>
          <PreviewImg src={image} />
        </PreviewContainer>
      )}
      {type === "profile" ? (
        <>
          <ImageContainer {...getInputProps()} />
          {isLoading ? (
            <span
              className="uk-link icon-feather-loader icon-medium"
              style={{
                zIndex: 99,
                color: "#fff",
              }}
            ></span>
          ) : (
            <span
              className="uk-link icon-feather-camera icon-medium"
              style={{ zIndex: 99 }}
            ></span>
          )}
        </>
      ) : (
        <>
          <input {...getInputProps()} />
          <p>Click here to upload a banner image</p>
          <em>(Only *.jpeg and *.png images will be accepted)</em>
        </>
      )}
    </DropContainer>
  );
}

export default ImageUpload;
