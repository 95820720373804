import http from "utils/http";
import { page } from "constants/endpoints";

const putPage = ({ pageId, pageContent, title }) => {
  const GET_PAGE_ENDPOINT = page.putPage({ pageId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.put(`${API_BASE_URL}${GET_PAGE_ENDPOINT}`, {
    data: {
      pageContent,
      title,
    },
  });
};

export default putPage;
