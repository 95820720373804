import React, { useState } from "react";
import getInstructors from "services/getInstructors";
import useFetchData from "effects/useFetchData";
import AdminSideBar from "components/AdminSideBar";
import { Link } from "react-router-dom";
import Footer from "components/Footer";
import InstructorCard from "./instructor-card";
import InstructorModal from "./InstructorModal/InstructorModal";

const AdminInstructors = () => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, errored, apiResponse, fetchAllInstructors] = useFetchData(
    () => getInstructors()
  );
  const { instructors } = apiResponse;

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (errored) {
    return <div>Something went wrong</div>;
  }

  return (
    <>
      <AdminSideBar />
      <div className="page-content">
        <div className="page-content-inner">
          <div className="d-flex">
            <nav id="breadcrumbs" className="mb-3">
              <ul>
                <li>
                  <a href="#">
                    {" "}
                    <i className="uil-home-alt"></i>{" "}
                  </a>
                </li>
                <li>
                  <a href="#"> Instructors </a>
                </li>
                <li>View Instructors</li>
              </ul>
            </nav>
          </div>
          <div className="section-header mb-lg-2 border-0 uk-flex-middle">
            <div className="section-header-left">
              <h2 className="uk-heading-line text-left">
                <span> Instructors </span>
              </h2>
            </div>
            <div className="section-header-right">
              <div className="display-as">
                <Link
                  to="/admin-instructors-list"
                  data-uk-tooltip="title: Veiw as list; pos: top-right"
                  title=""
                  aria-expanded="false"
                >
                  <i className="icon-feather-list"></i>
                </Link>
              </div>
              <div className="display-as">
                <Link
                  to="/admin-instructors"
                  className="active"
                  data-uk-tooltip="title: Veiw as Grid; pos: top-right"
                  title=""
                  aria-expanded="false"
                >
                  <i className="icon-feather-grid"></i>
                </Link>
              </div>

              <div className="d-flex">
                <button
                  className="btn btn-default uk-visible@s"
                  onClick={() => handleOpenModal()}
                >
                  <i className="uil-plus"></i> Add Instructors
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              {openModal && (
                <InstructorModal
                  handleCloseModal={handleCloseModal}
                  fetchAllInstructors={fetchAllInstructors}
                  apiResponse={apiResponse}
                />
              )}
            </div>
          </div>
          <div className="row">
            {instructors.map((instructor) => {
              return (
                <div className="d-flex justify-content-center col-4 mb-5">
                  <InstructorCard instructor={instructor} />
                </div>
              );
            })}
          </div>

          <ul className="uk-pagination my-5 uk-flex-center" data-uk-margin>
            <li className="uk-active">
              <span>1</span>
            </li>
            <li>
              <a href="#">2</a>
            </li>
            <li>
              <a href="#">3</a>
            </li>
            <li className="uk-disabled">
              <span>...</span>
            </li>
            <li>
              <a href="#">
                <span data-uk-pagination-next></span>
              </a>
            </li>
          </ul>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default AdminInstructors;
