import React from "react";
import { Redirect, Route } from "react-router-dom";
import Authentication from "services/Authentication";

const auth = new Authentication();

const AuthRoute = ({ children, role = false, ...rest }) => {
  const isAuthenticated = auth.isAuthenticated();
  const accessTokenPayload = auth.getAccessTokenPayload();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (role && accessTokenPayload?.userRole !== role) {
    return <Redirect to="/dashboard" />;
  }

  return <Route {...rest}>{children}</Route>;
};

export default AuthRoute;
