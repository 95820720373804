import http from "utils/http";
import { blog } from "constants/endpoints";

const putBlog = ({
  blogId,
  categoryId,
  description,
  image,
  slug,
  title,
  blogContent,
  published,
}) => {
  const ENDPOINT = blog.putBlog({ blogId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.put(`${API_BASE_URL}${ENDPOINT}`, {
    data: {
      categoryId,
      description,
      image,
      slug,
      title,
      blogContent,
      published,
    },
  });
};

export default putBlog;
