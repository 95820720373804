import React, { useState } from "react";
import styled from "styled-components";
import postUserPayment from "services/payment/postUserPayment";
import getStudentPaymentHistory from "services/getStudentPaymentHistory";
import successNotification from "utils/successNotification";
import useFetchData from "effects/useFetchData";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

const ModalContainer = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1 !important;
`;

const ModalInner = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  max-width: 700px;
  margin-left: 30%;
  padding: 0.5rem;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  box-shadow: 0 0 100px rgba(0, 0, 0, 1);
  @media screen and (max-width: 900px) {
    margin-left: 7%;
  }
  @media screen and (max-width: 768px) {
    margin-left: 5%;
  }
`;

const Submit = styled.button`
  background-color: #3659a2;
  border: none;
  box-sizing: border-box;
  color: #fff;
  border-radius: 3px;
  padding: 8px;
  cursor: pointer;
  :hover {
    border: none;
    background-color: #3e416d;
  }
`;

const Input = styled.input`
  background: transparent;
`;
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const PaginationPageNo = styled.button`
  margin: 0 0.2rem;
  cursor: pointer;
  padding: 0.5rem;
  border: none;
  background-color: ${({ active }) => (active ? "#3659a2" : "transparent")};
  color: ${({ active }) => (active ? "#fff" : "#000")};
  border-radius: 4px;
`;
const PaginationNavBtn = styled.button`
  margin: 0 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border: none;
  background-color: #3659a2;
  color: #fff;
  border-radius: 4px;
`;
const AddUserPayment = ({ setShowAddUserPaymentModal, nightMode, student }) => {
  const [loading, setLoading] = useState(false);
  const [transactionDate, setTransactionDate] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentsPerPage] = useState(10);

  const { userId } = student;

  const [
    loadingPaymentHistory,
    errorPaymentHistory,
    getPaymentHistoryResponse,
  ] = useFetchData(() => getStudentPaymentHistory({ userId }));

  if (loadingPaymentHistory) {
    return <div>Loading...</div>;
  }

  if (errorPaymentHistory) {
    return <div>Something went wrong</div>;
  }

  const { studentPaymentHistory } = getPaymentHistoryResponse;

  const handleAddPayment = (userId, description, amount, transactionDate) => {
    postUserPayment({
      userId,
      description,
      amount,
      transactionDate,
    })
      .then(() => {
        setLoading(loading);
        successNotification("Payment successfully added ✅");
        setTransactionDate("");
        setAmount("");
        setDescription("");
      })
      .catch((err) => console.log(err, "Something went wrong"));
  };

  const indexOfLastPayment = currentPage * paymentsPerPage;

  const indexOfFirstPayment = indexOfLastPayment - paymentsPerPage;

  const currentPayments = studentPaymentHistory.slice(
    indexOfFirstPayment,
    indexOfLastPayment
  );

  const totalPages = Math.ceil(studentPaymentHistory.length / paymentsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <ModalContainer
      style={{
        overflowY: "auto",
      }}
    >
      <ModalInner
        style={{
          backgroundColor: nightMode && "#232020",
          border: "none",
        }}
      >
        <Wrapper>
          <h3 className="title-table-st"> Payment history</h3>
          <i
            className="icon-line-awesome-close"
            style={{
              cursor: "pointer",
              backgroundColor: "#3659a2",
              color: "#fff",
              padding: "5px",
              borderRadius: "3px",
            }}
            onClick={() => setShowAddUserPaymentModal(false)}
          ></i>
        </Wrapper>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <tr style={{ justifyContent: "space-evenly" }}>
            <div style={{ marginLeft: "15px" }}>
              <th>Payment Status</th>
              <th>Payment Amount</th>
              <th>Payment Method</th>
              <th>Transaction Date</th>
            </div>

            {currentPayments.map((studentPayment, idx) => {
              const displayIndex = indexOfFirstPayment + idx + 1;

              return (
                <tr
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <td key={idx}>
                    <span>{displayIndex} - </span>
                    {studentPayment.status}
                  </td>
                  <td>{studentPayment.amount}</td>
                  <td>{studentPayment.payment_method}</td>
                  <td>{studentPayment.transaction_date}</td>
                </tr>
              );
            })}
          </tr>
        </div>
        <PaginationContainer>
          <PaginationNavBtn onClick={handlePreviousPage}>
            Previous
          </PaginationNavBtn>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <PaginationPageNo
              key={page}
              onClick={() => handlePageChange(page)}
              active={page === currentPage}
            >
              {page}
            </PaginationPageNo>
          ))}
          <PaginationNavBtn onClick={handleNextPage}>Next</PaginationNavBtn>
        </PaginationContainer>
        <Wrapper>
          <h5 className="title-table-st">Add Payment</h5>
        </Wrapper>
        <InputWrapper>
          <Input
            type="date"
            onChange={(e) => setTransactionDate(e.target.value)}
            value={transactionDate}
          />
          <Input
            placeholder="Amount"
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
          />
          <Input
            placeholder="Description"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
        </InputWrapper>
        <Submit
          onClick={() =>
            handleAddPayment(userId, description, amount, transactionDate)
          }
        >
          Submit
        </Submit>
      </ModalInner>
    </ModalContainer>
  );
};

export default AddUserPayment;
