import http from "utils/http";
import { payments } from "constants/endpoints";

const postUserPayment = ({ userId, description, transactionDate, amount }) => {
  const ENDPOINT = payments.postUserPayment();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${ENDPOINT}`, {
    data: {
      userId,
      description,
      amount,
      transactionDate,
    },
  });
};

export default postUserPayment;
