import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div data-uk-grid>
          <div className="uk-width-1-3@m">
            <a href="home.html" className="uk-logo">
              <i className="uil-graduation-hat"></i>
              CyprusCodes
            </a>
            <p className="footer-description"> #TeknolojininAmbargosuOlmaz</p>
          </div>
          <div className="uk-width-expand@s uk-width-1-2">
            <div className="footer-links pl-lg-8">
              <h5 style={{ color: "#3e416d" }}>Explore </h5>
              <ul>
                <li>
                  <Link to="/courses">Courses</Link>
                </li>
                {/*
                <li>
                  <Link to="/blogs">Blog</Link>
                </li>
                */}
              </ul>
            </div>
          </div>
          <div className="uk-width-expand@s uk-width-1-2">
            <div className="footer-links pl-lg-8">
              <h5 style={{ color: "#3e416d" }}> Account </h5>
              <ul>
                <li>
                  <Link to="/">Dashboard</Link>
                </li>
                {/*}
                <li>
                  <Link to="/profile">Profile</Link>
                </li>
              */}
              </ul>
            </div>
          </div>
          <div className="uk-width-expand@s uk-width-1-2">
            <div className="footer-links pl-lg-8">
              <h5 style={{ color: "#3e416d" }}> Resources</h5>
              <ul>
                {/*}
                <li>
                  <a href="#"> Contact </a>
                </li>
                <li>
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
            */}
                <li>
                  <a
                    href="https://cypruscodes.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div className="uk-grid-collapse" data-uk-grid>
          <div className="uk-width-expand@s uk-first-column">
            <p>
              © {new Date().getFullYear()} <strong>CyprusCodes</strong>. All
              Rights Reserved.{" "}
            </p>
          </div>
          <div className="uk-width-auto@s">
            <nav className="footer-nav-icon">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/Cyprus-Codes-100363815111116"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-brand-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/cypruscodes/?hl=en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-brand-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/cypruscodes"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-brand-twitter"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
