import React from "react";

class ScrollDetector extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    this.props.handleScroll();
  };

  render() {
    return <></>;
  }
}

export default ScrollDetector;
