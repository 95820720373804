import http from "utils/http";
import { page } from "constants/endpoints";

const postPageFeedback = ({ pageId, moduleId, comments, rating }) => {
  const ENDPOINT = page.postPageFeedback({ pageId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${ENDPOINT}`, {
    data: { moduleId, comments, rating },
  });
};

export default postPageFeedback;
