import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import postPageComplete from "services/page/postPageComplete";
import deletePageCompelete from "services/page/deletePageComplete";
import MarkdownWrapper from "components/MarkdownWrapper";
import styled from "styled-components";

const WhiteBackground = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: rgb(49 62 81 / 5%) 0px 8px 20px 0px;
`;

const PageContentView = ({
  page,
  pageId,
  chapterId,
  moduleId,
  nextPage,
  previousPage,
}) => {
  let history = useHistory();
  const [pageProgressId, setPageProgressId] = useState(page.userProgressId);
  const [pageCompleteStatus, setPageCompleteStatus] = useState(
    Boolean(page.userProgressId)
  );
  useEffect(() => {
    setPageProgressId(page.userProgressId);
    setPageCompleteStatus(Boolean(page.userProgressId));
  }, [page]);

  const markPageComplete = async () => {
    const result = await postPageComplete({
      pageId,
      chapterId,
      moduleId,
    });
    setPageCompleteStatus(true);
    setPageProgressId(result.data.pageComplete);
  };
  const unmarkPageComplete = () => {
    deletePageCompelete({ pageProgressId });
    setPageCompleteStatus(false);
  };

  return (
    <li>
      <p className="uk-margin-medium-top uk-text-medium">
        {" "}
        <WhiteBackground className="markdown-container">
          <MarkdownWrapper content={page.pageContent} />
        </WhiteBackground>
      </p>

      <div className="uk-flex uk-flex-center  uk-animation-slide-bottom my-4">
        {previousPage && (
          <button
            type="button"
            className="btn btn-default icon-label"
            onClick={() => {
              window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
              history.push(
                `/module/${moduleId}/chapter/${previousPage.chapterId}/page/${previousPage.pageId}`
              );
            }}
          >
            <span className="inner-icon">
              <i className="icon-feather-chevron-left"></i>
            </span>
            <span className="inner-text"> Previous</span>
          </button>
        )}

        {pageCompleteStatus ? (
          <button
            type="button"
            className="btn btn-success icon-label uk-flex-1 uk-margin-left  uk-margin-right"
            onClick={unmarkPageComplete}
          >
            <span className="inner-text"> Completed </span>
            <span className="inner-icon">
              <i className="icon-feather-check-circle"></i>
            </span>
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-info icon-label uk-flex-1 uk-margin-left  uk-margin-right"
            onClick={markPageComplete}
          >
            <span className="inner-text"> Mark as Complete </span>
            <span className="inner-icon">
              <i className="icon-feather-check-circle"></i>
            </span>
          </button>
        )}

        {nextPage && (
          <button
            type="button"
            className="btn btn-default icon-label "
            onClick={() => {
              window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
              history.push(
                `/module/${moduleId}/chapter/${nextPage.chapterId}/page/${nextPage.pageId}`
              );
            }}
          >
            <span className="inner-text"> Next </span>
            <span className="inner-icon">
              <i className="icon-feather-chevron-right"></i>
            </span>
          </button>
        )}
      </div>
    </li>
  );
};

export default PageContentView;
