import http from "utils/http";
import { user } from "constants/endpoints";

const patchUserDetails = ({
  studentId,
  firstName,
  lastName,
  dateOfBirth,
  phone,
}) => {
  const ENDPOINT = user.patchUserDetails({
    studentId,
  });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.patch(`${API_BASE_URL}${ENDPOINT}`, {
    data: {
      firstName,
      lastName,
      dateOfBirth,
      phone,
    },
  });
};

export default patchUserDetails;
