import http from "utils/http";
import { moduleEndpoint } from "constants/endpoints";

const getResumeModule = ({ moduleId }) => {
  const ENDPOINT = moduleEndpoint.getResumeModule({ moduleId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.get(`${API_BASE_URL}${ENDPOINT}`);
};

export default getResumeModule;
