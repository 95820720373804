import React from "react";
import Markdown from "markdown-to-jsx";
import EmojiConverter from "emoji-js";
import MarkdownStyled from "./styled";
import Code from "./components/Code";
import YouTube from "./components/YouTube";
import CodePen from "./components/CodePen";

const MarkdownWrapper = ({ content, className, children }) => {
  const pageContent = content ? content : children;
  const emojiConverter = new EmojiConverter();
  const newContent = emojiConverter.replace_colons(pageContent || "");

  return (
    <MarkdownStyled className={className}>
      <Markdown
        options={{
          overrides: {
            pre: {
              component: Code,
            },
            YouTube: {
              component: YouTube,
            },
            CodePen: {
              component: CodePen,
            },
            a: {
              props: {
                target: "_blank",
              },
            },
          },
        }}
      >
        {newContent || ""}
      </Markdown>
    </MarkdownStyled>
  );
};

export default MarkdownWrapper;
