import React from "react";
import getUserModules from "services/getUserModules";
import useFetchData from "effects/useFetchData";
import AdminSideBar from "components/AdminSideBar";
import { Link } from "react-router-dom";
import Authentication from "services/Authentication";
import Footer from "components/Footer";

const auth = new Authentication();

const AdminCourses = () => {
  const [loading, errored, apiResponse] = useFetchData(() => getUserModules());
  const { studentModules } = apiResponse;
  if (loading) {
    return <div>Loading...</div>;
  }

  if (errored) {
    return <div>Something went wrong</div>;
  }

  const jwtPayload = auth.getAccessTokenPayload();

  return (
    <>
      <AdminSideBar />

      <div className="page-content">
        <div className="page-content-inner">
          <nav id="breadcrumbs" className="mb-3">
            <ul>
              <li>
                <a href="#">
                  {" "}
                  <i className="uil-home-alt"></i>{" "}
                </a>
              </li>
              <li> Courses </li>
            </ul>
          </nav>

          <div className="d-flex justify-content-between mb-3">
            <h3> Courses (14) </h3>

            <div>
              <Link to="/admin-add-course" className="btn btn-default">
                <i className="uil-plus"> </i> New course
              </Link>
            </div>
          </div>

          <div
            className="uk-child-width-1-3@m"
            data-uk-grid
            data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200"
          >
            <div>
              <a href="#">
                <div className="card animate-this uk-inline-clip">
                  <img src="../assets/images/course/2.png" alt="" />
                  <div className="card-body text-center pb-3">
                    <h6 className=" mb-0"> Learn Angular Fundamentals </h6>
                  </div>
                  <div className="card-footer py-0 border-top-0">
                    <div className="row align-items-center text-center">
                      <div className="col-6 py-3">
                        <a href="#" className="text-muted">
                          {" "}
                          120$ Sales{" "}
                        </a>
                      </div>
                      <div className="col-6 py-3">
                        <a href="#" className="text-muted">
                          {" "}
                          20 Enrolled{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div>
              <a href="#">
                <div className="card animate-this uk-inline-clip">
                  <img src="../assets/images/course/3.png" alt="" />
                  <div className="card-body text-center pb-3">
                    <h6 className=" mb-0"> The Complete JavaScript </h6>
                  </div>
                  <div className="card-footer py-0 border-top-0">
                    <div className="row align-items-center text-center">
                      <div className="col-6 py-3">
                        <a href="#" className="text-muted">
                          {" "}
                          90$ Sales{" "}
                        </a>
                      </div>
                      <div className="col-6 py-3">
                        <a href="#" className="text-muted">
                          {" "}
                          8 Enrolled{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div>
              <a href="#">
                <div className="card animate-this uk-inline-clip">
                  <img src="../assets/images/course/1.png" alt="" />
                  <div className="card-body text-center pb-3">
                    <h6 className=" mb-0"> Ultimate Web Developer Course </h6>
                  </div>
                  <div className="card-footer py-0 border-top-0">
                    <div className="row align-items-center text-center">
                      <div className="col-6 py-3">
                        <a href="#" className="text-muted">
                          {" "}
                          230$ Sales{" "}
                        </a>
                      </div>
                      <div className="col-6 py-3">
                        <a href="#" className="text-muted">
                          {" "}
                          21 Enrolled{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div>
              <a href="#">
                <div className="card animate-this uk-inline-clip">
                  <img src="../assets/images/course/5.png" alt="" />
                  <div className="card-body text-center pb-3">
                    <h6 className=" mb-0"> Ultimate Web Developer Course </h6>
                  </div>
                  <div className="card-footer py-0 border-top-0">
                    <div className="row align-items-center text-center">
                      <div className="col-6 py-3">
                        <a href="#" className="text-muted">
                          {" "}
                          142$ Sales{" "}
                        </a>
                      </div>
                      <div className="col-6 py-3">
                        <a href="#" className="text-muted">
                          {" "}
                          13 Enrolled{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div>
              <a href="#">
                <div className="card animate-this uk-inline-clip">
                  <img src="../assets/images/course/7.png" alt="" />
                  <div className="card-body text-center pb-3">
                    <h6 className=" mb-0"> Bootstrap Introduction </h6>
                  </div>
                  <div className="card-footer py-0 border-top-0">
                    <div className="row align-items-center text-center">
                      <div className="col-6 py-3">
                        <a href="#" className="text-muted">
                          {" "}
                          80$ Sales{" "}
                        </a>
                      </div>
                      <div className="col-6 py-3">
                        <a href="#" className="text-muted">
                          {" "}
                          6 Enrolled{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div>
              <a href="#">
                <div className="card animate-this uk-inline-clip">
                  <img src="../assets/images/course/2.png" alt="" />
                  <div className="card-body text-center pb-3">
                    <h6 className=" mb-0"> Learn Angular Fundamentals </h6>
                  </div>
                  <div className="card-footer py-0 border-top-0">
                    <div className="row align-items-center text-center">
                      <div className="col-6 py-3">
                        <a href="#" className="text-muted">
                          {" "}
                          120$ Sales{" "}
                        </a>
                      </div>
                      <div className="col-6 py-3">
                        <a href="#" className="text-muted">
                          {" "}
                          20 Enrolled{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div>
              <a href="#">
                <div className="card animate-this uk-inline-clip">
                  <img src="../assets/images/course/3.png" alt="" />
                  <div className="card-body text-center pb-3">
                    <h6 className=" mb-0"> The Complete JavaScript </h6>
                  </div>
                  <div className="card-footer py-0 border-top-0">
                    <div className="row align-items-center text-center">
                      <div className="col-6 py-3">
                        <a href="#" className="text-muted">
                          {" "}
                          90$ Sales{" "}
                        </a>
                      </div>
                      <div className="col-6 py-3">
                        <a href="#" className="text-muted">
                          {" "}
                          8 Enrolled{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div>
              <a href="#">
                <div className="card animate-this uk-inline-clip">
                  <img src="../assets/images/course/1.png" alt="" />
                  <div className="card-body text-center pb-3">
                    <h6 className=" mb-0"> Ultimate Web Developer Course </h6>
                  </div>
                  <div className="card-footer py-0 border-top-0">
                    <div className="row align-items-center text-center">
                      <div className="col-6 py-3">
                        <a href="#" className="text-muted">
                          {" "}
                          230$ Sales{" "}
                        </a>
                      </div>
                      <div className="col-6 py-3">
                        <a href="#" className="text-muted">
                          {" "}
                          21 Enrolled{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div>
              <a href="#">
                <div className="card animate-this uk-inline-clip">
                  <img src="../assets/images/course/5.png" alt="" />
                  <div className="card-body text-center pb-3">
                    <h6 className=" mb-0"> Ultimate Web Developer Course </h6>
                  </div>
                  <div className="card-footer py-0 border-top-0">
                    <div className="row align-items-center text-center">
                      <div className="col-6 py-3">
                        <a href="#" className="text-muted">
                          {" "}
                          142$ Sales{" "}
                        </a>
                      </div>
                      <div className="col-6 py-3">
                        <a href="#" className="text-muted">
                          {" "}
                          13 Enrolled{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <ul className="uk-pagination my-5 uk-flex-center" data-uk-margin>
            <li className="uk-active">
              <span>1</span>
            </li>
            <li>
              <a href="#">2</a>
            </li>
            <li>
              <a href="#">3</a>
            </li>
            <li className="uk-disabled">
              <span>...</span>
            </li>
            <li>
              <a href="#">
                <span data-uk-pagination-next></span>
              </a>
            </li>
          </ul>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AdminCourses;
