import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import useForm from "effects/useForm";
import updatePassword from "services/passwordRecovery/updatePassword";
import successNotification from "utils/successNotification";

const RecoverPassword = () => {
  let history = useHistory();
  const { shortcode } = useParams();
  const [invalidCredentialsError, setInvalidCredentialsError] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    email,
    emailErrorMessage,
    password,
    passwordErrorMessage,
    setEmail,
    setPassword,
    isValid,
    confirmPassword,
    confirmPasswordErrorMessage,
    setConfirmPassword,
  } = useForm({
    email: {
      validation: (email) => {
        if (email === "") {
          return "Email is required";
        }
      },
      initialValue: "",
    },
    password: {
      validation: (password) => {
        if (password === "") {
          return "Password is required";
        }
      },
      initialValue: "",
    },
    confirmPassword: {
      validation: (confirmPassword) => {
        if (confirmPassword === "") {
          return "Confirm Password is required";
        }
      },
      initialValue: "",
    },
  });

  const handleUpdate = (event) => {
    event.preventDefault();
    setLoading(true);
    updatePassword({ email, password, confirmPassword, shortcode })
      .then(() => {
        successNotification("Your password has been changed.");
        setLoading(false);
        history.push("/login");
      })
      .catch((err) => {
        setInvalidCredentialsError("Incorrect Email or Password");
      });
  };

  return (
    <>
      <div data-uk-height-viewport className="uk-flex uk-flex-middle">
        <div className="uk-width-2-3@m uk-width-1-2@s m-auto rounded">
          <div className="uk-card-default p-5 rounded">
            <div className="mb-4 uk-text-center">
              <h3 className="mb-0"> Reset Password</h3>
              <p className="my-2">One time password recovery.</p>
            </div>
            <form>
              <div className="uk-form-group">
                <label className="uk-form-label"> Email</label>

                <div className="uk-position-relative w-100">
                  <span className="uk-form-icon">
                    <i className="icon-feather-mail"></i>
                  </span>
                  <input
                    className="uk-input"
                    type="email"
                    placeholder="name@example.com"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                  />
                </div>
              </div>
              {emailErrorMessage && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    "margin-top": "-7px",
                  }}
                >
                  {emailErrorMessage}
                </p>
              )}
              <div className="uk-form-group">
                <label className="uk-form-label"> Password</label>

                <div className="uk-position-relative w-100">
                  <span className="uk-form-icon">
                    <i className="icon-feather-lock"></i>
                  </span>
                  <input
                    className="uk-input"
                    type="password"
                    placeholder="********"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    value={password}
                  />
                </div>
              </div>
              {passwordErrorMessage && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    "margin-top": "-7px",
                  }}
                >
                  {passwordErrorMessage}
                </p>
              )}
              {invalidCredentialsError && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    "margin-top": "-7px",
                  }}
                >
                  {invalidCredentialsError}
                </p>
              )}
              <div className="uk-form-group">
                <label className="uk-form-label">Confirm Password</label>

                <div className="uk-position-relative w-100">
                  <span className="uk-form-icon">
                    <i className="icon-feather-lock"></i>
                  </span>
                  <input
                    className="uk-input"
                    type="password"
                    placeholder="********"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    value={confirmPassword}
                  />
                </div>
                {confirmPasswordErrorMessage && (
                  <p className="text-danger mb-0">
                    {confirmPasswordErrorMessage}
                  </p>
                )}
              </div>
              {invalidCredentialsError && (
                <div className="login-error-wrapper">
                  <p className="text-danger">{invalidCredentialsError}</p>
                </div>
              )}
              <div
                className="mt-4 uk-flex-middle uk-grid-small"
                data-uk-grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div className="uk-width-auto@s">
                  <Link to="/login">
                    <button
                      type="submit"
                      className="btn btn-default"
                      disabled={!isValid || loading}
                      onClick={handleUpdate}
                    >
                      {loading
                        ? "Sending Email.." // <i class="fas fa-spinner fa-spin"></i>
                        : "Update Password"}
                    </button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoverPassword;
