import React, { useState } from "react";
import useFetchData from "effects/useFetchData";
import getBlogsByCategory from "services/Blog/getBlogsByCategory";
import { Link } from "react-router-dom";
import getBlogCategories from "services/Blog/getBlogCategories";
import getBlogTags from "services/Blog/getBlogTags";
import getBlogsByTag from "services/Blog/getBlogsByTag";
import getBlogs from "services/Blog/getBlogs";
import formatDateRelativeOrAbsolute from "utils/formatDateRelativeOrAbsolute";
import chunkify from "utils/chunkify";
import postSubscription from "services/Blog/postSubscription";
import successNotification from "utils/successNotification";
import dangerNotification from "utils/dangerNotification";

const Blogs = ({ nightMode }) => {
  const [category, setCategory] = useState("All");
  const [selectedTag, setTag] = useState(null);
  const [subscriptionEmail, setSubscriptionEmail] = useState("");

  const [tagLoading, tagError, blogsByTagResponse] = useFetchData(
    () => getBlogsByTag({ tagId: selectedTag }),
    [selectedTag]
  );

  const [loading, error, blogs] = useFetchData(
    () => getBlogsByCategory({ categorySlug: category }),
    [category]
  );

  const [allBlogsLoading, allBlogsError, allBlogs] = useFetchData(() =>
    getBlogs()
  );

  const [categoryLoading, categoryError, categories] = useFetchData(
    () => getBlogCategories(),
    []
  );

  const [tagsLoading, tagsError, allBlogTags] = useFetchData(
    () => getBlogTags(),
    []
  );

  if (
    categoryLoading ||
    loading ||
    tagsLoading ||
    tagLoading ||
    allBlogsLoading
  ) {
    return <div>Loading...</div>;
  }

  if (categoryError || error || tagsError || tagError || allBlogsError) {
    return <div>Something went wrong</div>;
  }

  let blogCategorytoRender;
  if (category === "All") {
    blogCategorytoRender = allBlogs.blogs;
  } else {
    blogCategorytoRender = blogs.blogsByCategory;
  }

  const blogsToRender = selectedTag
    ? blogsByTagResponse.blogsByTag
    : blogCategorytoRender;

  const tagsPerLine = chunkify(
    [{ categorySlug: "All", category: "All" }, ...categories.allBlogCategories],
    6
  );

  const handleSubscribe = (event) => {
    event.preventDefault();
    postSubscription({ email: subscriptionEmail })
      .then(() => {
        successNotification("Succesfully subscribed.");
        setSubscriptionEmail("");
      })
      .catch((err) => {
        dangerNotification("Invalid email. Please try again.");
        console.log(err, "nope");
      });
  };

  return (
    <div className="page-content">
      <div className="page-content-inner">
        <h2> Blogs </h2>
        <div data-uk-toggle="cls: uk-flex uk-flex-between@m uk-flex-middle; mode: media; media: @m">
          <nav className="responsive-tab mb-4 style-1">
            {tagsPerLine.map((tags) => {
              return (
                <ul
                  style={{
                    margin: "0",
                    padding: "0",
                    overflowX: "inherit",
                  }}
                >
                  {tags.map((cat) => {
                    return (
                      <li
                        style={{
                          lineHeight: "32px",
                          height: "32px",
                        }}
                        onClick={() => {
                          setCategory(cat.categorySlug);
                          setTag(null);
                        }}
                        className={
                          cat.categorySlug === category ? "uk-active" : ""
                        }
                      >
                        <a href="#">{cat.category}</a>
                      </li>
                    );
                  })}
                </ul>
              );
            })}
          </nav>
        </div>

        <div className="uk-grid-large mt-4" data-uk-grid>
          <div className="uk-width-expand@m">
            {blogsToRender.map((blog) => {
              return (
                <div className="blog-article">
                  <a href="blog-single.html">
                    <Link to={`/blog/${blog.slug}`}>
                      {" "}
                      <h2>{blog.title}</h2>{" "}
                    </Link>
                  </a>
                  <p className="blog-article-meta">
                    <strong>
                      {" "}
                      {formatDateRelativeOrAbsolute(blog.publishedAt, {
                        startThreshold: 336,
                        endThreshold: 336,
                        dateOnly: true,
                      })}{" "}
                    </strong>
                    <a href="user-profile.html">
                      {" "}
                      {blog.firstName} {blog.lastName}
                    </a>
                  </p>
                  <Link to={`/blog/${blog.slug}`}>
                    <div className="blog-article-thumbnail">
                      <div className="blog-article-thumbnail-inner">
                        <img
                          src={blog.image || "../assets/images/blog/img-1.jpg"}
                        />
                      </div>
                    </div>
                  </Link>
                  <p className="blog-article-content">
                    {blog.description}
                    <Link to={`/blog/${blog.slug}`}>Read more...</Link>
                  </p>
                </div>
              );
            })}

            {/* <ul className="uk-pagination my-5 uk-flex-center" data-uk-margin>
                <li>
                  <a href="#">
                    <span data-uk-pagination-previous></span>
                  </a>
                </li>
                <li className="uk-disabled">
                  <span>...</span>
                </li>
                <li>
                  <a href="#">4</a>
                </li>
                <li className="uk-active">
                  <span>7</span>
                </li>
                <li>
                  <a href="#">8</a>
                </li>
                <li>
                  <a href="#">10</a>
                </li>
                <li className="uk-disabled">
                  <span>...</span>
                </li>
                <li>
                  <a href="#">
                    <span data-uk-pagination-next></span>
                  </a>
                </li>
              </ul> */}
          </div>

          <div className="uk-width-1-3@s">
            <div className="uk-card-default rounded uk-overflow-hidden">
              <div className="p-4 text-center">
                <h4 className="uk-text-bold text-blue"> Subscribe </h4>
                <p> Get the Latest Posts and Article for us On Your Email</p>

                <form className="mt-3">
                  <input
                    type="email"
                    className="uk-input uk-form-small"
                    placeholder="Enter your email address"
                    value={subscriptionEmail}
                    onChange={(e) => {
                      setSubscriptionEmail(e.target.value);
                    }}
                  />
                  <input
                    type="submit"
                    value="Subscribe"
                    className="btn btn-default  btn-block mt-3"
                    onClick={handleSubscribe}
                  />
                </form>
              </div>
            </div>

            {/* <div className="uk-card-default rounded mt-4">
                <ul
                  className="uk-child-width-expand uk-tab"
                  data-uk-switcher="animation: uk-animation-fade"
                >
                  <li>
                    <a href="#">Newest</a>
                  </li>
                  <li>
                    <a href="#">Popular</a>
                  </li>
                </ul>

                <ul className="uk-switcher">
                  <li>
                    <div className="py-3 px-4">
                      <div className="uk-grid-small" data-uk-grid>
                        <div className="uk-width-expand">
                          <p> Overview of SQL Commands and PDO </p>
                        </div>
                        <div className="uk-width-1-3">
                          <img
                            src="../assets/images/blog/img-3.jpg"
                            alt=""
                            className="rounded-sm"
                          />
                        </div>
                      </div>
                      <div className="uk-grid-small" data-uk-grid>
                        <div className="uk-width-expand">
                          <p> Writing a Simple MVC App in Plain </p>
                        </div>
                        <div className="uk-width-1-3">
                          <img
                            src="../assets/images/blog/img-2.jpg"
                            alt=""
                            className="rounded-sm"
                          />
                        </div>
                      </div>
                      <div className="uk-grid-small" data-uk-grid>
                        <div className="uk-width-expand">
                          <p> How to Create and Use Bash Scripts </p>
                        </div>
                        <div className="uk-width-1-3">
                          <img
                            src="../assets/images/blog/img-3.jpg"
                            alt=""
                            className="rounded-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="py-3 px-4">
                      <div className="uk-grid-small" data-uk-grid>
                        <div className="uk-width-expand">
                          <p> Overview of SQL Commands and PDO </p>
                        </div>
                        <div className="uk-width-1-3">
                          <img
                            src="../assets/images/blog/img-3.jpg"
                            alt=""
                            className="rounded-sm"
                          />
                        </div>
                      </div>
                      <div className="uk-grid-small" data-uk-grid>
                        <div className="uk-width-expand">
                          <p> Writing a Simple MVC App in Plain </p>
                        </div>
                        <div className="uk-width-1-3">
                          <img
                            src="../assets/images/blog/img-2.jpg"
                            alt=""
                            className="rounded-sm"
                          />
                        </div>
                      </div>
                      <div className="uk-grid-small" data-uk-grid>
                        <div className="uk-width-expand">
                          <p> How to Create and Use Bash Scripts </p>
                        </div>
                        <div className="uk-width-1-3">
                          <img
                            src="../assets/images/blog/img-3.jpg"
                            alt=""
                            className="rounded-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div> */}

            <h4 className="mt-4 text-blue"> Tags </h4>
            <div uk-margin>
              {allBlogTags.tags.map((tag) => {
                return (
                  <a
                    onClick={(event) => {
                      event.preventDefault();
                      setTag(tag.tagId);
                      setCategory(null);
                    }}
                    style={
                      tag.tagId === selectedTag
                        ? {
                            margin: "3px",
                            background: "#3e416d",
                            color: "white",
                          }
                        : { margin: "3px" }
                    }
                    className="btn btn-small btn-light"
                  >
                    {"#" + tag.tag}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
