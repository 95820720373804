/*
  Group endpoints by resource and HTTP Method
*/

const user = {
  getModules: () => `/users/modules`,
  getCalendar: () => `/users/calendar`,
  moduleLastPage: ({ moduleId }) => `/users/modules/${moduleId}/last-page`,
  login: () => `/login`,
  register: () => `/register`,
  putPersonalDetails: () => `/profile-edit/personal`,
  putAddressDetails: () => `/profile-edit/address`,
  getStudents: () => "/students",
  getInstructors: () => "/instructors",
  requestPasswordRecovery: () => `/recovery-request`,
  updatePassword: () => `/recover-password`,
  putProfilePicture: () => `/profile-edit/picture`,
  patchUserDetails: ({ studentId }) =>
    `/users/edit-student-details/${studentId}`, // update user details
  deletStudentDetails: ({ studentId }) => `/users/delete/${studentId}`, // delete student details
};

const moduleEndpoint = {
  getModule: ({ moduleId }) => `/module/${moduleId}`,
  getActiveModules: () => `/modules`,
  getResumeModule: ({ moduleId }) => `/users/module/${moduleId}/resume`,
  postUserModule: ({ userId }) => `/users/${userId}/modules`,
  deleteUserModule: ({ moduleId }) => `/modules/${moduleId}/remove`,
  getActiveStudentModules: ({ userId }) => `/users/${userId}/active-modules`,
};

const chapter = {
  getChapter: ({ chapterId }) => `/chapters/${chapterId}`,
};

const page = {
  getPage: ({ pageId }) => `/page/${pageId}`,
  putPage: ({ pageId }) => `/page/${pageId}`,
  postPageView: ({ pageId }) => `/users/page/${pageId}/pageview`,
  postFeedback: ({ pageId }) => `/page/${pageId}/feedback`,
  postAnswer: ({ questionId }) => `/question/${questionId}/answer`,
  postComplete: ({ pageId }) => `/page/${pageId}/complete`,
  deleteComplete: ({ pageProgressId }) => `/page/${pageProgressId}/remove`,
  postPageFeedback: ({ pageId }) => `/page/${pageId}/feedback`,
};

const blog = {
  getBlogsByCategory: ({ categorySlug }) => `/blog/category/${categorySlug}`,
  getBlogCategories: () => `/blog/categories`,
  getBlogTags: () => `/blog/tags`,
  getBlogs: () => `/blogsgrid`,
  getBlogComments: ({ blogId }) => `/blog/${blogId}/comment`,
  getBlogCommentReplies: ({ commentId }) => `/blog/comment/${commentId}/reply`,
  postBlogComment: ({ blogId }) => `/blog/${blogId}/comment/post`,
  postBlogCommentReply: ({ commentId }) =>
    `/blog/comment/${commentId}/reply/post`,
  getBlog: ({ slug }) => `/blog/${slug}`,
  getBlogBySlug: ({ blogSlug }) => `/blog/category/${blogSlug}`,
  postBlog: () => `/blog/post`,
  putBlog: ({ blogId }) => `/blog/${blogId}/update`,
  deleteBlog: ({ blogId }) => `/blog/${blogId}/remove`,
  getBlogsByTag: ({ tagId }) => `/blog/tag/${tagId}`,
  getBlogsByUser: () => `/blog/user`,
  modifyBlog: () => `/blog/user`,
  getBlogsByStatus: () => `/blog/review`,
  postBlogByStatus: ({ blogId }) => `/blog/${blogId}/publishBlog`,
  postSubscription: () => `/subscribe`,
};

const assets = {
  post: () => `/upload-asset`,
};

const students = {
  getStudentCount: () => `/total-students`,
  getStudentActivity: ({ userId }) => `/users/${userId}/activity-graph`,
  getStudentKatas: ({ userId }) => `/users/${userId}/katas`,
  putGithubUsername: () => `/users/github-profile`,
  putCodewarsUsername: () => `/users/codewars-profile`,
  getStudentsLastPages: ({ userId }) => `/get-students-last-pages/${userId}`,
  getStudentsCheckedPages: ({ userId }) =>
    `/get-students-checked-pages/${userId}`,
};

const payments = {
  postPaypointPayment: () => `/payment-initiated`,
  getPaymentStatus: () => `/check-payment-status`,
  postUserPayment: () => `/add-payment`,
  getStudentPaymentHistory: ({ userId }) =>
    `/student-payment-history/${userId}`,
  getPayments: () => `/payments`,
};

const admin = {
  postInstructor: () => `/instructor`,
};

export {
  user,
  chapter,
  page,
  moduleEndpoint,
  blog,
  assets,
  students,
  payments,
  admin,
};
