import React, { useState } from "react";
import Modal from "components/Modal";
import "./style.css";

const getDate = (dayDelta) => {
  const date = new Date();
  const yesterday = date.getTime() + 1000 * 60 * 60 * 24 * dayDelta;
  const newDay = new Date(yesterday);
  return newDay;
};
const ActivityGraph = ({ days }) => {
  const [isActivityGraphHelperOpen, setActivityGraphHelper] = useState(false);
  const d = new Date();
  const daysIntheWeek = ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day1 = daysIntheWeek[getDate(-1).getDay()];
  const day2 = daysIntheWeek[getDate(-2).getDay()];
  const day3 = daysIntheWeek[getDate(-3).getDay()];
  const day4 = daysIntheWeek[getDate(-4).getDay()];
  const day5 = daysIntheWeek[getDate(-5).getDay()];
  const day6 = daysIntheWeek[getDate(-6).getDay()];
  const day7 = daysIntheWeek[getDate(-7).getDay()];

  let thisMonth;
  let month1;
  let month2;

  if (d.getDate() <= 10) {
    thisMonth = months[d.getMonth() - 1];
    month1 = months[d.getMonth() - 2];
    month2 = months[d.getMonth() - 3];
  } else {
    thisMonth = months[d.getMonth()];

    month1 = months[d.getMonth() - 1];
    month2 = months[d.getMonth() - 2];
  }
  return (
    <div>
      {isActivityGraphHelperOpen && (
        <Modal
          body={
            <div>
              We monitor your activity on the portal, GitHub, and CodeWars to
              calculate your activity count daily. Here is what counts as an
              activity: <br />
              <br />
              <ul style={{ listStyle: "disc" }} className="px-3">
                <li>completing a page on the learning portal</li>
                <li>solving a quiz on the learning portal</li>
                <li>writing a blog on the learning portal</li>
                <li>solving a kata on CodeWars</li>
                <li>daily contributions on GitHub</li>
              </ul>{" "}
            </div>
          }
          close={() => setActivityGraphHelper(false)}
          actionBar={
            <button
              class="uk-button uk-button-primary"
              type="button"
              onClick={() => setActivityGraphHelper(false)}
            >
              Ok
            </button>
          }
        />
      )}
      <div className="activity-graph">
        <div className="graph">
          <ul className="months">
            <li>{thisMonth}</li>
            <li>{month1}</li>
            <li>{month2}</li>
          </ul>
          <ul className="days">
            <li>{day1}</li>
            <li>{day2}</li>
            <li>{day3}</li>
            <li>{day4}</li>
            <li>{day5}</li>
            <li>{day6}</li>
            <li>{day7}</li>
          </ul>
          <ul className="squares">
            {days.map((day) => {
              return (
                <li
                  data-level={day.level}
                  new
                  date
                  data-uk-tooltip={`${day.count} contributions on ${day.date}`}
                ></li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="activity-graph-explainer-container">
        <p
          className="activity-graph-explainer-text"
          onClick={() => setActivityGraphHelper(true)}
        >
          How your activity is calculated{" "}
          <i className="icon-material-outline-help-outline activity-graph-help-icon pl-1"></i>
        </p>
      </div>
    </div>
  );
};

export default ActivityGraph;
