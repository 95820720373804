import React from "react";
import Authentication from "services/Authentication";
import useFetchData from "effects/useFetchData";
import getStudentKatas from "services/getStudentKatas";

const auth = new Authentication();

const MyKatas = ({ nightMode }) => {
  const jwtPayload = auth.getAccessTokenPayload();

  const [loadingKatas, errorKatas, katasResponse] = useFetchData(() =>
    getStudentKatas({ userId: jwtPayload.userId })
  );

  if (errorKatas) {
    return <div>Something went wrong. Please refresh the page.</div>;
  }
  if (loadingKatas) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div>
        <div className="section-small">
          <div className="uk-child-width-1-4@m uk-child-width-1-3@s">
            CyprusCodes team have hand picked below CodeWars katas for you to
            sharpen your JavaScript skills. Katas are ordered in increasing
            complexity, so they get harder as you reach closer to the end of
            this page. Every kata you complete will also show in your green
            activity calendar on your dashboard. Good luck young padawan.
          </div>
        </div>
      </div>
      <div className="uk-child-width-1-2@ uk-child-width-1-3@m" data-uk-grid>
        {katasResponse.map((kata) => {
          return (
            <>
              <div>
                <div className="blog-post blog-post-card">
                  <div
                    className="blog-post-content"
                    style={{
                      minHeight: "100px",
                      maxHeight: "100px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <a href={kata.url} target="_blank" rel="noreferrer">
                      {kata.name}
                    </a>
                    <div className="small-hex is-extra-wide is-white-rank">
                      <div className="inner-small-hex is-extra-wide ">
                        <span> {kata.kyu}</span>
                      </div>
                    </div>
                  </div>
                  <div className="kata-div">
                    {kata.completed ? (
                      <button
                        id="edit-listing-form-house_Continue"
                        class="btn kata-btn-complete"
                        name="Continue"
                        type="submit"
                      >
                        <span>Completed</span>
                        <i className="icon-line-awesome-check icon-kata-done"></i>
                      </button>
                    ) : (
                      <button
                        id="edit-listing-form-house_Continue"
                        class="btn kata-btn"
                        name="Continue"
                        type="submit"
                        onClick={() => {
                          window.open(kata.url, "_blank");
                        }}
                      >
                        <span>Attempt</span>
                        <i className="icon-line-awesome-long-arrow-right icon-kata"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default MyKatas;
