import React from "react";
import Editor from "libs/ckeditor/exported";
import EditorStyle from "libs/ckeditor/style";
import RebootCSS from "libs/ckeditor/reboot";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { createGlobalStyle } from "styled-components";
import Authentication from "services/Authentication";

const GlobalStyle = createGlobalStyle`
  ${RebootCSS}
  ${EditorStyle}
  ${(props) => props.dynamicCKEditorStyle}
`;

const editorConfiguration = {
  removePlugins: ["Title"],
  toolbar: {
    config: {
      embed_provider:
        "//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}",
    },
    items: [
      "heading",
      "|",
      "fontFamily",
      "fontSize",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "superscript",
      "subscript",
      "highlight",
      "|",
      "link",
      "bulletedList",
      "numberedList",
      "horizontalLine",
      "|",
      "outdent",
      "indent",
      "-",
      "blockQuote",
      "codeBlock",
      "insertTable",
      "imageInsert",
      "mediaEmbed",
      "htmlEmbed",
      "|",
      "sourceEditing",
      "undo",
      "redo",
      "findAndReplace",
    ],
    shouldNotGroupWhenFull: true,
  },
  language: "en-gb",
  image: {
    toolbar: [
      "imageTextAlternative",
      "imageStyle:inline",
      "imageStyle:block",
      "imageStyle:side",
    ],
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableCellProperties",
      "tableProperties",
    ],
  },
  simpleUpload: {
    // The URL that the images are uploaded to.
    uploadUrl: `${process.env.REACT_APP_API_BASE_URL}/upload-asset`,

    // Enable the XMLHttpRequest.withCredentials property.
    // withCredentials: true,

    // Headers sent along with the XMLHttpRequest to the upload server.
    headers: {
      Authorization: `Bearer ${new Authentication().getAccessToken()}`,
    },
  },
};

const RichTextEditor = ({ customEditorConfiguration = {}, ...rest }) => {
  return (
    <>
      <GlobalStyle />
      <CKEditor
        editor={Editor}
        config={{ ...editorConfiguration, ...customEditorConfiguration }}
        {...rest}
      />
    </>
  );
};

export default RichTextEditor;
