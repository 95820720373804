import React, { useState } from "react";
import Modal from "components/Modal";
import ActivityGraph from "components/ActivityGraph";
import getUserModules from "services/getUserModules";
import useFetchData from "effects/useFetchData";
import Authentication from "services/Authentication";
import styled from "styled-components";
import GHIcon from "./assets/octocat";
import CodeWarsIcon from "./assets/codewars";
import GithubUsername from "./assets/github-username.png";
import CodeWarsUserName from "./assets/codewars-profile.png";
import CourseList from "./components/CourseList";
//import MyBookmarks from "./components/MyBookmarks";
import MyBlog from "./components/MyBlog";
import MyKatas from "./components/MyKatas";
import getGravatarImg from "utils/getGravatarImg";
// import Insta from "./assets/insta";
import getStudentActivity from "services/getStudentActivity";
import putGithubUsername from "services/putGithubUsername";
import putCodewarsUsername from "services/putCodewarsUsername";
import PaymentPopup from "../../components/PaymentPopupWindow/index";
import getPaymentStatus from "services/payment/getPaymentStatus";

const Badges = styled.div`
  display: inline-flex;
  justify-content: left;
`;

const SocialBadge = styled.div`
  border-radius: 20px;
  background: rgb(36, 41, 46);
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-right: 7px;
`;

const Trophies = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 770px) {
    align-items: flex-start;
  }
`;

const auth = new Authentication();

const TABS = {
  COURSE_LIST: "COURSE_LIST",
  BLOG: "BLOG",
  KATAS: "KATAS",
  //BOOKMARKS: "BOOKMARKS",
};

const Student = ({ nightMode }) => {
  const jwtPayload = auth.getAccessTokenPayload();

  const [loading, errored, apiResponse] = useFetchData(() => getUserModules());
  const [activeTab, setActiveTab] = useState(TABS.COURSE_LIST);
  const [captureGithubDetailsModalOpen, setCaptureGithubDetailsModalOpen] =
    useState(false);
  const [captureCodeWarsDetailsModalOpen, setCaptureCodeWarsDetailsModalOpen] =
    useState(false);
  const [githubUsername, setGithubUsername] = useState("");
  const [codewarsUsername, setCodewarsUsername] = useState("");
  const [
    isLoadingActivity,
    errorFetchingActivity,
    activityResponse,
    refreshActivity,
  ] = useFetchData(() => getStudentActivity({ userId: jwtPayload.userId }));

  const [paymentLoading, paymentErrored, paymentApiResponse] = useFetchData(
    () => getPaymentStatus({ userId: jwtPayload.userId })
  );

  const { studentModules } = apiResponse;
  const { hasPaid } = paymentApiResponse;

  if (loading || isLoadingActivity || paymentLoading) {
    return <div>Loading...</div>;
  }

  if (errored || errorFetchingActivity || paymentErrored) {
    return <div>Something went wrong</div>;
  }

  return (
    <>
      {captureGithubDetailsModalOpen && (
        <Modal
          body={
            <div>
              <span
                style={{ padding: "10px", display: "block", fontWeight: 600 }}
              >
                Please provide your GitHub username below to unlock activity
                calendar.{" "}
              </span>
              <input
                type="text"
                placeholder="ersel"
                onChange={(e) => setGithubUsername(e.target.value)}
                value={githubUsername}
              />
              <img src={GithubUsername} width="200px" />
            </div>
          }
          close={() => setCaptureGithubDetailsModalOpen(false)}
          actionBar={
            <button
              class="uk-button uk-button-primary"
              type="button"
              onClick={() => {
                putGithubUsername({ githubUsername }).then(() => {
                  setCaptureGithubDetailsModalOpen(false);
                  refreshActivity();
                });
              }}
            >
              Save
            </button>
          }
        />
      )}
      {captureCodeWarsDetailsModalOpen && (
        <Modal
          body={
            <div>
              <span
                style={{ padding: "10px", display: "block", fontWeight: 600 }}
              >
                Please provide your CodeWars username below to unlock activity
                calendar.{" "}
              </span>
              <input
                type="text"
                placeholder="ersel"
                onChange={(e) => setCodewarsUsername(e.target.value)}
                value={codewarsUsername}
              />
              <img src={CodeWarsUserName} width="300px" />
            </div>
          }
          close={() => setCaptureCodeWarsDetailsModalOpen(false)}
          actionBar={
            <button
              class="uk-button uk-button-primary"
              type="button"
              onClick={() => {
                putCodewarsUsername({
                  codewarsUsername,
                  userId: jwtPayload.userId,
                }).then(() => {
                  setCaptureCodeWarsDetailsModalOpen(false);
                  refreshActivity();
                });
              }}
            >
              Save
            </button>
          }
        />
      )}
      <div className="profile-container">
        <div className="page-content-inner uk-light pb-0">
          <div className="profile-layout">
            <div className="profile-layout-avature">
              <div className="user-profile-photo">
                {jwtPayload.image ? (
                  <div className="user-profile-photo  m-auto">
                    <img src={jwtPayload.image} alt="profile" />
                  </div>
                ) : (
                  <div className="user-profile-photo  m-auto">
                    <img
                      src={getGravatarImg(
                        jwtPayload.firstName,
                        jwtPayload.lastName
                      )}
                      alt="profile"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="profile-layout-content">
              <h2> {jwtPayload.firstName + " " + jwtPayload.lastName} </h2>

              <Trophies>
                <Badges>
                  {activityResponse.hasGitHubProfile ? (
                    <SocialBadge className="badge-xl badge-success mb-1 p-1 px-2">
                      <GHIcon />{" "}
                      <span className="ml-1" style={{ marginTop: "3px" }}>
                        {activityResponse.totalGithubContributions} commits
                      </span>
                    </SocialBadge>
                  ) : (
                    <SocialBadge
                      className="badge-xl badge-success mb-1 p-1 px-2 clicky-pointer"
                      onClick={() => setCaptureGithubDetailsModalOpen(true)}
                    >
                      <GHIcon />{" "}
                      <span className="ml-1" style={{ marginTop: "3px" }}>
                        Connect now
                      </span>
                    </SocialBadge>
                  )}
                  {activityResponse.hasCodewarsProfile ? (
                    <SocialBadge className="badge-xl badge-success mb-1 p-1 px-2">
                      <CodeWarsIcon />{" "}
                      <span className="ml-1" style={{ marginTop: "3px" }}>
                        {activityResponse.katasCompleted} katas /{" "}
                        {activityResponse.totalKyu}
                      </span>
                    </SocialBadge>
                  ) : (
                    <SocialBadge
                      className="badge-xl badge-success mb-1 p-1 px-2 clicky-pointer"
                      onClick={() => setCaptureCodeWarsDetailsModalOpen(true)}
                    >
                      <CodeWarsIcon />{" "}
                      <span className="ml-1" style={{ marginTop: "3px" }}>
                        Connect now
                      </span>
                    </SocialBadge>
                  )}
                </Badges>
                {/* <MedalContainer>
                  <div>
                    <MedalCount>2</MedalCount>
                    <Medal src={GoldImg} />
                  </div>
                  <div>
                    <MedalCount>10</MedalCount>
                    <Medal src={SilverImg} />
                  </div>
                  <div>
                    <MedalCount>25</MedalCount>
                    <Medal src={BronzeImg} />
                  </div>
                </MedalContainer> */}
              </Trophies>
            </div>
            <div className="profile-layout-badge">
              <ActivityGraph days={activityResponse.summary} />
            </div>
          </div>

          <nav className="responsive-tab style-4">
            <ul>
              <li
                className={activeTab === TABS.COURSE_LIST ? "uk-active" : ""}
                onClick={() => setActiveTab(TABS.COURSE_LIST)}
              >
                <a href="#">Courses</a>
              </li>
              <li
                className={activeTab === TABS.KATAS ? "uk-active" : ""}
                onClick={() => setActiveTab(TABS.KATAS)}
              >
                <a href="#">Katas</a>
              </li>
              <li
                className={activeTab === TABS.BLOG ? "uk-active" : ""}
                onClick={() => setActiveTab(TABS.BLOG)}
              >
                <a href="#">My Blog</a>
              </li>
              <li
                onClick={() =>
                  window.open(
                    "https://bootcamp-cv-template.cypruscodes.com",
                    "_blank"
                  )
                }
              >
                <a href="#">My CV</a>
              </li>
              {/*<li
                className={activeTab === TABS.BOOKMARKS ? "uk-active" : ""}
                onClick={() => setActiveTab(TABS.BOOKMARKS)}
              >
                <a href="#">Bookmarks</a>
              </li>
              */}
            </ul>
          </nav>
        </div>
      </div>
      <div className="page-content-inner">
        {!hasPaid && <PaymentPopup hasPaid={hasPaid} />}
        {TABS.COURSE_LIST === activeTab && (
          <CourseList studentModules={studentModules} />
        )}
        {/* {TABS.BOOKMARKS === activeTab && <MyBookmarks />} */}
        {TABS.KATAS === activeTab && <MyKatas nightMode={nightMode} />}
        {TABS.BLOG === activeTab && <MyBlog nightMode={nightMode} />}
      </div>
    </>
  );
};

export default Student;
