const successNotification = (message) => {
  UIkit.notification({
    message: message,
    status: "success",
    pos: "bottom-center",
    timeout: 5000,
  });
};

export default successNotification;
