import React from "react";
import { Link } from "react-router-dom";

const SideBar = () => {
  return (
    <>
      <div className="page-menu">
        {/* <!-- logo --> */}
        <div className="logo uk-visible@s">
          <a href="dashboard.html">
            {" "}
            <i className=" uil-graduation-hat"></i> <span> CyprusCodes</span>{" "}
          </a>
        </div>
        <div className="page-menu-inner" data-simplebar>
          {/* <!-- btn close on small devices --> */}
          <span
            className="btn-menu-close"
            data-uk-toggle="target: #wrapper ; cls: mobile-active"
          ></span>
          {/* <!-- traiger btn --> */}
          <span
            className="btn-menu-trigger"
            data-uk-toggle="target: .page-menu ; cls: menu-large"
          ></span>
          <ul data-submenu-title="Browse">
            <li>
              <Link to="profile">
                <i className="uil-user-circle"></i>
                <span> Account </span>
              </Link>
            </li>
            <li>
              <Link to="profile-edit">
                <i className="uil-edit-alt"></i> <span>Settings</span>{" "}
              </Link>
            </li>

            <li>
              <Link to="blog-1.html">
                <i className="uil-file-alt"></i> <span> My Blog Posts</span>
              </Link>
            </li>
            {/* <li>
              <Link to="page-pricing.html">
                <i className="uil-calendar-alt"></i> <span> Calendar</span>{" "}
              </Link>
            </li> */}
            {/* <li>
              <Link to="page-pricing.html">
                <i className="uil-bill"></i> <span> Billing</span>{" "}
              </Link>
            </li> */}
          </ul>

          <ul data-submenu-title="Discover">
            <li>
              <Link to="/courses">
                <i className="uil-play-circle"></i> <span> Courses</span>{" "}
              </Link>
            </li>
            <li>
              <a href="episode.html">
                <i className="uil-youtube-alt"></i> <span> Video</span>
              </a>{" "}
            </li>
          </ul>

          <ul data-submenu-title="Site">
            <li>
              <Link to="/privacy">
                <i className="uil-shield-question"></i>
                <span>Privacy Policy</span>{" "}
              </Link>
            </li>

            <li>
              <Link to="/terms">
                <i className="uil-info-circle"></i> <span>Terms of Use</span>{" "}
              </Link>
            </li>
            <li>
              <a href="page-faq.html">
                <i className="uil-comment-dots"></i> <span> Faq </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideBar;
