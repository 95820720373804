function chunk(files, size) {
  var chunks = [];
  for (var i = 0; i < files.length; i += size) {
    var chunk = [];
    for (var j = 0; j < size; j++) {
      if (files[i + j]) {
        chunk.push(files[i + j]);
      } else {
        j = size;
      }
    }
    chunks.push(chunk);
  }
  return chunks;
}

export default chunk;
