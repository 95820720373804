import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

const AdminSideBar = () => {
  return (
    <>
      <div className="page-menu admin-sidebar-text">
        {/* <!-- logo --> */}
        <div className="logo uk-visible@s">
          <a href="dashboard.html">
            {" "}
            <i className=" uil-graduation-hat icon1"></i>{" "}
            <span>CyprusCodes</span>{" "}
          </a>
        </div>
        <div className="page-menu-inner" data-simplebar>
          {/* <!-- btn close on small devices --> */}
          <span
            className="btn-menu-close"
            data-uk-toggle="target: #wrapper ; cls: mobile-active"
          ></span>
          {/* <!-- traiger btn --> */}
          <span
            className="btn-menu-trigger"
            data-uk-toggle="target: .page-menu ; cls: menu-large"
          ></span>
          {/* list starts*/}

          <ul data-submenu-title="Management">
            <li>
              <Link to="/admin">
                <i className="uil-home-alt"></i>
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/admin-courses">
                <i className="uil-youtube-alt"></i>
                <span>Courses</span>
              </Link>
            </li>
            <li>
              <Link to="/admin-students">
                <i className="uil-users-alt"></i>
                <span>Students</span>
              </Link>
            </li>
            <li>
              <Link to="/admin-instructors">
                <i className="uil-graduation-hat"></i>
                <span>Instructors</span>
              </Link>
            </li>

            <li>
              <Link to="/admin-manageblogs">
                <i className="uil-file-alt"></i> <span>Manage Blogs</span>
              </Link>
              {/*
              <a href="#">
                <i className="uil-file-alt"></i> <span> Manage Blogs</span>
              </a>{" "}
*/}
            </li>
            <li>
              <a href="#">
                <i className="uil-chart-line"></i>
                <span> Report</span>
              </a>{" "}
            </li>
            <li>
              <Link to="/payments">
                <i className="uil-file-alt"></i>
                <span>Payments</span>
              </Link>
            </li>
            <li>
              <a href="#">
                <i className="uil-question-circle"></i>
                <span>Help</span>
              </a>{" "}
            </li>
          </ul>

          <ul data-submenu-title="Settings">
            <li>
              <a href="setting.html">
                <i className="uil-cog"></i>
                <span>Account Settings</span>
              </a>{" "}
            </li>
            <li>
              <a href="setting.html">
                <i className="uil-edit-alt"></i>
                <span>Site</span>
              </a>{" "}
            </li>
            <li className="#">
              <a href="#">
                <i className="uil-layers"></i>
                <span>simple link</span>
              </a>
              <ul>
                <li>
                  <a href="#">
                    {" "}
                    simple link <span className="nav-tag">3</span>
                  </a>
                </li>
                <li>
                  <a href="#"> simple link </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="login.html">
                <i className="uil-sign-out-alt"></i>
                <span>Sign-out</span>
              </a>{" "}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AdminSideBar;
