import React from "react";
import { useHistory } from "react-router";

const ChapterView = ({ chapter, allPages, activePage, moduleId }) => {
  let history = useHistory();

  return (
    <li className={chapter.chapterId === activePage.chapterId ? "uk-open" : ""}>
      <a className="uk-accordion-title" href="#">
        {" "}
        {chapter.title}
      </a>
      <div className="uk-accordion-content">
        <ul
          className={chapter.progress === 100 ? "vidlist-4" : "vidlist-3"}
          data-uk-switcher="connect: #video-slider; animation: uk-animation-slide-right-small, uk-animation-slide-left-medium"
        >
          {chapter.pages.map((page) => {
            return (
              <li
                className={activePage.pageId === page.pageId ? "uk-active" : ""}
              >
                <a
                  href=""
                  onClick={() => {
                    const chapterId = allPages.find(
                      (p) => p.pageId === page.pageId
                    ).chapterId;
                    history.push(
                      `/module/${moduleId}/chapter/${chapterId}/page/${page.pageId}`
                    );                   
                  }}
                  style={{fontSize:"12px"}}
                >
                  {" "}
                  {page.title}{" "}
                  <span>{Boolean(page.completedAt ? "Done" : "")}</span>{" "}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </li>
  );
};

export default ChapterView;
