import http from "utils/http";
import { user } from "constants/endpoints";

const putAddressDetails = ({
  userId,
  address,
  addressLine2,
  city,
  postcode,
  town,
  country,
}) => {
  const ENDPOINT = user.putAddressDetails({
    userId,
    address,
    addressLine2,
    city,
    postcode,
    town,
    country,
  });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http
    .put(`${API_BASE_URL}${ENDPOINT}`, {
      data: {
        userId,
        address,
        addressLine2,
        city,
        postcode,
        town,
        country,
      },
    })
    .then((response) => {
      const accessToken = response.data.accessToken;
      localStorage.setItem("creds", accessToken);
      return response;
    });
};

export default putAddressDetails;
