import React from "react";
import { useHistory } from "react-router";
import PercentageCircle from "components/ProgressCircle";

const Chapter = ({ chapter, allPages, pageToContinue, moduleId }) => {
  let history = useHistory();

  return (
    <li
      className={
        chapter.chapterId === pageToContinue?.chapterId ? "uk-open" : ""
      }
    >
      <div className="uk-accordion-title" href="#">
        <div> {chapter.title} </div>
        <div>
          {" "}
          <PercentageCircle percent={chapter.progress} /> {" "}
        </div>
      </div>

      <div className="uk-accordion-content">
        <ul className="course-curriculum-list">
          {chapter.pages.map((page) => {
            return (
              <li
                onClick={() => {
                  const chapterId = allPages.find(
                    (p) => p.pageId === page.pageId
                  ).chapterId;
                  history.push(
                    `/module/${moduleId}/chapter/${chapterId}/page/${page.pageId}`
                  );
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                {page.title}{" "}
                <span className="tick"> {Boolean(page.completedAt) ? <i className="icon-feather-check" style={{marginRight: "20px"}} ></i> : ""} </span>
              </li>
            );
          })}
        </ul>
      </div>
    </li>
  );
};

export default Chapter;
