import http from "utils/http";
import { blog } from "constants/endpoints";


const postBlogComment = ({ blogId, commentContent }) => {
    const ENDPOINT = blog.postBlogComment({ blogId });
    const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
    return http.post(`${API_BASE_URL}${ENDPOINT}`, {
        data: {
            commentContent
        },
    });
};

export default postBlogComment;